import { useContext } from "react";
import { ROUTES } from "@Models/routes";
import { Route, Routes } from "react-router-dom";
import { MainView } from "@Pages/InitialView/InitialView";
import { SelectedSaleBox } from "@Pages/SelectedSaleBox/SelectedSaleBox";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  ModalAcceptCancel,
  NotFoundPage,
  SlideNotification,
  // @ts-ignore
} from "@viuti/recursos";
import { clearNotification } from "@ReduxService/states/notificationSlice";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import useShortcuts from "@Hooks/useShortcuts/useShortcuts";
import Movements from "@Pages/Movements/Movements";
import SalesDocuments from "@Pages/SalesDocuments/SalesDocuments";
import SaleboxReport from "@Pages/SaleboxReport/SaleboxReport";

const HomeRoute = () => {
  const dispatch = useAppDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;

  // Slide Notification
  const notificationState = useAppSelector((state) => state.notificationConfig);

  useShortcuts();

  return (
    <>
      <Routes>
        <Route path={ROUTES.HOME_ROUTE} element={<MainView />} />
        <Route
          path={`${ROUTES.HOME_ROUTE}/:saleBoxId`}
          element={<SelectedSaleBox />}
        />
        <Route
          path={`${ROUTES.HOME_ROUTE}/:saleBoxId/movimientos`}
          element={<Movements />}
        />
        <Route
          path={`${ROUTES.HOME_ROUTE}/:saleBoxId/documentos-de-ventas`}
          element={<SalesDocuments />}
        />
        <Route
          path={`${ROUTES.HOME_ROUTE}/:saleBoxId/reporte`}
          element={<SaleboxReport />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {/* @ts-ignore */}
      <ModalAcceptCancel {...modalContext}>
        {modalContext.modalChildren}
      </ModalAcceptCancel>
      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
    </>
  );
};

export default HomeRoute;
