import { configureStore } from "@reduxjs/toolkit";

// Data reducers
import currentSaleReducer from "./states/currentSaleSlice";
import saleConfigReducer from "./states/saleConfigSlice";
import saleBoxesListReducer from "./states/saleBoxesSlice";
import saleBoxReportSlice from "./states/saleBoxReportSlice";
import selectedSaleBoxReducer from "./states/selectedSaleBoxSlice";
import movementsReducer from "./states/movementsSlice";
import saleDocumentsReducer from "./states/saleDocumentsSlice";
import conceptsReducer from "./states/conceptsSlice";
// Components reducers
import notificationReducer from "./states/notificationSlice";
import reserveCommandsReducer from "./states/reserveCommands";
export const store = configureStore({
  reducer: {
    //* Reports reducers
    saleboxReports: saleBoxReportSlice,
    //* Sales reducers
    currentSale: currentSaleReducer,
    saleBoxesList: saleBoxesListReducer,
    selectedSaleBox: selectedSaleBoxReducer,
    movements: movementsReducer,
    saleDocuments: saleDocumentsReducer,
    concepts: conceptsReducer,
    //* Config reducers
    saleConfig: saleConfigReducer,
    notificationConfig: notificationReducer,
    reserveCommands: reserveCommandsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector = (state: RootState) => RootState;

/**
 * This file is the main file for the redux store, here we define the reducers and the store itself.
 *
 * We use different slices for different parts of the app:
 * - 💰 !important
 *      currentSale: stores the current sale data as the selected client, attached sales (products, services, packages, etc.), selected billing document and all the info a sale needs.
 *
 * - 📨   notificationConfig: stores the notification data as the message, type and if it's visible or not.
 *
 * - 📋 !important
 *      saleBoxesList: stores the sale boxes list of the current lounge.
 *
 * - 👜   saleConfig: stores all the info that you can use in a sale. This includes payment methods, the company services, the warehouses, the sale boxes, etc.
 *
 * - 💅🏻   selectedSaleBox: stores the selected sale box data. It also updates the sale box data on *saleBoxesSlice* when this one is updated or modified.
 */
