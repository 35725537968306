import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = [];

const initialState: ISaleBox[] | [] = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const saleBoxesSlice = createSlice({
  name: "saleBoxesList",
  initialState,
  reducers: {
    updateSaleBoxes: (state, action: PayloadAction<ISaleBox[]>) => {
      return action.payload;
    },
    updateSaleBoxOnList: (
      state: ISaleBox[],
      action: PayloadAction<ISaleBox>
    ) => {
      const saleBoxIndex = state.findIndex(
        (saleBox) => saleBox.id === action.payload.id
      );
      state[saleBoxIndex] = action.payload;
    },
  },
});

export const { updateSaleBoxes, updateSaleBoxOnList } = saleBoxesSlice.actions;

export default saleBoxesSlice.reducer;
