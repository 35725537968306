import React from "react";
import style from "./SaleAmountCard.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { CashIcon } from "@Models/icons";
import { formatToSoles } from "@Utilities/FormatsHandler";

interface Props {
  content: {
    icon: string;
    type: string;
    totalCashTitle: string;
  };
  values: {
    amount: number;
    totalCash: number;
  };
}

const SaleAmountCard = ({ content, values }: Props) => {
  return (
    <article className={style.container}>
      <div className={style.card__content}>
        <Icon path={content.icon} color={"#937cf4"} size={20} />
        <p className={style.card__header}>Cantidad de {content.type}</p>
        <div className={style.total_amount}>{values.amount}</div>
        <div className={style.card__numbers__container}>
          <span className={style.card__numbers__item}>
            <span className={style.card__numbers__item_label}>
              {content.totalCashTitle}
            </span>
            <span className={style.card__numbers__item_amount}>
              {formatToSoles(values.totalCash)}
            </span>
          </span>
        </div>
      </div>
    </article>
  );
};

export default SaleAmountCard;
