import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";
import { loungesBaseUrl, config } from "./constGlobals";

export const getConceptMovements_service = async () => {
  const url = `${loungesBaseUrl}/Concept/Concepts`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los conceptos de movimiento.",
      data: [],
    };
  }
};

export const postConceptMovements_service = async (data) => {
  const url = `${loungesBaseUrl}/Concept/Concept`;
  const crea_concepto_movimiento = 63;
  trackUserAction(crea_concepto_movimiento);

  try {
    const response: IResponse = await axios
      .post(url, data, config)
      .then((res) => res.data);
    return response;
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al crear el concepto de movimiento. Intentalo de nuevo.",
      data: [],
    };
  }
};
