// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hHkDSsExUUnn9lebn0nh {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  gap: 10px;
  min-width: 240px;
}

.Qxvw0xSjWLttcCqHGLeU {
  font-size: 1rem;
  color: #45348e;
}

.LR7O4DfbOQr3hLbrQw9i {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
}

.Px_FipzGVjuE9Sa15U80 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  padding: 10px 8px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
}

.w0GE1BQWGhjjIfidQQ6X {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SaleboxReport/components/PaymentMethodsList/PaymentMethodsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;;EAET,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;;EAET,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: -webkit-fill-available;\n  gap: 10px;\n  min-width: 240px;\n}\n\n.label {\n  font-size: 1rem;\n  color: #45348e;\n}\n\n.content {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  border: 1px solid #e5e5e5;\n}\n\n.method {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  gap: 15px;\n\n  padding: 10px 8px;\n  border-radius: 8px;\n  border: 1px solid #e5e5e5;\n}\n\n.method__info {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hHkDSsExUUnn9lebn0nh`,
	"label": `Qxvw0xSjWLttcCqHGLeU`,
	"content": `LR7O4DfbOQr3hLbrQw9i`,
	"method": `Px_FipzGVjuE9Sa15U80`,
	"method__info": `w0GE1BQWGhjjIfidQQ6X`
};
export default ___CSS_LOADER_EXPORT___;
