import {
  APPOINTMENTS_TYPE,
  COMMANDS_TYPE,
  GIFTCARDS_TYPE,
  PACKAGES_TYPE,
  PRODUCTS_TYPE,
  SERVICES_TYPE,
} from "@Models/const/salesOptions";
import {
  CalendarIcon,
  CodeBarIcon,
  CommandIcon,
  GiftIcon,
  PackageIcon,
  ServiceIcon,
} from "@Models/icons";

export const categoryName = (category: number) => {
  switch (category) {
    case PRODUCTS_TYPE:
      return "Productos";
    case APPOINTMENTS_TYPE:
      return "Reservas";
    case SERVICES_TYPE:
      return "Servicios";
    case PACKAGES_TYPE:
      return "Paquetes";
    case COMMANDS_TYPE:
      return "Comandas";
    case GIFTCARDS_TYPE:
      return "Giftcards";
    default:
      return "Ventas";
  }
};

export const categoryIcon = (category: number) => {
  switch (category) {
    case PRODUCTS_TYPE:
      return CodeBarIcon;
    case APPOINTMENTS_TYPE:
      return CalendarIcon;
    case SERVICES_TYPE:
      return ServiceIcon;
    case PACKAGES_TYPE:
      return PackageIcon;
    case COMMANDS_TYPE:
      return CommandIcon;
    case GIFTCARDS_TYPE:
      return GiftIcon;
    default:
      return "ShoppingBagIcon";
  }
};
