import React from "react";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { ClientsModalStateContextProps } from "@Hooks/NewClientsModalStateContext";
import { changeModalClient } from "@Hooks/UpdateModalType/changeModalClient/changeModalClient";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  APPOINTMENTS_TYPE,
  GIFTCARDS_TYPE,
  PACKAGES_TYPE,
  PRODUCTS_TYPE,
  SERVICES_TYPE,
} from "@Models/const/salesOptions";
import { changeModalService } from "@Hooks/UpdateModalType/changeModalService/changeModalService";
import { changeModalProducts } from "@Hooks/UpdateModalType/changeModalProducts/changeModalProducts";
import { changeModalPackages } from "@Hooks/UpdateModalType/changeModalPackages/changeModalPackages";
import { changeModalCommandsAppointments } from "@Hooks/UpdateModalType/changeModalCommandsAppointments/changeModalCommandsAppointments";
import { changeModalGiftcards } from "@Hooks/UpdateModalType/changeModalGiftcards/changeModalGiftcards";

export const handleSaleOptionAction = (
  optionId: number,
  dispatch: ReturnType<typeof useAppDispatch>,
  selectedClient: ReturnType<typeof useAppSelector>,
  modalContext: AcceptCancelModalContextProps,
  clientModalContext: ClientsModalStateContextProps,
  client
) => {
  const handleCallback = () => {
    switch (optionId) {
      case PRODUCTS_TYPE:
        changeModalProducts(modalContext);
        break;
      case APPOINTMENTS_TYPE:
        changeModalCommandsAppointments(modalContext, client.id);
        break;
      case SERVICES_TYPE:
        changeModalService(modalContext);
        break;
      case PACKAGES_TYPE:
        changeModalPackages(modalContext);
        break;
      case GIFTCARDS_TYPE:
        changeModalGiftcards(modalContext);
        break;
      default:
        break;
    }
  };

  if (!selectedClient) {
    changeModalClient(modalContext, clientModalContext, dispatch, () => {
      handleCallback();
    });
  } else {
    handleCallback();
  }
};
