import { lastCashCount_service } from "@Services/lastCashCount";

export const lastCashCount_adapter = async (saleBoxId: number) => {
  const response = await lastCashCount_service(saleBoxId);
  return {
    isSuccessful: response.isSuccessful,
    status: response.status,
    message: response.message,
    data: response.data,
  };
};
