import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateSaleBoxes } from "@ReduxService/states/saleBoxesSlice";
import {
  updateElectronicBillingAmmount,
  updateElectronicBilling,
} from "@ReduxService/states/saleConfigSlice";
import { getSaleBoxes_service } from "@Services/saleBox";

export const getSaleBoxes = async (dispatch: AppDispatch) => {
  const response: IResponse = await getSaleBoxes_service(dispatch);

  if (response.status !== STATUS_OK) {
    return response;
  }

  const formattedResponse: ISaleBox[] = response.data.saleBoxList?.map(
    (saleBox: any) => {
      return {
        id: saleBox.idSaleBox,
        name: saleBox.saleBoxName,
        isOpen: saleBox.estado === 1,
        hasElectronicBill: saleBox.electronicBilling,
        businessName: saleBox.businnesName,
        businessAddress: saleBox.businneAddress,
        ruc: saleBox.ruc,
        localName: saleBox.localName,
        needsCashCount: saleBox.cashCountConflict,
        isDowngraded: Boolean(saleBox.isDowngrade),
      };
    }
  );

  dispatch(updateSaleBoxes(formattedResponse));
  dispatch(
    updateElectronicBillingAmmount(
      response.data.amountInvoiceDocument.billingDocumentStock
    )
  );
  dispatch(
    updateElectronicBilling({
      lastPurchaseDate: response.data.amountInvoiceDocument.lastPurchase,
      isRunningOutOfDocuments: response.data.amountInvoiceDocument.alert20,
    })
  );

  return {
    status: STATUS_OK,
    message: "Cajas obtenidas correctamente",
    data: formattedResponse,
  };
};
