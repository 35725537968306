import axios from "axios";

import { config, baseUrl } from "./constGlobals";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getMovements_service = async (payload: IGetMovements) => {
  const url = `${baseUrl}/CashMovements/GetMovements?saleBoxId=${payload.saleBoxId}&typeMovement=${payload.typeMovement}&typeMovementConcept=${payload.typeMovementConcept}&startDate=${payload.startDate}&endDate=${payload.endDate}&searchParameter=${payload.searchParameter}&page=${payload.page}`;
  const carga_movimientos = 58;
  trackUserAction(carga_movimientos);
  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data.message || "Error al obtener los movimientos",
      data: [],
    };
  }
};

export const createMovement_service = async (payload: ICreateMovement) => {
  const url = `${baseUrl}/CashMovements/RecordMovement`;
  const crea_movimiento = 59;
  trackUserAction(crea_movimiento);
  try {
    const response: IResponse = await axios
      .post(url, payload, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data.message || "Error al crear el movimiento",
      data: [],
    };
  }
};

export const updateMovement_service = async (payload) => {
  const url = `${baseUrl}/CashMovements/EditMovement`;
  const edita_movimiento = 60;
  trackUserAction(edita_movimiento);
  try {
    const response: IResponse = await axios
      .put(url, payload, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data.message || "Error al editar el movimiento",
      data: [],
    };
  }
};

export const deleteMovement_service = async (id: number) => {
  const url = `${baseUrl}/CashMovements/DeleteMove?Id=${id}`;
  const elimina_movimiento = 61;
  trackUserAction(elimina_movimiento);
  try {
    const response: IResponse = await axios
      .delete(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data.message || "Error al eliminar el movimiento",
      data: [],
    };
  }
};

export const getEditingHistory_service = async (id: number) => {
  const url = `${baseUrl}/CashMovements/GetMovementsEditLogs?MovementId=${id}`;
  const historial_ediciones = 64;
  trackUserAction(historial_ediciones);
  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data.message ||
        "Error al obtener el historial de ediciones",
      data: [],
    };
  }
};

export const downloadCashMovements_service = async (payload) => {
  const url = `${baseUrl}/CashMovements/DownloadCashMovement?saleBoxId=${payload.idBox}&typeMovement=${payload.type}&typeMovementConcept=${payload.concept}&startDate=${payload.startDate}&endDate=${payload.endDate}&searchParameter=${payload.searParameter}`;
  const descarga_movimientos = 62;
  trackUserAction(descarga_movimientos);
  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data.message || "Error al descargar el reporte",
      data: [],
    };
  }
};
