import React, { useEffect, useState } from "react";
import style from "./SelectClientModal.module.css";
import TertiaryButton from "@Components/ui/atoms/TertiaryButton/TertiaryButton";
import { ClientsModalStateContextProps } from "@Hooks/NewClientsModalStateContext";
import { AddIcon } from "@Models/icons";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
// @ts-ignore
import { LoadingSpinner, SearchWithColoredBorder } from "@viuti/recursos";
import { useDebounce } from "@Hooks/useDebounce/useDebounce";
import { getClientsByText } from "@Adapters/getClientsByText.adapter";
import ClientButton from "./components/ClientButton/ClientButton";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { GENERIC_CLIENT_ID } from "@Models/const/clients";
import AddClientModal from "../AddClientModal/AddClientModal";

interface ISelectClientModalProps {
  clientModalContext: ClientsModalStateContextProps;
  modalContext: AcceptCancelModalContextProps;
  handleSelectClient: (client: IClient) => void;
}

const SelectClientModal = ({
  clientModalContext,
  modalContext,
  handleSelectClient,
}: ISelectClientModalProps) => {
  const dispatch = useAppDispatch();
  const registeredClients = useAppSelector(
    (state) => state.saleConfig.registeredClients
  );
  const client = useAppSelector((state) => state.currentSale.client);
  const genericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const currentClient = genericClient || client;

  const [isCreatingClient, setIsCreatingClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState(currentClient);

  const [isSearchingClients, setIsSearchingClients] = useState(false);
  const [searchClientValue, setSearchClientValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchClientValue, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        await getClientsByText(searchClientValue, dispatch);
        setIsSearchingClients(false);
      })();
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchClientValue !== "") {
      setIsSearchingClients(true);
    } else {
      setIsSearchingClients(false);
    }
  }, [searchClientValue]);

  useEffect(() => {
    clientModalContext.handleVisibilityChange({
      isVisible: isCreatingClient,
      setIsVisible: setIsCreatingClient,
    });
  }, [clientModalContext, isCreatingClient]);

  useEffect(() => {
    if (selectedClient?.id) {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        textButtonTwo: "Seleccionar",
        actionButtonTwo: () => handleSelectClient(selectedClient),
        isDisabled: false,
      });
    } else {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        textButtonTwo: "Seleccionar",
        isDisabled: true,
      });
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  useEffect(() => {
    setSelectedClient({ ...currentClient } as IClient);
  }, [currentClient]);

  return (
    <div className={style.body}>
      <TertiaryButton
        buttonProps={{
          onClick: () => setIsCreatingClient(true),
        }}
        icon={AddIcon}
        value="Añadir cliente"
      />

      <div className={style.search__container}>
        <SearchWithColoredBorder
          value={searchClientValue}
          placeholder={"Buscar cliente por nombre o documento"}
          setValue={setSearchClientValue}
          searchBy={["name", "documentNumber"]}
        />
        {isSearchingClients && (
          <span className={style.search__spinner}>
            <LoadingSpinner isVisible={isSearchingClients} size={18} />
          </span>
        )}
      </div>
      <span className={style.generic_client__container}>
        <input
          type="checkbox"
          name="clientId"
          id="clientId"
          checked={selectedClient?.id === GENERIC_CLIENT_ID}
          onChange={(e) => {
            setSelectedClient({
              id: GENERIC_CLIENT_ID,
              name: "Cliente genérico",
              lastName: "",
            } as IClient);
          }}
        />
        <label htmlFor="clientId" className={style.generic_client__label}>
          Es un cliente genérico
        </label>
      </span>
      <article className={style.clients__container}>
        {registeredClients.length > 0
          ? registeredClients.map((client) => {
              const isClientSelected = selectedClient?.id === client?.id;
              const initials =
                client.name.charAt(0).toUpperCase() +
                client.lastName.charAt(0).toUpperCase();
              return (
                <ClientButton
                  key={client.id}
                  client={client}
                  setSelectedClient={setSelectedClient}
                  isClientSelected={isClientSelected}
                  initials={initials}
                />
              );
            })
          : searchClientValue !== "" &&
            !isSearchingClients && (
              <div className={style.no_clients__container}>
                <span className={style.no_clients__text}>
                  No se encontraron resultados
                </span>
                Asegúrate de haberlo escrito correctamente o añade un nuevo
                cliente.
              </div>
            )}
      </article>

      <AddClientModal
        handleCreateClient={(client: IClient) => handleSelectClient(client)}
        setIsVisible={setIsCreatingClient}
      />
    </div>
  );
};

export default SelectClientModal;
