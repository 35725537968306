import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import {
  addSaleAppointments,
  updateCommands,
} from "@ReduxService/states/currentSaleSlice";
import { getReserveCommands_service } from "@Services/reserveCommands";

export const getReserveCommands_adapter = async (idClient: number) => {
  const response: IResponse = await getReserveCommands_service(idClient);

  if (!response.isSuccessful) {
    return response;
  }

  // Filtrar y transformar las comandas
  const commands = response.data.items
    .filter((item) => item.comandaId !== null)
    .map((item) => ({
      index: generateUniqueId(),
      command: {
        reserveId: item.reserveId,
        comandaId: item.comandaId,
        itemsGroup: item.itemsGroup.map((groupItem) => ({
          index: generateUniqueId(),
          item: {
            reserveDetailId: groupItem.reserveDetailId,
            CommandDetailId: groupItem.comandaDetailId,
            localProductId: groupItem.localProductId,
            packageId: groupItem.packageId,
            priceServiceId: groupItem.priceServiceId,
            quantity: groupItem.quantity,
            price: groupItem.price,
            unitPrice: groupItem.unitPrice,
            description: groupItem.description,
            employeeId: groupItem.employeeId,
            employeeName: groupItem.employeeName,
            packageItem: groupItem.packageItem,
            warehouseId: groupItem.warehouseId,
            warehouseName: groupItem.warehouseName,
          },
          summary: {
            quantity: groupItem.quantity.toString(),
            subtotal: 0,
            discount: 0,
            addition: 0,
            modifierIsPercentage: false,
            total: groupItem.price,
          },
        })),
        summary: {
          quantity: 1,
          subtotal: 0,
          discount: 0,
          addition: 0,
          total: (
            item.itemsGroup.reduce((acc, item) => acc + item.price, 0) *
            item.itemsGroup.length
          ).toFixed(2),
        },
      },
    }));

  // Filtrar y transformar las reservas
  const formattedAppointments = response.data.items
    .filter((item) => item.reserveId !== null && item.comandaId === null)
    .map((item) => ({
      index: generateUniqueId(),
      appointment: {
        id: item.reserveId,
        clientId: idClient,
        price: item.itemsGroup[0].price,
        startTime: "3:45 PM",
        endTime: "4:45 PM",
        prepaid: 0,
        status: 1,
        services: item.itemsGroup.map((groupItem) => ({
          index: generateUniqueId(),
          item: {
            id: groupItem.reserveDetailId,
            priceId: groupItem.priceServiceId,
            name: groupItem.description,
            price: groupItem.price,
            priceUnit: groupItem.unitPrice,
            igv: (groupItem.price * 0.18).toFixed(2),
            total: (groupItem.price * 1.18).toFixed(2),
            isFree: false,
            availableEmployees: [
              {
                id: groupItem.employeeId,
                name: groupItem.employeeName,
              },
            ],
            duration: 60,
            reserveDetailId: groupItem.reserveDetailId,
          },
          employeeId: groupItem.employeeId,
          summary: {
            quantity: groupItem.quantity.toString(),
            subtotal: 0,
            discount: 0,
            addition: 0,
            modifierIsPercentage: false,
            total: groupItem.price,
          },
        })),
      },
    }));

  // dispatch(updateCommands(commands));
  // dispatch(addSaleAppointments(formattedAppointments));
  return {
    ...response,
    data: {
      commands,
      appointments: formattedAppointments,
    },
  };
};

// Función auxiliar para generar un ID único
function generateUniqueId() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
