import React, { useContext } from "react";
import style from "./SaleAppointmentServiceRow.module.css";
// @ts-ignore
import { SelectInput } from "@viuti/recursos";
import { useDispatch } from "react-redux";
import { updateSaleAppointmentServiceEmployee } from "@ReduxService/states/currentSaleSlice";
import { changeModalEditPrices } from "@Hooks/UpdateModalType/changeModalEditPrices/changeModalEditPrices";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import { APPOINTMENTS_TYPE } from "@Models/const/salesOptions";
import { formatToSoles } from "@Utilities/FormatsHandler";

interface ISaleAppointmentServiceRow {
  appointmentIndex: string;
  sale: ISaleService;
}

const SaleAppointmentServiceRow = ({
  appointmentIndex,
  sale,
}: ISaleAppointmentServiceRow) => {
  const dispatch = useDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const availableEmployees = sale.item.availableEmployees.map((employee) => ({
    value: employee.id,
    name: `${employee.name} ${employee.lastName}`,
  }));

  return (
    <div
      key={sale.item.id}
      className={style.sales__container__body__sales__row}
    >
      <span className={style.sales__container__body__sales__row__column}>
        {sale.item.name}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        No aplica
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <SelectInput
          items={availableEmployees}
          value={sale.employeeId}
          handleChange={(e) => {
            dispatch(
              updateSaleAppointmentServiceEmployee({
                appointmentIndex: appointmentIndex,
                serviceIndex: sale.index,
                employeeId: e.target.value,
              })
            );
          }}
        />
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        1
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        {sale.item.priceUnit
          ? sale.item.priceUnit
          : sale.summary?.discount
          ? sale.summary.discount
          : 0}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        {sale.summary?.total ? sale.summary.total : 0}
      </span>
    </div>
  );
};

export default SaleAppointmentServiceRow;
