import React from "react";
import style from "./SaleTable.module.css";
import {
  APPOINTMENTS_TYPE,
  COMMANDS_TYPE,
  GIFTCARDS_TYPE,
  PACKAGES_TYPE,
  PRODUCTS_TYPE,
  SERVICES_TYPE,
} from "@Models/const/salesOptions";
import {
  categoryIcon,
  categoryName,
} from "@Components/wrappers/SalesContainer/categories";
import SaleServiceRow from "@Components/ui/atoms/SaleServiceRow/SaleServiceRow";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import SaleProductRow from "@Components/ui/atoms/SaleProductRow/SaleProductRow";
import SaleAppointmentRow from "@Components/ui/atoms/SaleAppointmentRow/SaleAppointmentRow";
import SalePackageRow from "@Components/ui/atoms/SalePackageRow/SalePackageRow";
import { SaleCommandRow } from "@Components/ui/atoms/SaleCommandRow/SaleCommandRow";
import SaleGiftcardRow from "@Components/ui/atoms/SaleGiftcardRow/SaleGiftcardRow";

interface ISaleTable {
  category: number;
  sales: any;
}

/* The SaleTable component is a molecule that displays the sales of a specific category.
It receives the category and the sales to display. The component objetive is render each
sale added previously in the correct section. */

const SaleTable = ({ category, sales }: ISaleTable) => {
  return (
    <>
      <h3 className={style.sales__container__body__category}>
        <Icon path={categoryIcon(category)} size={15} color={"#45348e"} />{" "}
        {categoryName(category)}
      </h3>
      <div className={style.sales__container__body__sales}>
        <div className={style.sales__container__body__sales__container}>
          {category === SERVICES_TYPE &&
            sales.map((sale: ISaleService) => (
              <SaleServiceRow key={sale.index} sale={sale} />
            ))}
          {category === PRODUCTS_TYPE &&
            sales.map((sale: ISaleProduct) => (
              <SaleProductRow key={sale.index} sale={sale} />
            ))}
          {category === APPOINTMENTS_TYPE &&
            sales.map((sale: ISaleAppointment) => {
              return <SaleAppointmentRow key={sale.index} sale={sale} />;
            })}
          {category === PACKAGES_TYPE &&
            sales.map((sale: ISalePackage) => {
              return <SalePackageRow key={sale.index} sale={sale} />;
            })}
          {category === COMMANDS_TYPE &&
            sales.map((sale: any) => {
              return <SaleCommandRow key={sale.index} sale={sale} />;
            })}
          {category === GIFTCARDS_TYPE &&
            sales.map((sale: ISaleGiftcard) => {
              return <SaleGiftcardRow key={sale.index} sale={sale} />;
            })}
        </div>
      </div>
    </>
  );
};

export default SaleTable;
