import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = {
  movements: [],
  pages: 0,
  currentPagination: 1,
  shouldReloadTable: false,
};

const initialState: IMovementSlice = (() => {
  return DEFAULT_STATE;
})();

export const movementsSlices = createSlice({
  name: "movementsSlices",
  initialState,
  reducers: {
    updateMovements: (state, action: PayloadAction<IMovement[]>) => {
      state.movements = action.payload;
    },
    updatePages: (state, action: PayloadAction<number>) => {
      state.pages = action.payload;
    },
    updateCurrentPagination: (state, action: PayloadAction<number>) => {
      state.currentPagination = action.payload;
    },
    updateShouldReloadTable: (state, action: PayloadAction<boolean>) => {
      state.shouldReloadTable = action.payload;
    },
  },
});

export const {
  updateMovements,
  updatePages,
  updateCurrentPagination,
  updateShouldReloadTable,
} = movementsSlices.actions;

export default movementsSlices.reducer;
