import axios from "axios";
import { clientsBaseUrl, config } from "./constGlobals";

export const getClientsByText_service = async (search: string) => {
  const url = `${clientsBaseUrl}/Cliente/SearchCustomers/${search}`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error.response?.status || 500,
      message:
        error.response?.data?.message ||
        "Hubo un error al obtener los clientes.",
      data: [],
    };
  }
};
