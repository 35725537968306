import qr from "qrcode-svg";

export default function qrSvg(data: string) {
  const qrSvg = new qr({
    content: data,
    padding: 0,
    width: 80,
    height: 80,
    color: "#000000",
    background: "#ffffff",
    ecl: "M",
  });
  return qrSvg.svg();
}
