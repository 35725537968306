import React, { useEffect } from "react";
import { useAppDispatch } from "@Hooks/store";
import { DISCOUNT_TYPE } from "@Models/const/priceModifier";
import {
  updateSaleAppointmentSummary,
  updateSaleProductSummary,
  updateSaleServiceSummary,
} from "@ReduxService/states/currentSaleSlice";
import {
  APPOINTMENTS_TYPE,
  PRODUCTS_TYPE,
  SERVICES_TYPE,
} from "@Models/const/salesOptions";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";

const useEditPriceSubmit = (
  modalContext: AcceptCancelModalContextProps,
  modifierType: string,
  sale: {
    appointmentIndex?: string;
    index?: string;
    summary?: IItemSummary;
  },
  summary: IItemSummary,
  modifier: {
    modifierSoles: number;
    modifierPercentage: number;
  },
  backendSummary: {
    grossPrice: number;
    taxes: number;
    subtotal: number;
    discount: number;
    total: number;
  },
  type: number,
  isSubmitDisabled: boolean
) => {
  const dispatch = useAppDispatch();

  const formattedModifier =
    modifierType === DISCOUNT_TYPE ? "discount" : "addition";

  const priceModification = summary.modifierIsPercentage
    ? modifier.modifierPercentage
    : modifier.modifierSoles;

  const newSummary = {
    ...summary,
    total: backendSummary.total,
    subtotal: summary.subtotal,
    [formattedModifier]: Math.abs(priceModification),
  };

  const submitAction = () => {
    switch (type) {
      case SERVICES_TYPE:
        dispatch(
          updateSaleServiceSummary({
            index: sale.index,
            summary: newSummary,
          })
        );
        break;
      case PRODUCTS_TYPE:
        dispatch(
          updateSaleProductSummary({
            index: sale.index,
            summary: newSummary,
          })
        );
        break;
      case APPOINTMENTS_TYPE:
        dispatch(
          updateSaleAppointmentSummary({
            appointmentIndex: sale.appointmentIndex,
            serviceIndex: sale.index,
            summary: newSummary,
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      textButtonTwo: "Modificar",
      actionButtonTwo: () => {
        submitAction();
        modalContext.handleVisibilityChange({
          isVisible: false,
          setIsVisible: modalContext.visibility.setIsVisible,
        });
      },
      isDisabled: !summary || isSubmitDisabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary, isSubmitDisabled]);
};

export default useEditPriceSubmit;
