import { useState, useEffect, useRef } from "react";
import { useAppDispatch } from "@Hooks/store";
import { debounce } from "lodash";
import { getGiftcardDataByCode_adapter } from "@Adapters/getGiftcardDataByCode.adapter";

export const useGiftcardValidation = () => {
  const dispatch = useAppDispatch();
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [availableAmount, setAvailableAmount] = useState<number | null>(null);
  const [giftcardId, setGiftcardId] = useState<number | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const validateGiftcard = async (code: string) => {
    if (code.length < 5) {
      setCodeError("El código debe tener al menos 5 caracteres");
      setIsCodeValid(false);
      setGiftcardId(null);
      return;
    }

    if (!/[0-9]/.test(code)) {
      setCodeError("El código debe contener al menos un número");
      setIsCodeValid(false);
      setGiftcardId(null);
      return;
    }

    // Cancelar la petición anterior si existe
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Crear nuevo AbortController
    abortControllerRef.current = new AbortController();

    setIsCodeLoading(true);
    setCodeError("");

    try {
      const giftcardData = await getGiftcardDataByCode_adapter(
        code,
        dispatch,
        abortControllerRef.current.signal
      );

      if (!giftcardData?.isValid) {
        setCodeError("El código no es válido o ya fue utilizado");
        setIsCodeValid(false);
        setAvailableAmount(null);
        setGiftcardId(null);
      } else {
        setIsCodeValid(true);
        setCodeError("");
        setAvailableAmount(giftcardData.availableAmount);
        setGiftcardId(giftcardData.id);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        // Ignorar error de abort
        return;
      }
      if (error.status === 499) {
        return;
      } else {
        setCodeError("Error al validar el código");
        setIsCodeValid(false);
        setAvailableAmount(null);
        setGiftcardId(null);
      }
    } finally {
      setIsCodeLoading(false);
    }
  };

  const debouncedValidateGiftcard = debounce(validateGiftcard, 300);

  // Limpiar el AbortController al desmontar
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return {
    isCodeLoading,
    isCodeValid,
    codeError,
    availableAmount,
    giftcardId,
    validateGiftcard: debouncedValidateGiftcard,
  };
};
