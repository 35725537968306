import axios from "axios";
import { config, baseUrl, promotionsBaseUrl } from "./constGlobals";
import { IGiftcardsResponse } from "@Models/interfaces/giftcards";

export const getGiftcards_service = async () => {
  try {
    const response = await axios.get(
      `${promotionsBaseUrl}/Giftcards/GetGiftcardToSale`,
      config
    );
    return response.data;
  } catch (error) {
    return {
      status: error.response?.status || 500,
      message: error.message,
      data: null,
    };
  }
};

export const postGiftcardService = async (
  newGiftcard
): Promise<IGiftcardsResponse> => {
  const url = `${promotionsBaseUrl}/Giftcards/CreateGiftcard`;
  const response = await axios.post(url, newGiftcard, config);
  return {
    isSuccess: response.status === 200,
    userMessage: response.data.message,
    data: response.data.data,
  };
};

interface IValidateGiftcardCodeResponse {
  isSuccess: boolean;
  message: string;
  isAvailable: boolean;
}

export const validateGiftcardCode = async (
  code: string
): Promise<IValidateGiftcardCodeResponse> => {
  try {
    const PATH = `${promotionsBaseUrl}/Giftcards/AvailableCode`;
    const response = await axios.get(PATH, {
      ...config,
      params: { code },
    });

    return {
      isSuccess: true,
      message: "Código validado correctamente",
      isAvailable: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      message: "Error al validar el código",
      isAvailable: false,
    };
  }
};

export interface IGiftcardDataResponse {
  isSuccess: boolean;
  message: string;
  status: number;
  data?: {
    giftcardId: number;
    remainder: number;
    value: number;
    modality: string;
    modalityId: number;
    creationDate: string;
    saleDate: string;
    expirationDate: string;
    daysAvailable: number;
    statusId: number;
    status: string;
  };
}

export const getGiftcardDataByCode = async (
  code: string,
  signal?: AbortSignal
): Promise<IGiftcardDataResponse> => {
  try {
    const PATH = `${baseUrl}/Giftcard/GetGiftcardDataCode`;
    const response = await axios.get(PATH, {
      ...config,
      params: { code },
      signal,
    });

    return {
      isSuccess: true,
      status: 200,
      message: "Datos de giftcard obtenidos correctamente",
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error.code === "ERR_CANCELED" ? 499 : error.response?.status,
      message:
        error.response?.data?.message ||
        "Error al obtener los datos de la giftcard",
    };
  }
};
