import { BASE_ROUTE } from "@Models/routes";
import HomeRoute from "@Routes/context.route";
import { BrowserRouter as Router } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import { store } from "@ReduxService/index";
import { AcceptCancelModalProvider } from "@Hooks/AcceptCancelModalContext";
import { ClientsModalStateProvider } from "@Hooks/NewClientsModalStateContext";

// Route page of the application
export default function App() {
  return (
    <Router basename={BASE_ROUTE}>
      <AcceptCancelModalProvider>
        <ClientsModalStateProvider>
          <Provider store={store}>
            <HomeRoute />
          </Provider>
        </ClientsModalStateProvider>
      </AcceptCancelModalProvider>
    </Router>
  );
}
