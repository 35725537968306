import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = {
  account: null,
  billingDocuments: [],
  electronicBillingAmmount: null,
  electronicBilling: {
    lastPurchaseDate: null,
    isRunningOutOfDocuments: false,
  },
  paymentMethods: [],
  registeredClients: [],
  companyServices: [],
  packages: [],
  clientAppointments: [],
  warehouses: [],
  loungeEmployees: [],
};

const initialState: ISettings = (() => {
  return DEFAULT_STATE;
})();

export const saleConfigSlice = createSlice({
  name: "saleBoxesConfig",
  initialState,
  reducers: {
    updateAccount: (state, action: PayloadAction<IAccountInfo>) => {
      state.account = action.payload;
    },
    updateBillingDocuments: (
      state,
      action: PayloadAction<IBillingDocument[]>
    ) => {
      state.billingDocuments = action.payload;
    },
    updateElectronicBillingAmmount: (state, action: PayloadAction<number>) => {
      state.electronicBillingAmmount = action.payload;
    },
    updateElectronicBilling: (
      state,
      action: PayloadAction<{
        lastPurchaseDate: string;
        isRunningOutOfDocuments: boolean;
      }>
    ) => {
      state.electronicBilling = action.payload;
    },
    updatePaymentMethods: (state, action: PayloadAction<IPaymentMethod[]>) => {
      state.paymentMethods = action.payload;
    },
    updateRegisteredClients: (state, action: PayloadAction<IClient[]>) => {
      state.registeredClients = action.payload;
    },
    updateCompanyServices: (state, action: PayloadAction<IService[]>) => {
      state.companyServices = action.payload;
    },
    updateClientAppointments: (
      state,
      action: PayloadAction<ISaleAppointment[]>
    ) => {
      state.clientAppointments = action.payload;
    },
    updateWarehouses: (state, action: PayloadAction<IWarehouse[]>) => {
      state.warehouses = action.payload;
    },
    updateWarehouseProducts: (
      state,
      action: PayloadAction<{ products: IProduct[]; warehouseId: number }>
    ) => {
      if (action.payload.products.length === 0) {
        state.warehouses = state.warehouses.map((warehouse) => {
          if (warehouse.id === action.payload.warehouseId) {
            warehouse.products = [];
            warehouse.hasFetchedProducts = true;
          }
          return warehouse;
        });
      } else {
        state.warehouses = state.warehouses.map((warehouse) => {
          if (warehouse.id === action.payload.warehouseId) {
            warehouse.products = action.payload.products;
            warehouse.hasFetchedProducts = true;
          }
          return warehouse;
        });
      }
    },
    updateLoungeEmployees: (state, action: PayloadAction<IEmployee[]>) => {
      state.loungeEmployees = action.payload;
    },
    updatePackages: (state, action: PayloadAction<IPackage[]>) => {
      state.packages = action.payload;
    },
  },
});

export const {
  updateAccount,
  updateBillingDocuments,
  updateElectronicBillingAmmount,
  updateElectronicBilling,
  updatePaymentMethods,
  updateRegisteredClients,
  updateCompanyServices,
  updateClientAppointments,
  updateWarehouses,
  updateWarehouseProducts,
  updateLoungeEmployees,
  updatePackages,
} = saleConfigSlice.actions;

export default saleConfigSlice.reducer;
