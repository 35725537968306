import PrintableTicketHTML from "@Components/ui/molecules/PrintableTicketHTML/PrintableTicketHTML";
import React from "react";
import { billingPrintbuilder } from "../../const/billingPrintData";
import { useAppSelector } from "@Hooks/store";

const PayoutTicket = ({
  printableRef,
  summary,
  saleBoxName = "",
  ruc = "",
  address = "",
  typeDocumentName = "",
  localName = "",
  clientDocumentName = "Documento de identidad",
  qrData = "",
  hash = "",
}) => {
  const sales = useAppSelector((state) => state.currentSale.sales);
  const salebox = useAppSelector((state) => state.selectedSaleBox);
  const billingDocument = useAppSelector(
    (state) => state.currentSale.billingDocument
  );
  const billingOptions = useAppSelector(
    (state) => state.saleConfig.billingDocuments
  );
  const selectedBillingOption = billingOptions.find(
    // @ts-ignore
    (option) => option.id === billingDocument
  );
  const lounge = JSON.parse(localStorage.getItem("account_info"));
  const finishedSaleResponse = useAppSelector(
    (state) => state.currentSale.finishedSaleResponse
  );
  const client = useAppSelector((state) => state.currentSale.client);
  const genericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const giftcardPayments = useAppSelector(
    (state) => state.currentSale.giftcardPayments
  );

  const selectedClient = genericClient || client;
  const paymentMethods = useAppSelector(
    (state) => state.currentSale.paymentMethods
  );
  const account = useAppSelector((state) => state.saleConfig.account);
  const isFreeSale = useAppSelector((state) => state.currentSale.isFreeSale);

  const products = [];

  sales.services.forEach((sale) => {
    products.push({
      DESCRIPTION: sale.item.name,
      AMOUNT: sale.summary.quantity,
      TOTAL: (
        (sale.item.price +
          parseFloat(String(sale.summary.addition)) -
          parseFloat(String(sale.summary.discount)) +
          sale.item.igv) *
        parseInt(sale.summary.quantity)
      ).toFixed(2),
    });
  });

  sales.products.forEach((sale) => {
    products.push({
      DESCRIPTION: sale.item.name,
      AMOUNT: sale.summary.quantity,
      TOTAL: (
        (sale.item.price +
          parseFloat(String(sale.summary.addition)) -
          parseFloat(String(sale.summary.discount)) +
          sale.item.igv) *
        parseInt(sale.summary.quantity)
      ).toFixed(2),
    });
  });

  sales.appointments.forEach((appointment) => {
    appointment.appointment.services.forEach((service) => {
      products.push({
        DESCRIPTION: service.item.name,
        AMOUNT: service.summary.quantity,
        TOTAL: (
          service.item.price +
          parseFloat(String(service.summary.addition)) -
          parseFloat(String(service.summary.discount)) +
          parseFloat(String(service.item.igv)) *
            parseInt(service.summary.quantity)
        ).toFixed(2),
      });
    });
  });

  sales.packages.forEach((salePackage) => {
    products.push({
      DESCRIPTION: salePackage.item.name,
      AMOUNT: salePackage.summary.quantity,
      TOTAL: (
        (salePackage.item.price +
          parseFloat(String(salePackage.summary.addition)) -
          parseFloat(String(salePackage.summary.discount)) +
          salePackage.item.igv) *
        parseInt(salePackage.summary.quantity)
      ).toFixed(2),
    });
  });

  sales.commands.forEach((command) => {
    command.command.itemsGroup.forEach((group) => {
      products.push({
        DESCRIPTION: group.item.description,
        AMOUNT: group.summary.quantity,
        TOTAL: (
          (group.item.price +
            parseFloat(String(group.summary.addition)) -
            parseFloat(String(group.summary.discount))) *
          parseInt(group.summary.quantity)
        ).toFixed(2),
      });
    });
  });

  sales.giftcards.forEach((giftcard) => {
    products.push({
      DESCRIPTION: giftcard.item.code,
      AMOUNT: giftcard.summary.quantity,
      TOTAL: giftcard.item.price,
    });
  });

  const formattedPaymentMethods = paymentMethods?.map((paymentMethod) => {
    return {
      DESCRIPTION: paymentMethod.paymentCategory.name,
      AMOUNT: parseFloat(paymentMethod.value),
    };
  });

  // Calculamos el descuento total de giftcards
  const giftcardsDiscount = giftcardPayments?.reduce((acc, payment) => {
    return acc + (parseFloat(String(payment.amount)) || 0);
  }, 0);

  return (
    <div style={{ display: "none" }}>
      <PrintableTicketHTML
        reference={printableRef}
        billingData={billingPrintbuilder({
          titleName: salebox.name || saleBoxName,
          responsable: `${account.name} ${account.lastName}`,
          business: {
            NAME: salebox.businessName || "",
            RUC: salebox.ruc || ruc,
          },
          local: {
            // TODO: CHANGE NOMBRE FOR NAME
            NAME: lounge?.lounge?.name || localName,
            LOCATION: salebox?.businessAddress || address,
            PHONE: "",
            EMAIL: "",
          },
          billingCode:
            finishedSaleResponse.data?.billing.serie +
            "-" +
            finishedSaleResponse.data?.billing?.document,
          billingDocument: selectedBillingOption?.name || typeDocumentName,
          products: products,
          client: {
            NAME: `${selectedClient?.name} ${selectedClient?.lastName}`,
            DOCUMENT_TYPE: client?.documentType?.name || clientDocumentName,
            DOCUMENT: selectedClient?.documentNumber || " - ",
            LOCATION: "",
          },
          pricing: {
            OP_GRAVADAS: Number(parseFloat(summary.subtotal).toFixed(2)),
            OP_EXONERADAS: 0,
            OP_IGV: Number(parseFloat(summary.igv).toFixed(2)),
            OP_ICBPER: Number(parseFloat(summary.icbp).toFixed(2)),
            OP_DESCUENTO: Number(parseFloat(summary.discount).toFixed(2)),
            OP_DESCUENTO_GIFT: Number(giftcardsDiscount.toFixed(2)),
            OP_TOTAL: Number(
              (parseFloat(summary.total) - giftcardsDiscount).toFixed(2)
            ),
          },
          paymentMethods: formattedPaymentMethods,
          dataTicket: {
            hash: hash || finishedSaleResponse.data?.dataTicket?.hash,
            qrData: qrData || finishedSaleResponse.data?.dataTicket?.qrData,
          },
          isFreeSale,
        })}
      />
    </div>
  );
};

export default PayoutTicket;
