import React, { useState } from "react";
import style from "./SalesContainer.module.css";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
// @ts-ignore
import { ConfirmModal, Icon } from "@viuti/recursos";
import { OpenBoxIcon, TrashBinIcon } from "@Models/icons";
import SaleTable from "@Components/ui/molecules/SaleTable/SaleTable";
import { capitalizeWords } from "@Utilities/CapitalizeWords";
import {
  APPOINTMENTS_TYPE,
  COMMANDS_TYPE,
  GIFTCARDS_TYPE,
  PACKAGES_TYPE,
  PRODUCTS_TYPE,
  SERVICES_TYPE,
} from "@Models/const/salesOptions";
import { resetClientSales } from "@ReduxService/states/currentSaleSlice";
import EmptyMessage from "@Components/ui/molecules/EmptyMessage/EmptyMessage";
import ModalPortal from "../postals/ModalPortal";

const SalesContainer = () => {
  const dispatch = useAppDispatch();
  const sales = useAppSelector((state) => state.currentSale.sales);
  const client = useAppSelector((state) => state.currentSale.client);
  const genericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const selectedClient = genericClient || client;

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const isSalesEmpty =
    !sales?.products.length &&
    !sales?.services.length &&
    !sales?.appointments.length &&
    !sales?.packages.length &&
    !sales?.commands.length &&
    !sales?.giftcards.length;

  return (
    <section className={style.sales__container}>
      <ModalPortal>
        <ConfirmModal
          title={"Remover cliente"}
          message={
            <p className={style.remove__client__modal__message}>
              Estás seguro que deseas remover al cliente{" "}
              <b>{`${selectedClient.name} ${selectedClient.lastName}`}</b>? Se
              removerán las ventas ingresadas.
            </p>
          }
          buttonLbl={"Confirmar"}
          controller={{
            visible: isDeleteModalVisible,
            setVisible: setIsDeleteModalVisible,
          }}
          confirmAction={async () => {
            dispatch(resetClientSales());
            return {
              status: "success",
              message: "Producto removido correctamente.",
            };
          }}
        />
      </ModalPortal>
      <div className={style.sales__container__header}>
        <span
          className={style.sales__container__header__title}
        >{`${capitalizeWords(selectedClient.name)} ${capitalizeWords(
          selectedClient.lastName
        )}`}</span>
        <span className={style.sales__container__header__actions}>
          <button
            className={`${style.sales__container__header__actions__button} ${style.sales__container__header__actions__button__close}`}
            onClick={() => setIsDeleteModalVisible(true)}
          >
            <Icon path={TrashBinIcon} size={15} color={"#ba1b1b"} />
          </button>
        </span>
      </div>
      {isSalesEmpty && (
        <EmptyMessage
          icon={{ name: OpenBoxIcon, size: 100 }}
          text="No hay ventas añadidas."
          subtext="Agrega una venta para continuar."
        />
      )}
      {!isSalesEmpty && (
        <div className={style.sales__container__body}>
          <div className={style.sales__container__body__header}>
            <span className={style.sales__container__body__header__column}>
              Concepto
            </span>
            <span className={style.sales__container__body__header__column}>
              Almacén
            </span>
            <span className={style.sales__container__body__header__column}>
              Colaborador
            </span>
            <span className={style.sales__container__body__header__column}>
              Cantidad
            </span>
            <span className={style.sales__container__body__header__column}>
              Precio u.
            </span>
            <span className={style.sales__container__body__header__column}>
              Total
            </span>
            <span
              className={style.sales__container__body__header__column}
            ></span>
          </div>
          {sales.services.length > 0 && (
            <SaleTable category={SERVICES_TYPE} sales={sales.services} />
          )}
          {sales.products.length > 0 && (
            <SaleTable category={PRODUCTS_TYPE} sales={sales.products} />
          )}
          {sales.appointments.length > 0 && (
            <SaleTable
              category={APPOINTMENTS_TYPE}
              sales={sales.appointments}
            />
          )}
          {sales.packages.length > 0 && (
            <SaleTable category={PACKAGES_TYPE} sales={sales.packages} />
          )}
          {sales.commands.length > 0 && (
            <SaleTable category={COMMANDS_TYPE} sales={sales.commands} />
          )}
          {sales.giftcards.length > 0 && (
            <SaleTable category={GIFTCARDS_TYPE} sales={sales.giftcards} />
          )}
        </div>
      )}
    </section>
  );
};

export default SalesContainer;
