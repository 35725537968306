import React, { useContext } from "react";
import style from "./SalePackageRow.module.css";
// @ts-ignore
import { Icon, SelectInput, TextInput, IconWrapper } from "@viuti/recursos";
import { useDispatch } from "react-redux";
import {
  removeSalePackage,
  updateSalePackageQuantity,
  updateSaleProductQuantity,
  updateSalePackageEmployee,
} from "@ReduxService/states/currentSaleSlice";
import { CodeBarIcon, ServiceIcon, TimesIcon } from "@Models/icons";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import { useAppSelector } from "@Hooks/store";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { PACKAGES_TYPE } from "@Models/const/salesOptions";
import { changeModalPackageModifications } from "@Hooks/UpdateModalType/changeModalPackageModifications/changeModalPackageModifications";
import { HiExclamationTriangle } from "react-icons/hi2";

interface ISalePackageRow {
  sale: ISalePackage;
}

interface IEmployeeSelect {
  employeeId: number;
  availableEmployees: Array<{ id: number; name: string; lastName: string }>;
  onChange: (employeeId: string) => void;
}

// Componente para el select de empleado
const EmployeeSelect = ({
  employeeId,
  availableEmployees,
  onChange,
}: IEmployeeSelect) => {
  const noEmployeesAvailable = availableEmployees.length === 0;

  const formattedEmployees =
    availableEmployees.length > 0
      ? availableEmployees.map((employee) => ({
          value: employee?.id.toString(),
          name: `${employee?.name} ${employee?.lastName}`,
        }))
      : [{ value: "0", name: "No hay empleados disponibles" }];

  // Modificamos el useEffect para manejar ambos casos
  React.useEffect(() => {
    // Si no hay empleado seleccionado y hay empleados disponibles, selecciona el primero
    if (employeeId === 0 && availableEmployees.length > 0) {
      onChange(availableEmployees[0].id.toString());
    }
    // Si no hay empleados disponibles y no hay empleado seleccionado, asigna 0
    else if (employeeId === 0 && availableEmployees.length === 0) {
      onChange("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, availableEmployees]);

  return (
    <SelectInput
      items={formattedEmployees}
      value={employeeId ? employeeId.toString() : ""}
      handleChange={(e) => onChange(e.target.value)}
      touched={noEmployeesAvailable}
      required
      error={
        noEmployeesAvailable
          ? "No hay empleados asignados para realizar este servicio"
          : ""
      }
    />
  );
};

// Type guards para ayudar con el tipado
interface ServiceAssignment {
  idService: number;
  employeeId: number;
}

interface ProductAssignment {
  idProduct: number;
  employeeId: number;
}

const SalePackageRow = ({ sale }: ISalePackageRow) => {
  const dispatch = useDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const loungeEmployees = useAppSelector(
    (state) => state.saleConfig.loungeEmployees
  );
  const isStockAvailable = sale.item.stock === null || sale.item.stock > 0;

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.value.replace(/\D/g, "");

    dispatch(
      updateSalePackageQuantity({
        index: sale.index,
        quantity: quantity,
        subtotal: parseFloat(
          ((sale.item.price + sale.item.igv) * parseInt(quantity || "1"))
            .toFixed(2)
            .toString()
        ),
        total: (
          (parseFloat(String(sale.item.price + sale.item.igv)) -
            parseFloat(String(sale.summary.discount)) +
            parseFloat(String(sale.summary.addition))) *
          parseInt(quantity || "1")
        ).toFixed(2) as any,
      })
    );
  };

  const handleQuantityBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const quantity = e.target.value || "0";
    const quantityToNumber = parseInt(quantity);

    if (quantityToNumber === 0) {
      dispatch(
        updateSaleProductQuantity({
          index: sale.index,
          quantity: "1",
          subtotal: sale.item.price + sale.item.igv,
          total: sale.item.price + sale.item.igv,
        })
      );
    }
  };

  const isPackagePromoValid =
    sale.item.isValid && isStockAvailable && sale.item.isAvailable;

  // Función para obtener el empleado asignado
  const getAssignedEmployee = (itemId: number, isService: boolean) => {
    const items = isService ? sale.services : sale.products;
    if (isService) {
      return (
        (items as ServiceAssignment[]).find((i) => i.idService === itemId)
          ?.employeeId || 0
      );
    } else {
      return (
        (items as ProductAssignment[]).find((i) => i.idProduct === itemId)
          ?.employeeId || 0
      );
    }
  };

  // Función para manejar el cambio de empleado
  const handleEmployeeChange = (
    itemId: number,
    isService: boolean,
    employeeId: string
  ) => {
    dispatch(
      updateSalePackageEmployee({
        packageIndex: sale.index,
        itemId,
        isService,
        employeeId: parseInt(employeeId),
      })
    );
  };

  return (
    <div className={style.sales__container__body__sales__row}>
      {/* Fila principal del paquete */}
      <div className={style.sales__container__body__sales__row_package}>
        <span
          className={`${style.sales__container__body__sales__row__column} ${
            style.package__name
          } ${
            isPackagePromoValid ? style.valid_package : style.invalid_package
          }`}
          onClick={() =>
            changeModalPackageModifications(
              modalContext,
              sale.item,
              sale,
              PACKAGES_TYPE
            )
          }
        >
          {!isPackagePromoValid && (
            <IconWrapper
              icon={HiExclamationTriangle}
              size={15}
              color="#ff9800"
            />
          )}
          {sale.item.name}
        </span>
        <span className={style.sales__container__body__sales__row__column}>
          No aplica
        </span>
        <span className={style.sales__container__body__sales__row__column}>
          -
        </span>
        <span className={style.sales__container__body__sales__row__column}>
          <TextInput
            value={sale.summary.quantity}
            inputProps={{
              placeholder: "1",
              type: "number",
              min: 0,
              max: sale.item.stock,
              onChange: handleQuantityChange,
              onBlur: handleQuantityBlur,
            }}
            width="50px"
          />
        </span>
        <span className={style.sales__container__body__sales__row__column}>
          {formatToSoles(sale.item.price + sale.item.igv, false)}
        </span>
        <span className={style.sales__container__body__sales__row__column}>
          {sale.summary.total}
        </span>
        <span className={style.sales__container__body__sales__row__column}>
          <button
            className={style.sales__container__body__sales__row__column__remove}
            onClick={() => dispatch(removeSalePackage(sale.index))}
          >
            <Icon path={TimesIcon} size={12} color={"#45348e"} />
          </button>
        </span>
      </div>
      <div
        className={
          style.sales__container__body__sales__row_package_container_services_products
        }
      >
        {/* Fila para servicios */}
        {sale.item.services?.map((service) => (
          <div
            key={service.priceId}
            className={style.sales__container__body__sales__row_package_service}
          >
            <span
              className={`${style.sales__container__body__sales__row__column}`}
            >
              <div
                className={
                  style.sales__container__body__sales__row__column__icon
                }
              >
                <Icon path={ServiceIcon} size={12} color={"#45348e"} />
                {service.name}
              </div>
            </span>
            <span className={style.sales__container__body__sales__row__column}>
              -
            </span>
            <span className={style.sales__container__body__sales__row__column}>
              <EmployeeSelect
                employeeId={getAssignedEmployee(service.priceId, true)}
                availableEmployees={service.availableEmployees}
                onChange={(employeeId) =>
                  handleEmployeeChange(service.priceId, true, employeeId)
                }
              />
            </span>
          </div>
        ))}

        {/* Filas para productos */}
        {sale.item.products?.map((product) => (
          <div
            key={product.localId}
            className={style.sales__container__body__sales__row_package_product}
          >
            <span
              className={`${style.sales__container__body__sales__row__column}`}
            >
              <div
                className={
                  style.sales__container__body__sales__row__column__icon
                }
              >
                <Icon path={CodeBarIcon} size={12} color={"#45348e"} />
                {product.name}
              </div>
            </span>
            <span className={style.sales__container__body__sales__row__column}>
              -
            </span>
            <span className={style.sales__container__body__sales__row__column}>
              <EmployeeSelect
                employeeId={getAssignedEmployee(product.localId, false)}
                availableEmployees={loungeEmployees}
                onChange={(employeeId) =>
                  handleEmployeeChange(product.localId, false, employeeId)
                }
              />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalePackageRow;
