// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UPkTjDXtHu0D5fYzha7O {
  width: 100%;
  /* 100% - Header height */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  overflow-y: auto;
  margin-top: 24px;
}

.mOML4N3N9hFB6cVnJ0_9 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Hsp3cJE5Fxm20LVGFKmF {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}

.eLlrq1LnSGF2Cd3PfPJ2 {
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0px 15px 0px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SaleboxReport/SaleboxReport.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".body {\n  width: 100%;\n  /* 100% - Header height */\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 1rem;\n  overflow-y: auto;\n  margin-top: 24px;\n}\n\n.loading {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.error {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding-top: 20px;\n}\n\n.error_message {\n  color: #4d4d4d;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 18px;\n  font-weight: 700;\n  margin: 10px 0px 15px 0px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `UPkTjDXtHu0D5fYzha7O`,
	"loading": `mOML4N3N9hFB6cVnJ0_9`,
	"error": `Hsp3cJE5Fxm20LVGFKmF`,
	"error_message": `eLlrq1LnSGF2Cd3PfPJ2`
};
export default ___CSS_LOADER_EXPORT___;
