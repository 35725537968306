import React from "react";
// @ts-ignore
import style from "./SaleIndicator.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";

interface ISaleIndicatorProps {
  icon: string;
  number: number;
  title: string;
}

const SaleIndicator = ({ icon, number, title }: ISaleIndicatorProps) => {
  return (
    <span className={style.sale_indicator__container} title={title}>
      <Icon path={icon} size={15} color={"#45348e"} />
      <p className={style.sale_indicator__number}>{number}</p>
    </span>
  );
};

export default SaleIndicator;
