import { createSlice } from "@reduxjs/toolkit";

export const reserveCommandsSlice = createSlice({
  name: "reserveCommands",
  initialState: {
    commands: [],
    appointments: [],
  },
  reducers: {
    updateCommandsAppointments: (state, action) => {
      state.commands = action.payload.commands;
      state.appointments = action.payload.appointments;
    },
    clearReserveCommands: (state) => {
      state.commands = [];
      state.appointments = [];
    },
  },
});

export default reserveCommandsSlice.reducer;
export const { updateCommandsAppointments, clearReserveCommands } =
  reserveCommandsSlice.actions;
