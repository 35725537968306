import React, { useEffect, useState } from "react";
import {
  ButtonWithoutIcon,
  Icon,
  TextInput,
} from "@Models/resources-components";
import style from "./StatusSaleBoxModal.module.css";
import {
  CashRegisterStrokeIcon,
  CircleAlertIcon,
  ValidatedIcon,
} from "@Models/icons";
import { handleInitialCashChange } from "./functions/handleInitialCashChange";
import { handleInitialCashBlur } from "./functions/handleInitialCashBlur";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { STATUS_OK } from "@Models/httpsStatus";
import { BASE_ROUTE } from "@Models/routes";
import { openSaleBoxProps } from "./const/openSaleBoxProps";
import { closeSaleBoxProps } from "./const/closeSaleBoxProps";
import { useAppSelector } from "@Hooks/store";
import { openCloseSaleBox_adapter } from "@Adapters/openCloseSaleBox.adapter";
import { lastCashCount_adapter } from "@Adapters/lastCashCount.adapter";

interface IStatusSaleBoxModalProps {
  wantToOpen: boolean;
  saleBox: ISaleBox;
  handleSubmit: (initialCash: string) => Promise<IResponse>;
}

const StatusSaleBoxModal = ({
  wantToOpen,
  saleBox,
  handleSubmit,
}: IStatusSaleBoxModalProps) => {
  const saleConfig = useAppSelector((state) => state.saleConfig);
  const [lastCashCount, setLastCashCount] = useState<string>("");
  const currentStatusProps = wantToOpen ? openSaleBoxProps : closeSaleBoxProps;
  const [handleSubmitResponse, setHandleSubmitResponse] = useState<IResponse>({
    isSuccessful: null,
    status: 0,
    message: "",
    data: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLastCashCount, setIsLoadingLastCashCount] = useState(true);
  const [lastCashCountError, setLastCashCountError] = useState<string | null>(
    null
  );

  const [values, setValues] = useState<{ initialCash: string }>({
    initialCash: "",
  });
  const [touched, setTouched] = useState<{ initialCash: boolean }>({
    initialCash: false,
  });

  const isSubmitButtonDisabled = isLoading;

  useEffect(() => {
    const fetchLastCashCount = async () => {
      setIsLoadingLastCashCount(true);
      setLastCashCountError(null);
      try {
        const response = await lastCashCount_adapter(saleBox.id);
        if (response.isSuccessful) {
          setLastCashCount(String(response.data));
        } else {
          setLastCashCountError(
            response.message || "Error al obtener el último arqueo de caja."
          );
        }
      } catch (error) {
        setLastCashCountError(
          error.message || "Error al obtener el último arqueo de caja."
        );
      } finally {
        setIsLoadingLastCashCount(false);
      }
    };
    fetchLastCashCount();
  }, [saleBox.id, wantToOpen]);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await openCloseSaleBox_adapter(saleBox.id);
      setHandleSubmitResponse(response);

      if (response.status === STATUS_OK) {
        setTimeout(() => {
          setHandleSubmitResponse({
            isSuccessful: null,
            status: 0,
            message: "",
            data: null,
          });
          window.location.href = `${BASE_ROUTE}/${saleBox.id}`;
        }, 2000);
      }
    } catch (error) {
      // Captura el error y actualiza el estado
      setHandleSubmitResponse({
        isSuccessful: false,
        status: error.status || 500,
        message: error.message || "Ocurrió un error inesperado.",
        data: null,
      });
      setIsLoading(false);
    }
  };

  let openSaleboxButtonText = "";
  if ((wantToOpen && !saleBox.isOpen) || saleBox.needsCashCount) {
    openSaleboxButtonText = currentStatusProps.inputs.submit__button;
  } else {
    openSaleboxButtonText = currentStatusProps.inputs.submit__button_continue;
  }

  let statusContent = null;
  let statusNote = "";
  if (wantToOpen) {
    if ((wantToOpen && !saleBox.isOpen) || saleBox.needsCashCount) {
      statusNote = currentStatusProps.messages.cash_count__note;
    } else {
      statusNote = currentStatusProps.messages.cash_count__note_already_open;
    }
  } else {
    statusNote = currentStatusProps.messages.cash_count__note;
  }

  if (handleSubmitResponse.status === 0) {
    statusContent = (
      <p className={style.StatusSaleBoxModal__status__note}>{statusNote}</p>
    );
  } else if (handleSubmitResponse.status !== STATUS_OK) {
    statusContent = (
      <span
        className={`${style.StatusSaleBoxModal__status__container} ${style.StatusSaleBoxModal__status__error}`}
      >
        <div className={style.StatusSaleBoxModal__status__title}>
          <Icon path={CircleAlertIcon} color={"#FF0000"} size={18} />
          <p className={style.StatusSaleBoxModal__status__title__error}>
            No se ha podido {!wantToOpen ? "cerrar" : "abrir"} la caja
          </p>
        </div>
        {handleSubmitResponse.data?.lastAmount ? (
          <p className={style.StatusSaleBoxModal__status__note}>
            El monto en efectivo no coincide con el último cierre. El último
            monto arqueado registra una diferencia de{" "}
            <strong>
              {formatToSoles(handleSubmitResponse.data?.lastAmount)}
            </strong>
            .
          </p>
        ) : (
          <p className={style.StatusSaleBoxModal__status__note}>
            {handleSubmitResponse.message}
          </p>
        )}
      </span>
    );
  } else {
    statusContent = (
      <span
        className={`${style.StatusSaleBoxModal__status__container} ${style.StatusSaleBoxModal__status__success}`}
      >
        <div className={style.StatusSaleBoxModal__status__title}>
          <Icon path={ValidatedIcon} color={"#00B780"} size={18} />
          <p className={style.StatusSaleBoxModal__status__title__success}>
            {currentStatusProps.messages.cash_count__success}
          </p>
        </div>
        <div className={style.loader}></div>
        <p className={style.StatusSaleBoxModal__status__note}>
          {currentStatusProps.messages.cash_count__success_instruction}
        </p>
      </span>
    );
  }

  return (
    <div className={style.StatusSaleBoxModal__container}>
      <p className={style.StatusSaleBoxModal__title}>
        {(wantToOpen && !saleBox.isOpen) || saleBox.needsCashCount
          ? currentStatusProps.messages.salebox_want_to_open
          : currentStatusProps.messages.salebox_already_open}
      </p>
      <span className={style.StatusSaleBoxModal__info}>
        <p>
          <strong className={style.StatusSaleBoxModal__info__label}>
            Local:
          </strong>{" "}
          {saleBox.localName}
        </p>
        {saleBox.businessAddress && (
          <>
            <p>
              <strong className={style.StatusSaleBoxModal__info__label}>
                Dirección de facturación:
              </strong>{" "}
              {saleBox.businessAddress}
            </p>
            <p>
              <strong className={style.StatusSaleBoxModal__info__label}>
                Documentos disponibles:
              </strong>{" "}
              {saleConfig.electronicBillingAmmount} u.
            </p>
          </>
        )}
        {isLoadingLastCashCount ? (
          <div className={style.loader} />
        ) : (
          <p className={style.StatusSaleBoxModal__amountContainer}>
            <strong className={style.StatusSaleBoxModal__amountLabel}>
              {wantToOpen ? "Último arqueo de caja:" : "Cantidad de efectivo:"}
            </strong>{" "}
            <span
              className={`${style.StatusSaleBoxModal__amountValue} ${
                !lastCashCount && !isLoadingLastCashCount
                  ? style.StatusSaleBoxModal__error
                  : ""
              }`}
              style={{
                color: lastCashCountError ? "#FF0000" : "#392b75",
              }}
            >
              {lastCashCountError
                ? lastCashCountError
                : formatToSoles(lastCashCount)}
            </span>
          </p>
        )}
        <p className={style.StatusSaleBoxModal__info__note}>
          {lastCashCountError
            ? "Hubo un error al obtener el último arqueo de caja. Por favor, intenta nuevamente más tarde."
            : wantToOpen
            ? "Este es el monto con el que cerraste tu caja, si no coincide con lo que tienes, ingresa a la caja y genera el movimiento de salida o entrada para cuadrar tu caja."
            : "Este es el monto que tienes en la caja, si no coincide con lo que tienes, ingresa a la caja y genera el movimiento de salida o entrada para cuadrar tu caja."}
        </p>
      </span>

      <form
        className={style.StatusSaleBoxModal__form}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <ButtonWithoutIcon
          isPrimary
          textBttn={
            wantToOpen
              ? openSaleboxButtonText
              : currentStatusProps.inputs.submit__button
          }
          handleClick={onSubmit}
          isDisabled={isSubmitButtonDisabled}
          isLoading={isLoading}
          width="100%"
        />
      </form>
      <hr className={style.StatusSaleBoxModal__separator} />
      <Icon path={CashRegisterStrokeIcon} color={"#6b5bb0"} size={48} />
      {statusContent}
    </div>
  );
};

export default StatusSaleBoxModal;
