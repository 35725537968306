// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YrOHp8RpcpvEEMHmrSJn {
  width: 100%;
  min-width: 766px;
  display: grid;
  grid-template-columns:
    minmax(160px, 2fr)
    minmax(130px, 1.5fr)
    minmax(150px, 1.5fr)
    minmax(85px, 1fr)
    minmax(70px, 1fr)
    minmax(75px, 1fr)
    minmax(40px, 40px);
  gap: 5px;
  padding: 10px 12px;
  border-radius: 4px;

  border: 1px solid #e8e3ff;
}

.scLUS71F0ELPxWhfqEjz {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #45348e;
}

.bK_PwoubJJ0HtkocXUv8 {
  text-decoration: underline;
  color: #45348e;
  font-weight: 600;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    font-weight: 700;
  }
}

.NuVE1hndLnHWOfv0nnpx {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border: none;
  border-radius: 50%;
  background-color: #e8e3ff;
  color: #45348e;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    filter: brightness(1.05);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/SaleAppointmentServiceRow/SaleAppointmentServiceRow.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb;;;;;;;sBAOoB;EACpB,QAAQ;EACR,kBAAkB;EAClB,kBAAkB;;EAElB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,0BAA0B;EAC1B,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iCAAiC;;EAEjC;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,WAAW;EACX,YAAY;;EAEZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,iCAAiC;;EAEjC;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".sales__container__body__sales__row {\n  width: 100%;\n  min-width: 766px;\n  display: grid;\n  grid-template-columns:\n    minmax(160px, 2fr)\n    minmax(130px, 1.5fr)\n    minmax(150px, 1.5fr)\n    minmax(85px, 1fr)\n    minmax(70px, 1fr)\n    minmax(75px, 1fr)\n    minmax(40px, 40px);\n  gap: 5px;\n  padding: 10px 12px;\n  border-radius: 4px;\n\n  border: 1px solid #e8e3ff;\n}\n\n.sales__container__body__sales__row__column {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  font-size: 0.875rem;\n  font-weight: 600;\n  color: #45348e;\n}\n\n.sales__container__body__sales__row__column__price {\n  text-decoration: underline;\n  color: #45348e;\n  font-weight: 600;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    font-weight: 700;\n  }\n}\n\n.sales__container__body__sales__row__column__remove {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  width: 32px;\n  height: 32px;\n\n  border: none;\n  border-radius: 50%;\n  background-color: #e8e3ff;\n  color: #45348e;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    filter: brightness(1.05);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sales__container__body__sales__row": `YrOHp8RpcpvEEMHmrSJn`,
	"sales__container__body__sales__row__column": `scLUS71F0ELPxWhfqEjz`,
	"sales__container__body__sales__row__column__price": `bK_PwoubJJ0HtkocXUv8`,
	"sales__container__body__sales__row__column__remove": `NuVE1hndLnHWOfv0nnpx`
};
export default ___CSS_LOADER_EXPORT___;
