import { getConceptsMovements } from "@Adapters/getConceptsMovements.adapter";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import React, { useEffect } from "react";

const useConceptsMovements = () => {
  const dispatch = useAppDispatch();
  const concepts = useAppSelector((state) => state.concepts.concepts);
  const entryConcepts = useAppSelector((state) => state.concepts.entryConcepts);
  const outputConcepts = useAppSelector(
    (state) => state.concepts.outputConcepts
  );

  useEffect(() => {
    (async () => {
      // Se obtienen los conceptos de movimiento
      await getConceptsMovements(dispatch);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { concepts, entryConcepts, outputConcepts };
};

export default useConceptsMovements;
