import React from "react";
import style from "./SaleIndicators.module.css";
import SaleIndicator from "@Components/ui/atoms/SaleIndicator/SaleIndicator";
import {
  CalendarIcon,
  CodeBarIcon,
  CommandIcon,
  PackageIcon,
  ServiceIcon,
  GiftIcon,
} from "@Models/icons";

interface ISaleIndicatorsProps {
  sales: {
    products: ISaleProduct[];
    services: ISaleService[];
    appointments: ISaleAppointment[];
    packages: ISalePackage[];
    commands: ISaleCommand[];
    giftcards: ISaleGiftcard[];
  };
}

const SaleIndicators = ({ sales }: ISaleIndicatorsProps) => {
  const serviceSalesQuantity = sales.services.length;
  const productSalesQuantity = sales.products.length;
  const appointmentSalesQuantity = sales.appointments.length;
  const packageSalesQuantity = sales.packages.length;
  const commandsSalesQuantity = sales.commands.length;
  const giftcardsSalesQuantity = sales.giftcards.length;
  return (
    <div className={style.container}>
      {serviceSalesQuantity > 0 && (
        <SaleIndicator
          icon={ServiceIcon}
          title="Servicios añadidos"
          number={serviceSalesQuantity}
        />
      )}
      {productSalesQuantity > 0 && (
        <SaleIndicator
          icon={CodeBarIcon}
          title="Productos añadidos"
          number={productSalesQuantity}
        />
      )}
      {appointmentSalesQuantity > 0 && (
        <SaleIndicator
          icon={CalendarIcon}
          title="Reservas añadidas"
          number={appointmentSalesQuantity}
        />
      )}
      {packageSalesQuantity > 0 && (
        <SaleIndicator
          icon={PackageIcon}
          title="Paquetes añadidos"
          number={packageSalesQuantity}
        />
      )}
      {commandsSalesQuantity > 0 && (
        <SaleIndicator
          icon={CommandIcon}
          title="Comandas añadidas"
          number={commandsSalesQuantity}
        />
      )}
      {giftcardsSalesQuantity > 0 && (
        <SaleIndicator
          icon={GiftIcon}
          title="Tarjetas añadidas"
          number={giftcardsSalesQuantity}
        />
      )}
    </div>
  );
};

export default SaleIndicators;
