import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateBillingDocuments } from "@ReduxService/states/saleConfigSlice";
import { getBillingDocuments_service } from "../services/billingDocuments";

export const getBillingDocuments = async (
  dispatch: AppDispatch,
  saleBoxId: number
) => {
  const response: IResponse = await getBillingDocuments_service(saleBoxId);

  if (!response.isSuccessful) {
    return response;
  }

  const formattedResponse: IBillingDocument[] = response.data
    .map((billingDocument: any) => {
      if (billingDocument.documentType !== 3)
        return {
          id: billingDocument.documentType,
          serie: billingDocument.serialNumber,
          serialNumber: billingDocument.number,
          isAvailable: billingDocument.active,
          name:
            billingDocument.documentName.charAt(0).toUpperCase() +
            billingDocument.documentName.slice(1).toLowerCase(),
        };
    })
    .filter((billingDocument: any) => billingDocument !== undefined);

  dispatch(updateBillingDocuments(formattedResponse));

  return response;
};
