import React, { useState } from "react";
import style from "./SaleboxReportFilter.module.css";
// @ts-ignore
import { ButtonWithoutIcon, DateInput, SelectInput } from "@viuti/recursos";
import { getSaleBoxReport } from "@Adapters/getSaleBoxReport.adapter";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { updateReportFilters } from "@ReduxService/states/saleBoxReportSlice";

interface ISaleboxReportFilterProps {
  isDisabled?: boolean;
}

const SaleboxReportFilter = ({
  isDisabled = false,
}: ISaleboxReportFilterProps) => {
  const dispatch = useAppDispatch();
  const values = useAppSelector((state) => state.saleboxReports);
  const saleBoxes = useAppSelector((state) => state.saleBoxesList);
  const [isSubmitPending, setIsSubmitPending] = useState(false);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateReportFilters(e));
  };

  const onSubmit = async () => {
    setIsSubmitPending(true);
    await getSaleBoxReport(dispatch, values);
    setIsSubmitPending(false);
  };

  return (
    <div className={style.filter__container}>
      <span className={style.filter__salebox}>
        <SelectInput
          label="Caja"
          value={values.salebox}
          items={saleBoxes}
          handleChange={handleValueChange}
          name="salebox"
        />
      </span>
      <span className={style.filter__dates}>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Desde"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: values.fromDate,
              onChange: handleValueChange,
              name: "fromDate",
            }}
          />
        </span>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Hasta"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: values.toDate,
              onChange: handleValueChange,
              name: "toDate",
            }}
          />
        </span>
        {!isDisabled && (
          <ButtonWithoutIcon
            isPrimary={false}
            textBttn={"Cargar"}
            handleClick={onSubmit}
            disabled={
              values.salebox === "" ||
              values.fromDate === "" ||
              values.toDate === "" ||
              isSubmitPending
            }
            isLoading={isSubmitPending}
          />
        )}
      </span>
    </div>
  );
};

export default SaleboxReportFilter;
