import React from "react";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import EBillingAmountModal from "@Components/ui/organisms/EBillingAmountModal/EBillingAmountModal";
import { ERROR_TYPE, SUCCESS_TYPE, WARNING_TYPE } from "@Models/const/common";

export const changeModalEBillingAmount = (
  modalContext: AcceptCancelModalContextProps,
  type: SUCCESS_TYPE | WARNING_TYPE | ERROR_TYPE,
  isAutomatic: boolean = false
) => {
  const {
    buttonTwo,
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = (
    <EBillingAmountModal
      modalContext={modalContext}
      type={type}
      isAutomatic={isAutomatic}
    />
  );

  handleTitleChange("Documentos electrónicos");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: false,
  });
  handleButtonTwoChange({
    ...buttonTwo,
    textButtonTwo: "Agregar",
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
