export const formatTypeDocument = (document) => {
  switch (document.toLowerCase()) {
    case "boleta de venta":
      return "Boleta de venta";
    case "factura":
      return "Factura";
    case "nota de credito":
      return "Nota de crédito";
    case "nota de venta":
      return "Nota de venta";
    default:
      return " - ";
  }
};
