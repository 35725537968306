import React, { useEffect, useState } from "react";
import styles from "./SelectedSaleBox.module.css";
import SelectedCashregisterHeader from "@Components/wrappers/SelectedCashregisterHeader/SelectedCashregisterHeader";
import SelectedCashregisterBody from "@Components/wrappers/SelectedCashregisterBody/SelectedCashregisterBody";
import { LoadingPulseIcon } from "@Models/icons";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import useValidateSalebox from "@Hooks/useValidateSalebox";
import { getSalesSummary } from "@Adapters/getSalesSummary.adapter";
import { clearNotification } from "@ReduxService/states/notificationSlice";
import useFetchSalebox from "@Hooks/useFetchSalebox";
// @ts-ignore
import { CriticalErrorPage } from "@viuti/recursos";
import { useEBillingModal } from "./hooks/useEBillingModal";

export function SelectedSaleBox() {
  const dispatch = useAppDispatch();
  const [fetchResponses, setFetchResponses] = useState<{
    saleBoxes: {
      loading: boolean;
      error: boolean;
    };
  }>({
    saleBoxes: {
      loading: true,
      error: false,
    },
  });

  useValidateSalebox(setFetchResponses);
  const criticalError = useFetchSalebox(fetchResponses);
  const sales = useAppSelector((state) => state.currentSale.sales);
  useEBillingModal();

  useEffect(() => {
    getSalesSummary(dispatch, sales);
    return () => {
      dispatch(clearNotification());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sales]);

  if (!fetchResponses.saleBoxes.loading && criticalError)
    return <CriticalErrorPage />;

  return (
    <div id="viuti-front-mainContent">
      {fetchResponses.saleBoxes.loading || criticalError === null ? (
        <div className={styles.loader}>
          <img
            className={styles.AnimatedLoader}
            src={LoadingPulseIcon}
            alt="AnimatedLoader"
          />
        </div>
      ) : (
        <main className={styles.SelectedSaleBox}>
          <SelectedCashregisterHeader />
          <SelectedCashregisterBody />
        </main>
      )}
      <div id="modal-root"></div>
    </div>
  );
}
