// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OCw0mi4XOJNpZ59iQPgv {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
}

.BaoQWmSjkSFX2a3FHLOy {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: 800 1.25rem/1.5rem "Mulish", sans-serif;
  color: #45348e;
}

.STI01ttZSM4z6fnLvsZZ {
  aspect-ratio: 1;
  width: auto;
  height: 12px;
  border-radius: 50%;
}

.wDMnzI3fN0mkpmerkpps {
  background-color: #00de66;
}

.rgzQoBpbAgLT2Iw0vbsg {
  background-color: #ff2323;
}
`, "",{"version":3,"sources":["webpack://./src/hooks/UpdateModalType/changeModalSalebox/changeModalSalebox.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;;EAEnB,6CAA6C;EAC7C,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".ModalAcceptCancel__title__container {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 0.8rem;\n}\n\n.ModalAcceptCancel__title {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n\n  font: 800 1.25rem/1.5rem \"Mulish\", sans-serif;\n  color: #45348e;\n}\n\n.ModalAcceptCancel__title__state {\n  aspect-ratio: 1;\n  width: auto;\n  height: 12px;\n  border-radius: 50%;\n}\n\n.ModalAcceptCancel__title__state__open {\n  background-color: #00de66;\n}\n\n.ModalAcceptCancel__title__state__close {\n  background-color: #ff2323;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalAcceptCancel__title__container": `OCw0mi4XOJNpZ59iQPgv`,
	"ModalAcceptCancel__title": `BaoQWmSjkSFX2a3FHLOy`,
	"ModalAcceptCancel__title__state": `STI01ttZSM4z6fnLvsZZ`,
	"ModalAcceptCancel__title__state__open": `wDMnzI3fN0mkpmerkpps`,
	"ModalAcceptCancel__title__state__close": `rgzQoBpbAgLT2Iw0vbsg`
};
export default ___CSS_LOADER_EXPORT___;
