import React from "react";
import style from "./AddPaymentMethodRow.module.css";
// @ts-ignore
import { Icon, SelectInput, TextInput } from "@viuti/recursos";
import { useAppSelector } from "@Hooks/store";
import { AddIcon, TimesIcon } from "@Models/icons";
import { formatToDecimal } from "@Utilities/FormatsHandler";

interface IAddPaymentMethodRow {
  saleIndex: number;
  values: ISalePaymentMethod[];
  setValues: React.Dispatch<React.SetStateAction<ISalePaymentMethod[]>>;
  resume: {
    saleCovered: number;
    difference: number;
    isSaleValid: boolean;
  };
}

const AddPaymentMethodRow = ({
  saleIndex,
  values,
  setValues,
  resume,
}: IAddPaymentMethodRow) => {
  const paymentMethods = useAppSelector(
    (state) => state.saleConfig.paymentMethods
  );

  const currentValue: {
    paymentMethod: IPaymentMethod;
    paymentCategory: ISubPaymentCategory;
    value: string;
  } = values[saleIndex];

  const paymentCategories = currentValue.paymentMethod?.subCategories;

  const handlePaymentMethodChange = (e: any) => {
    const value = e.target.value;
    const newValues = [...values];
    newValues[saleIndex].paymentMethod = value;
    // @ts-ignore
    newValues[saleIndex].paymentCategory = "";
    // @ts-ignore
    if (value.subCategories.length === 1) {
      // @ts-ignore
      newValues[saleIndex].paymentCategory = value.subCategories[0];
    }
    setValues(newValues);
  };

  const handlePaymentCategoryChange = (e: any) => {
    const newValues = [...values];
    newValues[saleIndex].paymentCategory = e.target.value;
    setValues(newValues);
  };

  const handleQuantityChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    e.target.value =
      // @ts-ignore
      e.target.value + e.key?.replace(/[^0-9.]/g, "");

    // @ts-ignore
    let quantity = formatToDecimal(e.target.value);

    const newValues = [...values];
    newValues[saleIndex].value = quantity;
    setValues(newValues);
  };

  const handleQuantityBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let quantity = e.target.value || "0";
    const currentQuantity = values[saleIndex].value;

    if (currentValue.paymentMethod.id !== 1 && Number(resume.difference) <= 0) {
      quantity = (Number(currentQuantity) + Number(resume.difference)).toFixed(
        2
      );
    }

    const quantityToNumber = parseFloat(quantity);
    const formattedValue = quantityToNumber.toFixed(2);
    const newValues = [...values];
    newValues[saleIndex].value = formattedValue;
    setValues(newValues);
  };

  const handleAddMethod = () => {
    const newValues = [...values];
    // reset current value
    newValues.unshift(currentValue);
    newValues[saleIndex] = {
      // @ts-ignore
      paymentMethod: "" as IPaymentMethod,
      // @ts-ignore
      paymentCategory: "" as ISubPaymentCategory,
      // @ts-ignore
      value: "",
    };

    setValues(newValues);
  };

  const shouldDisableAddButton =
    !currentValue.paymentCategory ||
    !currentValue.paymentMethod ||
    !currentValue.value ||
    currentValue.value === "0.00" ||
    resume.isSaleValid ||
    resume.difference === 0;

  return (
    <div className={style.form__row}>
      <span className={style.form__row__input_container}>
        <SelectInput
          label={saleIndex === 0 && "Tipo de pago"}
          value={currentValue.paymentMethod}
          handleChange={handlePaymentMethodChange}
          items={paymentMethods}
          required
        />
      </span>
      <span className={style.form__row__input_container}>
        <SelectInput
          label={saleIndex === 0 && "Categoría"}
          value={currentValue.paymentCategory}
          handleChange={handlePaymentCategoryChange}
          items={paymentCategories}
          required
          disabled={!paymentCategories || paymentCategories.length === 1}
        />
      </span>
      <span className={style.form__row__input_container}>
        {saleIndex === 0 &&
          !!currentValue?.paymentCategory.id &&
          !!currentValue?.paymentMethod.id && (
            <button
              className={`${style.form__row__maxpay} ${
                resume.difference <= 0 ? style.form__row__maxpay_disabled : ""
              }`}
              disabled={resume.difference <= 0}
              onClick={() => {
                const newValues = [...values];
                newValues[saleIndex].value = (
                  Number(newValues[saleIndex].value) + Number(resume.difference)
                ).toFixed(2);
                setValues(newValues);
              }}
            >
              MAX
            </button>
          )}
        <TextInput
          label={saleIndex === 0 && "Monto"}
          value={currentValue.value}
          inputProps={{
            placeholder: "0",
            onChange: handleQuantityChange,
            onBlur: handleQuantityBlur,
            max: resume.difference,
          }}
          prefix="S/"
          disabled={
            !currentValue.paymentCategory || !currentValue.paymentMethod
          }
        />
      </span>
      {saleIndex === 0 ? (
        <span
          className={`${style.form__row__input__icon} ${
            shouldDisableAddButton ? style.form__row__input__icon_disabled : ""
          }`}
        >
          <button
            onClick={handleAddMethod}
            disabled={shouldDisableAddButton}
            data-testid="add-method-button"
          >
            <Icon path={AddIcon} size={15} color={"#382b75"} />
          </button>
        </span>
      ) : (
        <span
          className={`${style.form__row__input__icon} ${style.form__row__input__icon_delete}`}
        >
          <button
            onClick={() =>
              setValues(values.filter((_, index) => index !== saleIndex))
            }
            data-testid="delete-method-button"
          >
            <Icon path={TimesIcon} size={15} color={"#ba1a1a"} />
          </button>
        </span>
      )}
    </div>
  );
};

export default AddPaymentMethodRow;
