/* eslint no-use-before-define: 2 */
import React from "react";
import styles from "./PackageDetailsDropdown.module.css";
// @ts-ignore
import { IconWrapper } from "@viuti/recursos";
import {
  HiChevronDown,
  HiChevronUp,
  HiTag,
  HiTrash,
  HiCalendarDays,
  HiExclamationTriangle,
} from "react-icons/hi2";

interface PackageDetailsDropdownProps {
  packageItem: IPackage;
  onRemove: (id: number) => void;
  isExpanded: boolean;
  onToggle: (id: number) => void;
}

const PackageDetailsDropdown: React.FC<PackageDetailsDropdownProps> = ({
  packageItem,
  onRemove,
  isExpanded,
  onToggle,
}) => {
  const thereIsStockAvailable =
    packageItem.stock === null || packageItem.stock > 0;
  const stockMessage =
    packageItem.stock === null
      ? "Stock ilimitado"
      : `Hay ${packageItem.stock} disponibles`;

  const allPaymentMethods = packageItem.paymentMethods.length === 0;

  return (
    <div className={styles.package_dropdown}>
      <div
        className={styles.package_summary}
        onClick={() => onToggle(packageItem.id)}
      >
        <span className={styles.package_name}>
          {packageItem.name}
          {(!packageItem.isAvailable ||
            !packageItem.isValid ||
            (packageItem.stock <= 0 && packageItem.stock !== null)) && (
            <IconWrapper
              icon={HiExclamationTriangle}
              size={14}
              color="#ff9800"
            />
          )}
        </span>
        {isExpanded ? (
          <IconWrapper icon={HiChevronUp} size={14} />
        ) : (
          <IconWrapper icon={HiChevronDown} size={14} />
        )}
      </div>
      {isExpanded && (
        <div className={styles.package_details}>
          <div className={styles.package_icons}>
            <div className={styles.icon_container}>
              <span
                className={`${styles.icon_wrapper} ${
                  packageItem.isAvailable && packageItem.isValid
                    ? styles.available_icon
                    : ""
                }`}
              >
                <IconWrapper icon={HiCalendarDays} size={14} />
              </span>
              <span className={styles.icon_label}>
                {packageItem.isAvailable
                  ? "Promoción disponible"
                  : packageItem.conflictMessage}
              </span>
            </div>
            <div className={styles.icon_container}>
              <span
                className={`${styles.icon_wrapper} ${
                  thereIsStockAvailable ? styles.available_icon : ""
                }`}
              >
                <IconWrapper icon={HiTag} size={14} />
              </span>
              <span className={styles.icon_label}>
                {thereIsStockAvailable ? stockMessage : "Sin stock"}
              </span>
            </div>
          </div>
          <span className={styles.section_container}>
            <h4 className={styles.details_title}>
              Medios de pago disponibles:
            </h4>
            <ul className={styles.payment_methods}>
              {allPaymentMethods ? (
                <li className={styles.payment_method}>
                  Todos los medios de pago
                </li>
              ) : (
                packageItem.paymentMethods.map((method, index) => (
                  <li key={index} className={styles.payment_method}>
                    {method.name}
                  </li>
                ))
              )}
            </ul>
          </span>
          <span className={styles.section_container}>
            <h4 className={styles.details_title}>Términos y condiciones:</h4>
            <p className={styles.terms_conditions}>{packageItem.condition}</p>
          </span>
          <button
            className={styles.remove_button}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(packageItem.id);
            }}
          >
            <IconWrapper icon={HiTrash} size={14} />
            Quitar paquete
          </button>
        </div>
      )}
    </div>
  );
};

export default PackageDetailsDropdown;
