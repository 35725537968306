import style from "./changeModalSalebox.module.css";
import { AppDispatch } from "@ReduxService/index";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { postOpenSaleBox } from "@Adapters/postOpenSaleBox.adapter";

import StatusSaleBoxModal from "@Components/ui/organisms/StatusSaleBoxModal/StatusSaleBoxModal";
import { postCloseSaleBox } from "@Adapters/postCloseSaleBox.adapter";
import { BASE_ROUTE } from "@Models/routes";

export const changeModalSalebox = (
  selectedSaleBox: ISaleBox,
  modalContext: AcceptCancelModalContextProps,
  dispatch: AppDispatch,
  wantToOpen = false,
  obligatoryCashCount = false
) => {
  const {
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const handleOpenSaleBox = async (initialCash: string) => {
    if (wantToOpen && selectedSaleBox.isOpen && !selectedSaleBox.needsCashCount)
      window.location.href = `${BASE_ROUTE}/${selectedSaleBox.id}`;
    return await postOpenSaleBox(initialCash, selectedSaleBox, dispatch);
  };

  const handleCloseSaleBox = async (lastCash: string) => {
    return await postCloseSaleBox(lastCash, selectedSaleBox, dispatch);
  };

  const ModalChildren = (
    <StatusSaleBoxModal
      saleBox={selectedSaleBox}
      handleSubmit={wantToOpen ? handleOpenSaleBox : handleCloseSaleBox}
      wantToOpen={wantToOpen}
    />
  );

  handleTitleChange(
    <div className={style.ModalAcceptCancel__title__container}>
      <span
        className={`${style.ModalAcceptCancel__title__state} ${
          wantToOpen
            ? style.ModalAcceptCancel__title__state__open
            : style.ModalAcceptCancel__title__state__close
        }`}
        title={wantToOpen ? "Abriendo caja" : "Cerrando caja"}
      />
      <h1 className={style.ModalAcceptCancel__title}>
        {wantToOpen ? "Abrir" : "Cerrar"} Caja - {selectedSaleBox.name}
      </h1>
    </div>
  );
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: !obligatoryCashCount,
    showButtonTwo: false,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
