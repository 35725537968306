import React, { useEffect, useState } from "react";
import { useAppDispatch } from "@Hooks/store";
import style from "./AddGiftcardsModal.module.css";
import { addSaleGiftcards } from "@ReduxService/states/currentSaleSlice";
import { getGiftcards_adapter } from "@Adapters/getGiftcards.adapter";
import {
  ButtonWithoutIcon,
  Icon,
  IconWrapper,
  GiftcardCreationForm,
  // @ts-ignore
} from "@viuti/recursos";
import { GiftIcon } from "@Models/icons";
import { HiArrowPath } from "react-icons/hi2";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { useGiftcardForm } from "./hook/useGiftcardForm";
import { ApiResponse } from "@Services/clients/utilities";

interface IAddGiftcardsModalProps {
  modalContext: AcceptCancelModalContextProps;
}

interface IGiftcard {
  id: number;
  value: number;
  code: string;
  modality: string;
}

const CustomButton: React.FC<{
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
}> = ({ onClick, className, icon, children, disabled }) => (
  <button
    onClick={onClick}
    className={`${style.button} ${className || ""}`}
    disabled={disabled}
  >
    {icon && <span className={style.button__icon}>{icon}</span>}
    {children}
  </button>
);

const CustomCheckbox: React.FC<{
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ checked, onChange }) => (
  <label className={style.checkbox}>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className={style.checkbox__input}
    />
    <span className={style.checkbox__checkmark}></span>
  </label>
);

const AddGiftcardsModal = ({ modalContext }: IAddGiftcardsModalProps) => {
  const dispatch = useAppDispatch();
  const [giftcards, setGiftcards] = useState<IGiftcard[]>([]);
  const [selectedGiftcards, setSelectedGiftcards] = useState<Set<number>>(
    new Set()
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const {
    handleSubmitForm,
    handleInputChange,
    giftcardForm,
    giftcardFormError,
    isSubmitting,
    codeError,
    isCodeLoading,
    isFormValid,
  } = useGiftcardForm();

  const fetchGiftcards = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await getGiftcards_adapter(dispatch);
      setGiftcards(data);
      if (data.length === 0) {
        setError("No hay giftcards disponibles");
      }
    } catch (err) {
      setError("Error al cargar las giftcards");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGiftcards();
  }, [dispatch]);

  useEffect(() => {
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      textButtonTwo: "Agregar",
      actionButtonTwo: () => {
        const selectedItems = giftcards.filter((giftcard) =>
          selectedGiftcards.has(giftcard.id)
        );

        dispatch(addSaleGiftcards(selectedItems));
        modalContext.handleVisibilityChange({
          isVisible: false,
          setIsVisible: modalContext.visibility.setIsVisible,
        });
      },
      isDisabled: selectedGiftcards.size === 0,
    });
  }, [selectedGiftcards, giftcards, modalContext, dispatch]);

  useEffect(() => {
    if (isCreating) {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        textButtonTwo: "Crear tarjeta",
        actionButtonTwo: handleCreateGiftcard,
        isDisabled: !isFormValid,
        isLoading: isSubmitting,
      });
    }
  }, [isFormValid, isSubmitting, isCreating]);

  const handleGiftcardSelection = (giftcardId: number) => {
    const newSelected = new Set(selectedGiftcards);
    if (newSelected.has(giftcardId)) {
      newSelected.delete(giftcardId);
    } else {
      newSelected.add(giftcardId);
    }
    setSelectedGiftcards(newSelected);
  };

  const handleRefresh = () => {
    fetchGiftcards();
  };

  const handleGiftcardItemClick = (giftcardId: number) => {
    handleGiftcardSelection(giftcardId);
  };

  const toggleCreateForm = () => {
    setIsCreating(!isCreating);
    if (!isCreating) {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        textButtonTwo: "Crear tarjeta",
        actionButtonTwo: handleCreateGiftcard,
        isDisabled: !isFormValid,
        isLoading: isSubmitting,
      });
    } else {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        textButtonTwo: "Agregar",
        actionButtonTwo: () => {
          const selectedItems = giftcards.filter((giftcard) =>
            selectedGiftcards.has(giftcard.id)
          );
          dispatch(addSaleGiftcards(selectedItems));
          modalContext.handleVisibilityChange({
            isVisible: false,
            setIsVisible: modalContext.visibility.setIsVisible,
          });
        },
        isDisabled: selectedGiftcards.size === 0,
      });
    }
  };

  const handleCreateGiftcard = async () => {
    const result = await handleSubmitForm();
    if (result?.isSuccess) {
      await fetchGiftcards();
      setIsCreating(false);
      // Seleccionar automáticamente la nueva giftcard
      const newGiftcard = result?.data;
      // if (newGiftcard?.id) {
      // 	setSelectedGiftcards(new Set([newGiftcard.id]));
      // }
    }
  };

  if (isLoading) {
    return <div className={style.loading}>Cargando giftcards...</div>;
  }

  if (error) {
    return (
      <div className={style.errorContainer}>
        <p className={style.errorMessage}>{error}</p>
        <CustomButton
          onClick={handleRefresh}
          className={style.refreshButton}
          icon={<IconWrapper icon={HiArrowPath} size={15} color="#ff9800" />}
        >
          Reintentar
        </CustomButton>
      </div>
    );
  }

  if (isCreating) {
    return (
      <div className={style.container}>
        <div className={style.header}>
          <h3>Nueva Giftcard</h3>
          <CustomButton
            onClick={toggleCreateForm}
            className={style.refreshButton}
          >
            Volver
          </CustomButton>
        </div>
        <GiftcardCreationForm
          handleInputChange={handleInputChange}
          giftcardForm={giftcardForm}
          giftcardFormError={giftcardFormError}
          codeError={codeError}
          isCodeLoading={isCodeLoading}
        />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <ButtonWithoutIcon
          isPrimary={false}
          textBttn={"Crear Giftcard"}
          handleClick={toggleCreateForm}
        />
        <CustomButton
          onClick={handleRefresh}
          className={style.refreshButton}
          icon={<IconWrapper icon={HiArrowPath} size={15} color="#45348e" />}
        >
          Actualizar
        </CustomButton>
      </div>
      <div className={style.giftcardsList}>
        {giftcards.map((giftcard) => (
          <div
            key={giftcard.id}
            className={style.giftcardItem}
            onClick={() => handleGiftcardItemClick(giftcard.id)}
          >
            <div className={style.giftcardContent}>
              <Icon path={GiftIcon} size={20} color="#45348e" />
              <div className={style.giftcardInfo}>
                <span className={style.giftcardInfo__code}>
                  <span className={style.giftcardCode}>{giftcard.code}</span>
                  <span className={style.giftcardName}>
                    Uso {giftcard.modality.toLowerCase()}
                  </span>
                </span>
                <span className={style.giftcardPrice}>
                  S/ {giftcard.value.toFixed(2)}
                </span>
              </div>
            </div>
            <CustomCheckbox
              checked={selectedGiftcards.has(giftcard.id)}
              onChange={() => handleGiftcardSelection(giftcard.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddGiftcardsModal;
