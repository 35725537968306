import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updatePaymentMethods } from "@ReduxService/states/saleConfigSlice";
import { getPaymentMethods_service } from "@Services/paymentMethods";

export const getPaymentMethods = async (dispatch: AppDispatch) => {
  const response: IResponse = await getPaymentMethods_service();

  if (!response.isSuccessful) {
    return response;
  }

  const formattedResponse: IPaymentMethod[] = response.data.map(
    (paymentMethod: any) => {
      return {
        id: paymentMethod.categoryPaymnetId,
        name:
          paymentMethod.categoryPaymnetName.charAt(0).toUpperCase() +
          paymentMethod.categoryPaymnetName.slice(1),
        subCategories: paymentMethod.subCategoryPayments?.map(
          (subPaymentCategory: any) => {
            return {
              id: subPaymentCategory.subCategoryPaymentId,
              name:
                subPaymentCategory.subCategoryPaymentName
                  .charAt(0)
                  .toUpperCase() +
                subPaymentCategory.subCategoryPaymentName.slice(1),
            };
          }
        ),
      };
    }
  );

  dispatch(updatePaymentMethods(formattedResponse));

  return response;
};
