import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { useEffect, useState } from "react";
import style from "./EBillingAmountModal.module.css";
// @ts-ignore
import { useAppSelector } from "@Hooks/store";
import {
  ERROR_CONST,
  ERROR_TYPE,
  SUCCESS_CONST,
  SUCCESS_TYPE,
  WARNING_CONST,
  WARNING_TYPE,
} from "@Models/const/common";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import {
  eBillingAmountModalErrorMessages,
  eBillingAmountModalSuccessMessages,
  eBillingAmountModalWarningMessages,
} from "./data/messages";

interface IEBillingAmountModalProps {
  modalContext: AcceptCancelModalContextProps;
  type?: SUCCESS_TYPE | WARNING_TYPE | ERROR_TYPE;
  isAutomatic?: boolean;
}

const EBillingAmountModal = ({
  modalContext,
  type = SUCCESS_CONST,
  isAutomatic = false,
}: IEBillingAmountModalProps) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const electronicBillingAmmount = useAppSelector(
    (state) => state.saleConfig.electronicBillingAmmount
  );
  const currentSale = useAppSelector((state) => state.currentSale);
  const electronicBilling = useAppSelector(
    (state) => state.saleConfig.electronicBilling
  );
  const { lastPurchaseDate } = electronicBilling;

  const getMessages = () => {
    if (type === ERROR_CONST) return eBillingAmountModalErrorMessages;
    if (type === WARNING_CONST) return eBillingAmountModalWarningMessages;
    return eBillingAmountModalSuccessMessages;
  };

  const { title, message, button } = getMessages();

  useEffect(() => {
    if (dontShowAgain) {
      localStorage.setItem("lastEBillingWarningDate", lastPurchaseDate);
    } else {
      localStorage.removeItem("lastEBillingWarningDate");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dontShowAgain]);

  let color = "#45348e";
  let StatusIcon = require("react-icons/hi").HiCheckCircle;

  if (type === ERROR_CONST) {
    color = "#d82121";
    StatusIcon = require("react-icons/hi2").HiExclamationCircle;
  } else if (type === WARNING_CONST) {
    color = "#ffc107";
    StatusIcon = require("react-icons/hi2").HiExclamationTriangle;
  } else if (type === SUCCESS_CONST) {
    color = "#00de66";
    StatusIcon = require("react-icons/hi2").HiCheckCircle;
  }

  const handleClick = () => {
    localStorage.setItem("lastSavedSale", JSON.stringify(currentSale));
    const currentSalebox = localStorage.getItem("lastSaleboxVisited");
    localStorage.setItem("lastSaleboxSale", currentSalebox);
    window.location.href = "/mi-cuenta";
  };

  return (
    <div className={style.body}>
      <StatusIcon size={48} color={color} />
      <h2 className={style.title}>
        {title.replace("[1]", String(electronicBillingAmmount))}
      </h2>
      <p className={style.description}>{message}</p>
      {isAutomatic && (
        <div className={style.checkboxContainer}>
          <label className={style.checkboxLabel}>
            <input
              type="checkbox"
              checked={dontShowAgain}
              onChange={(e) => setDontShowAgain(e.target.checked)}
            />
            No volver a mostrar hasta mi próxima compra
          </label>
        </div>
      )}
      <ButtonWithoutIcon
        isPrimary
        textBttn={button}
        handleClick={handleClick}
        width="100%"
      />
    </div>
  );
};

export default EBillingAmountModal;
