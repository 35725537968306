import React from "react";
import style from "./ReportContent.module.css";
import PittyCashCard from "@Pages/SaleboxReport/components/PittyCashCard/PittyCashCard";
import SaleAmountCard from "@Pages/SaleboxReport/components/SaleAmountCard/SaleAmountCard";
import { useAppSelector } from "@Hooks/store";
import {
  SimpleSaleboxIcon,
  SimpleSaleboxInputIcon,
  simpleSaleboxOutputIcon,
} from "@Models/icons";
import PaymentMehodChart from "@Pages/SaleboxReport/components/PaymentMethodsChart/PaymentMethodsChart";
import PaymentMethodsList from "@Pages/SaleboxReport/components/PaymentMethodsList/PaymentMethodsList";
import CashregisterMovementsTable from "../CashregisterMovementsTable/CashregisterMovementsTable";
const ReportContent = () => {
  const report = useAppSelector((state) => state.saleboxReports.report);
  const saleboxReport = useAppSelector((state) => state.saleboxReports);

  if (!report?.pettyCash) return null;
  return (
    <section className={style.container}>
      <div className={style.content__first_row}>
        <PittyCashCard />
        <div className={style.content__column}>
          <h4 className={style.label}>Resumen</h4>
          <span className={style.content__row__sales_cards}>
            <SaleAmountCard
              values={report.movements.sales}
              content={{
                icon: SimpleSaleboxIcon,
                type: "ventas",
                totalCashTitle: "Total de ingresos por ventas",
              }}
            />
            <SaleAmountCard
              values={report.movements.inputs}
              content={{
                icon: SimpleSaleboxInputIcon,
                type: "entradas",
                totalCashTitle: "Monto total de entradas",
              }}
            />
            <SaleAmountCard
              values={report.movements.outputs}
              content={{
                icon: simpleSaleboxOutputIcon,
                type: "salidas",
                totalCashTitle: "Monto total de salidas",
              }}
            />
          </span>
        </div>
      </div>
      <div className={style.content__second_row}>
        <PaymentMehodChart />
        <PaymentMethodsList />
      </div>
      <div className={style.content__movements}>
        <h4 className={style.label}>Movimientos de caja</h4>
        <CashregisterMovementsTable />
      </div>
    </section>
  );
};

export default ReportContent;
