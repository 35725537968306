import React, { useEffect, useState } from "react";
import style from "./AddSaleServiceRow.module.css";
// @ts-ignore
import { Icon, SearchWithColoredBorder, SelectInput } from "@viuti/recursos";
import { useAppSelector } from "@Hooks/store";
import { AddIcon, TimesIcon } from "@Models/icons";
import { formatToSoles } from "@Utilities/FormatsHandler";

const AddSaleServiceRow = ({ saleIndex, values, setValues }) => {
  const companyServices = useAppSelector(
    (state) => state.saleConfig.companyServices
  );

  const [searchServicesValue, setSearchServicesValue] = useState("");
  const [servicesResults, setServicesResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const currentValue: {
    item: IService;
    employeeId: number | string;
  } = values[saleIndex];
  const availableEmployees = currentValue.item?.availableEmployees?.map(
    (employee) => ({
      value: employee.id,
      name: `${employee.name} ${employee.lastName}`,
    })
  );
  const renderResults: IService[] =
    currentValue.item?.name === "" ? companyServices : servicesResults;

  const handleSetServiceSearch = (value: string) => {
    const updatedValues = [...values];
    updatedValues[saleIndex] = {
      item: { name: value } as IService,
      employee: "",
    };
    setValues(updatedValues);
  };

  useEffect(() => {
    if (searchServicesValue === "") {
      setShowSearchResults(false);
    } else {
      setShowSearchResults(true);
    }
  }, [searchServicesValue]);

  const handleServiceSearch = (value: string) => {
    setSearchServicesValue(value);
    handleSetServiceSearch(value);

    if (currentValue.item?.name !== value) {
      const updatedValues = [...values];
      updatedValues[saleIndex] = {
        item: { name: "" } as IService,
        employee: "",
      };
      setValues(updatedValues);
    }

    const results = companyServices.filter((service) => {
      const displayName = `${service.name} (${formatToSoles(service.total)})`;
      return displayName.toLowerCase().includes(value.toLowerCase());
    });

    setServicesResults(results);
    setShowSearchResults(true);
  };

  const handleSelectService = (item: IService, optionIndex: number) => {
    const name = item.name.replace(/ \(S\/.*\)$/, "");
    setSearchServicesValue(name);

    const updatedValues = [...values];
    updatedValues[optionIndex] = {
      item: {
        ...item,
        name: name,
      },
      employee: "",
    };

    setValues(updatedValues);
    setShowSearchResults(false);
  };

  const handleEmployeeChange = (e: any, optionIndex: number) => {
    const newValues = [...values];
    newValues[optionIndex].employeeId = e.target.value;
    setValues(newValues);
  };

  const handleAddService = () => {
    const newValues = [...values];
    newValues.unshift(currentValue);
    newValues[saleIndex] = { service: {} as IService, employee: "" };

    setSearchServicesValue("");
    setServicesResults([]);
    setShowSearchResults(true);

    setValues(newValues);
  };

  return (
    <div className={style.form__row}>
      <span className={style.form__row__input_container}>
        <SearchWithColoredBorder
          label="Servicio"
          value={
            currentValue.item?.name
              ? `${currentValue.item.name} (${formatToSoles(
                  currentValue.item.total
                )})`
              : searchServicesValue
          }
          placeholder={"Buscar servicio"}
          setValue={handleServiceSearch}
          allData={renderResults.map((service) => ({
            ...service,
            name: `${service.name} (${formatToSoles(service.total)})`,
          }))}
          onClickResult={(service) => handleSelectService(service, saleIndex)}
          showResults={showSearchResults}
          searchBy={["name"]}
          clearInput={false}
          required
        />
      </span>
      <span className={style.form__row__input_container}>
        <SelectInput
          label="Empleado asignado"
          value={currentValue.employeeId}
          handleChange={(e) => handleEmployeeChange(e, saleIndex)}
          items={availableEmployees}
          required
          disabled={!availableEmployees}
        />
      </span>
      {saleIndex === 0 ? (
        <span
          className={`${style.form__row__input__icon} ${
            !currentValue.employeeId
              ? style.form__row__input__icon_disabled
              : ""
          }`}
        >
          <button
            onClick={handleAddService}
            disabled={!currentValue.employeeId}
          >
            <Icon path={AddIcon} size={15} color={"#382b75"} />
          </button>
        </span>
      ) : (
        <span
          className={`${style.form__row__input__icon} ${style.form__row__input__icon_delete}`}
        >
          <button
            onClick={() =>
              setValues(values.filter((_, index) => index !== saleIndex))
            }
          >
            <Icon path={TimesIcon} size={15} color={"#ba1a1a"} />
          </button>
        </span>
      )}
    </div>
  );
};

export default AddSaleServiceRow;
