const base = `https://gw-qa.prikly.io`;

export const baseUrl = `${base}/caja/v1`;
export const appointmentsBaseUrl = `${base}/reservas/v1`;
export const loungesBaseUrl = `${base}/lounges/v1`;
export const employeesBaseUrl = `${base}/empleados/v1`;
export const servicesBaseUrl = `${base}/servicios/v1`;
export const clientsBaseUrl = `${base}/customers/v1`;
export const warehousesBaseUrl = `${base}/almacen/v1`;
export const promotionsBaseUrl = `${base}/promotions/v1`;
export const oldBaseUrl = `${base}/viuti2/v1`;

const tokenLocal = localStorage.getItem("access_token");

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenLocal}`,
  },
};
