import React, { useEffect } from "react";
import { getSaleBoxes } from "@Adapters/getSaleBoxes.adapter";
import { STATUS_OK } from "@Models/httpsStatus";
import { updateSelectedSaleBox } from "@ReduxService/states/selectedSaleBoxSlice";
import { BASE_ROUTE } from "@Models/routes";
import { useAppDispatch } from "@Hooks/store";
import { useParams } from "react-router-dom";

const useValidateSalebox = (
  setFetchResponses: React.Dispatch<
    React.SetStateAction<{
      saleBoxes: {
        loading: boolean;
        error: boolean;
      };
    }>
  >
) => {
  const dispatch = useAppDispatch();
  const { saleBoxId } = useParams<{ saleBoxId: string }>();

  useEffect(() => {
    getSaleBoxes(dispatch).then((res) => {
      if (res.status === STATUS_OK) {
        const saleBoxFound: ISaleBox = res.data?.find(
          (saleBox: ISaleBox) => saleBox.id === Number(saleBoxId)
        );
        if (saleBoxFound?.isOpen && !saleBoxFound?.isDowngraded) {
          dispatch(updateSelectedSaleBox(saleBoxFound));
          setFetchResponses((prev) => {
            return {
              ...prev,
              saleBoxes: {
                loading: false,
                error: false,
              },
            };
          });
        } else {
          window.location.href = BASE_ROUTE;
          localStorage.removeItem("lastSaleboxVisited");
        }
      } else {
        window.location.href = BASE_ROUTE;
        localStorage.removeItem("lastSaleboxVisited");
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useValidateSalebox;
