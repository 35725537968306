import React, { useEffect } from "react";
import style from "../AddPaymentMethodRow/AddPaymentMethodRow.module.css";
// @ts-ignore
import { Icon, TextInput } from "@viuti/recursos";
import { TimesIcon } from "@Models/icons";
import { formatToDecimal } from "@Utilities/FormatsHandler";
import { useGiftcardValidation } from "./hooks/useGiftcardValidation";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  removeGiftcardPayment,
  updateGiftcardPayment,
} from "@ReduxService/states/currentSaleSlice";

interface IAddGiftcardPaymentRow {
  saleIndex: number;
  resume: {
    saleCovered: number;
    difference: number;
    isSaleValid: boolean;
  };
}

const AddGiftcardPaymentRow = ({
  saleIndex,
  resume,
}: IAddGiftcardPaymentRow) => {
  const dispatch = useAppDispatch();
  const giftcardPayments = useAppSelector(
    (state) => state.currentSale.giftcardPayments
  );
  const {
    isCodeLoading,
    isCodeValid,
    codeError,
    availableAmount,
    validateGiftcard,
    giftcardId,
  } = useGiftcardValidation();

  const currentValue = giftcardPayments[saleIndex];

  const handleGiftcardCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value.toUpperCase();
    if (code.length > 10) return;
    if (!/^[A-Z0-9]*$/.test(code)) return;

    dispatch(
      updateGiftcardPayment({
        index: saleIndex,
        payment: {
          giftcardCode: code,
          giftcardId: undefined,
          amount: "",
        },
      })
    );

    validateGiftcard(code);
  };

  useEffect(() => {
    if (isCodeValid && giftcardId) {
      dispatch(
        updateGiftcardPayment({
          index: saleIndex,
          payment: {
            giftcardId: giftcardId,
          },
        })
      );
    }
  }, [isCodeValid, giftcardId]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value;
    if (!/^\d*\.?\d*$/.test(amount)) return;

    dispatch(
      updateGiftcardPayment({
        index: saleIndex,
        payment: { amount },
      })
    );
  };

  const handleAmountBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) return;

    const amount = parseFloat(value);
    if (isNaN(amount)) return;

    // Aseguramos que el monto no exceda el disponible
    const maxAmount = Math.min(
      amount,
      availableAmount || 0,
      resume.difference || 0
    );

    dispatch(
      updateGiftcardPayment({
        index: saleIndex,
        payment: { amount: maxAmount.toFixed(2) },
      })
    );
  };

  const handleMaxAmount = () => {
    if (!isCodeValid || !availableAmount) return;

    const currentAmount = parseFloat(currentValue?.amount || "0");
    const totalNeeded = currentAmount + Number(resume.difference);
    const maxAmount = Math.min(totalNeeded, availableAmount);

    if (maxAmount > 0) {
      dispatch(
        updateGiftcardPayment({
          index: saleIndex,
          payment: { amount: maxAmount.toFixed(2) },
        })
      );
    }
  };

  return (
    <div className={style.form__row}>
      <span className={style.form__row__input_container}>
        <TextInput
          label={"Código de Giftcard"}
          value={currentValue.giftcardCode || ""}
          inputProps={{
            placeholder: "Ingrese el código",
            onChange: handleGiftcardCodeChange,
          }}
          touched
          error={codeError}
          isLoading={isCodeLoading}
        />
      </span>
      <span className={style.form__row__input_container}>
        {isCodeValid && availableAmount && availableAmount > 0 && (
          <button
            className={`${style.form__row__maxpay} ${
              resume.difference <= 0 || availableAmount <= 0
                ? style.form__row__maxpay_disabled
                : ""
            }`}
            disabled={resume.difference <= 0 || availableAmount <= 0}
            onClick={handleMaxAmount}
          >
            MAX
          </button>
        )}
        <TextInput
          label={"Monto"}
          value={currentValue.amount}
          inputProps={{
            placeholder: "0",
            onChange: handleAmountChange,
            onBlur: handleAmountBlur,
            max: Math.min(resume.difference, availableAmount || Infinity),
          }}
          prefix="S/"
          disabled={!isCodeValid}
        />
      </span>
      <span className={style.form__row__input__icon}>
        <button
          onClick={() => dispatch(removeGiftcardPayment(saleIndex))}
          data-testid="delete-method-button"
        >
          <Icon path={TimesIcon} size={15} color={"#ba1a1a"} />
        </button>
      </span>
    </div>
  );
};

export default AddGiftcardPaymentRow;
