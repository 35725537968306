import React, { useContext } from "react";
import style from "./SaleServiceRow.module.css";
// @ts-ignore
import { Icon, SelectInput } from "@viuti/recursos";
import { useDispatch } from "react-redux";
import {
  removeSaleService,
  updateSaleServiceEmployee,
} from "@ReduxService/states/currentSaleSlice";
import { TimesIcon } from "@Models/icons";
import { changeModalEditPrices } from "@Hooks/UpdateModalType/changeModalEditPrices/changeModalEditPrices";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { SERVICES_TYPE } from "@Models/const/salesOptions";

interface ISaleServiceRow {
  sale: ISaleService;
}

const SaleServiceRow = ({ sale }: ISaleServiceRow) => {
  const dispatch = useDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const availableEmployees = sale.item.availableEmployees.map((employee) => ({
    value: employee.id,
    name: `${employee.name} ${employee.lastName}`,
  }));

  // Verificar si hay modificación de precio
  const hasModification =
    sale.summary.discount > 0 || sale.summary.addition > 0;

  return (
    <div
      key={sale.item.id}
      className={style.sales__container__body__sales__row}
    >
      <span className={style.sales__container__body__sales__row__column}>
        {sale.item.name}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        No aplica
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <SelectInput
          items={availableEmployees}
          value={sale.employeeId}
          handleChange={(e) => {
            dispatch(
              updateSaleServiceEmployee({
                index: sale.index,
                employeeId: e.target.value,
              })
            );
          }}
        />
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        1
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <button
          className={style.sales__container__body__sales__row__column__price}
          onClick={() =>
            changeModalEditPrices(modalContext, sale, SERVICES_TYPE, null)
          }
        >
          {hasModification && (
            <span className={style.price__modifier__indicator} />
          )}
          {formatToSoles(sale.item.price + sale.item.igv, false)}
        </button>
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        {sale.summary.total}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <button
          className={style.sales__container__body__sales__row__column__remove}
          onClick={() => dispatch(removeSaleService(sale.index))}
        >
          <Icon path={TimesIcon} size={12} color={"#45348e"} />
        </button>
      </span>
    </div>
  );
};

export default SaleServiceRow;
