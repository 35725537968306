import axios from "axios";
import { config, baseUrl } from "./constGlobals";

export const lastCashCount_service = async (saleBoxId: number) => {
  try {
    const url = `${baseUrl}/SaleBox/LastCashCount?saleBoxId=${saleBoxId}`;
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener el último arqueo de caja.",
      data: {},
    };
  }
};
