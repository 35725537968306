import React, { useEffect, useState, useRef } from "react";
import style from "./AddPackagesModal.module.css";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import {
  addSalePackages,
  updateSalePackageQuantity,
} from "@ReduxService/states/currentSaleSlice";
import { STATUS_OK } from "@Models/httpsStatus";
import { LoadingPulseIcon } from "@Models/icons";
import { getPackages } from "@Adapters/getPackages.adapter";
// @ts-ignore
import { SearchWithColoredBorder } from "@viuti/recursos";
import PackageDetailsDropdown from "@Components/ui/molecules/PackageDetailsDropdown/PackageDetailsDropdown";

interface IAddPackagesModalProps {
  modalContext: AcceptCancelModalContextProps;
}

const AddPackagesModal = ({ modalContext }: IAddPackagesModalProps) => {
  const dispatch = useAppDispatch();
  const packages = useAppSelector((state) => state.saleConfig.packages);
  const currentSalePackages = useAppSelector(
    (state) => state.currentSale.sales.packages
  );
  const loungeEmployees = useAppSelector(
    (state) => state.saleConfig.loungeEmployees
  );

  const [addedPackages, setAddedPackages] = useState<IPackage[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [showResults, setShowResults] = useState(true);

  const [values, setValues] = useState<
    ISalePackage[]
    // @ts-ignore
  >([{ item: {} as IPackage, employee: "" }]);

  const [fetchResponses, setFetchResponses] = useState({
    packages: { loading: false, error: false },
  });
  const setFetchPackages = (loading: boolean, error: boolean) => {
    setFetchResponses((prev) => {
      return {
        ...prev,
        packages: {
          loading: loading,
          error: error,
        },
      };
    });
  };

  const [expandedPackageId, setExpandedPackageId] = useState<number | null>(
    null
  );
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleTogglePackage = (packageId: number) => {
    setExpandedPackageId((prevId) => (prevId === packageId ? null : packageId));
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.maxHeight = "60vh";
      scrollContainerRef.current.style.overflowY = "auto";
    }
  }, []);

  useEffect(() => {
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      textButtonTwo: "Agregar",
      actionButtonTwo: () => {
        const formattedValues = addedPackages.map((pkg) => ({
          item: pkg,
          employeeId:
            loungeEmployees.find(
              (employee) =>
                employee.id ===
                JSON.parse(localStorage.getItem("account_info") || "{}")
                  .employee?.id
            )?.id || "",
        }));

        formattedValues.forEach((packageToAdd) => {
          const existingPackage = currentSalePackages.find(
            (pkg) => pkg.item.id === packageToAdd.item.id
          );

          if (existingPackage) {
            // Si el paquete ya existe, actualizamos la cantidad
            const currentQuantity = parseInt(existingPackage.summary.quantity);
            const newQuantity = (currentQuantity + 1).toString();
            const newSubtotal = parseFloat(
              (
                parseFloat(newQuantity) *
                (packageToAdd.item.price + packageToAdd.item.igv)
              ).toFixed(2)
            );
            dispatch(
              updateSalePackageQuantity({
                index: existingPackage.index,
                quantity: newQuantity,
                subtotal: newSubtotal,
                total: newSubtotal,
              })
            );
          } else {
            // Si es un nuevo paquete, lo añadimos
            dispatch(
              addSalePackages([packageToAdd as unknown as ISalePackage])
            );
          }
        });

        setAddedPackages([]);
        modalContext.handleClose();
      },
      isDisabled: addedPackages.length === 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addedPackages,
    currentSalePackages,
    dispatch,
    loungeEmployees,
    modalContext,
  ]);

  const handleUpdatePackages = (packageItem: IPackage) => {
    setShowResults(false);
    const packageExists = addedPackages.some((p) => p.id === packageItem.id);

    if (!packageExists) {
      setAddedPackages([...addedPackages, packageItem]);
    }
  };

  const handleRemovePackage = (packageId: number) => {
    setAddedPackages(addedPackages.filter((pkg) => pkg.id !== packageId));
  };

  useEffect(() => {
    setFetchPackages(true, false);

    (async () => {
      const response = await getPackages(dispatch);
      setFetchPackages(false, response.status !== STATUS_OK);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchFocus = () => {
    setShowResults(true);
  };

  if (packages.length === 0 && !fetchResponses.packages.loading) {
    return (
      <div className={style.body}>
        <p className={style.empty__message}>
          No hemos encontrado ningún paquete disponible.
        </p>
        <p className={style.empty__submessage}>
          Para poder agregar paquetes a la venta, es necesario que haya al menos
          un paquete disponible.
        </p>
      </div>
    );
  }
  return (
    <>
      <div className={style.body} ref={scrollContainerRef}>
        <div className={style.search__container}>
          <SearchWithColoredBorder
            value={searchInput}
            placeholder={"Buscar producto por nombre o código"}
            setValue={setSearchInput}
            allData={packages}
            onClickResult={(packageItem) => handleUpdatePackages(packageItem)}
            showResults={showResults}
            searchBy={["name", "code"]}
            onFocus={onSearchFocus}
          />
        </div>
        {addedPackages.length > 0 ? (
          <div className={style.packages__container}>
            {addedPackages.map((pkg) => (
              <PackageDetailsDropdown
                key={pkg.id}
                packageItem={pkg}
                onRemove={() => handleRemovePackage(pkg.id)}
                isExpanded={expandedPackageId === pkg.id}
                onToggle={handleTogglePackage}
              />
            ))}
          </div>
        ) : (
          <p className={style.empty_message}>
            No se han añadido paquetes. Por favor, busque y seleccione paquetes
            para agregarlos.
          </p>
        )}
      </div>
      {fetchResponses?.packages.loading && (
        <div className={style.loading}>
          <img src={LoadingPulseIcon} alt="" />
        </div>
      )}
    </>
  );
};

export default AddPackagesModal;
