import React from "react";
import style from "./SaleGiftcardRow.module.css";
// @ts-ignore
import { Icon, SelectInput } from "@viuti/recursos";
import { useDispatch } from "react-redux";
import { TimesIcon } from "@Models/icons";
import {
  removeSaleGiftcard,
  updateSaleGiftcardEmployee,
} from "@ReduxService/states/currentSaleSlice";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { useAppSelector } from "@Hooks/store";

interface ISaleGiftcardRow {
  sale: ISaleGiftcard;
}

const SaleGiftcardRow = ({ sale }: ISaleGiftcardRow) => {
  const dispatch = useDispatch();
  const loungeEmployees = useAppSelector(
    (state) => state.saleConfig.loungeEmployees
  );
  const availableEmployees = loungeEmployees.map((employee) => ({
    value: employee.id,
    name: `${employee.name} ${employee.lastName}`,
  }));

  const handleEmployeeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      updateSaleGiftcardEmployee({
        index: sale.index,
        employeeId: e.target.value,
      })
    );
  };

  return (
    <div className={style.sales__container__body__sales__row}>
      <span className={style.sales__container__body__sales__row__column}>
        {sale.item.code}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        No aplica
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <SelectInput
          items={availableEmployees}
          value={sale.employeeId}
          handleChange={(e) => {
            dispatch(
              updateSaleGiftcardEmployee({
                index: sale.index,
                employeeId: e.target.value,
              })
            );
          }}
        />
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        1
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        {formatToSoles(sale.item.price, false)}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        {sale.summary.total}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <button
          className={style.sales__container__body__sales__row__column__remove}
          onClick={() => dispatch(removeSaleGiftcard(sale.index))}
        >
          <Icon path={TimesIcon} size={12} color={"#45348e"} />
        </button>
      </span>
    </div>
  );
};

export default SaleGiftcardRow;
