import {
  PRODUCTS_TYPE,
  SaleTypeId,
  SERVICES_TYPE,
  PACKAGES_TYPE,
} from "@Models/const/salesOptions";
import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getItemNetPrice_service } from "@Services/sales";
import { cancelOperation_service } from "@Services/salesDocuments";

interface IItemData {
  appointmentIndex?: string;
  index?: string;
  item: ISaleItem;
  summary?: IItemSummary;
}

export const getItemNetPrice = async (
  dispatch: AppDispatch,
  sale: IItemData,
  summary: IItemSummary,
  type: SaleTypeId
) => {
  let typeIdKey: string;
  let typeIdValue: number;

  if (type === SERVICES_TYPE) {
    typeIdKey = "PriceServiceId";
    // @ts-ignore
    typeIdValue = sale.item?.priceId;
  } else if (type === PRODUCTS_TYPE) {
    typeIdKey = "LocalProductId";
    // @ts-ignore
    typeIdValue = sale.item?.localId;
  } else if (type === PACKAGES_TYPE) {
    typeIdKey = "PackageId";
    typeIdValue = sale.item?.id;
  }

  const percentageDiscount = summary.subtotal * (summary.discount / 100);
  const percentageAddition = summary.subtotal * (summary.addition / 100);

  const params = {
    [typeIdKey]: typeIdValue,
    Amount: Number(sale.summary.quantity),
    Discount: summary.modifierIsPercentage
      ? percentageDiscount
      : summary.discount || 0,
    Increase: summary.modifierIsPercentage
      ? percentageAddition
      : summary.addition || 0,
  };
  const response: IResponse = await getItemNetPrice_service(params);

  if (response.status !== STATUS_OK) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status as any,
        toastTimeDuration: 4000,
      })
    );
    return response;
  }

  const newSummary = {
    grossPrice: response.data.grossPrice,
    taxes: response.data.tax,
    subtotal: response.data.value,
    discount: response.data.discount,
    total: response.data.price,
  };

  return {
    ...response,
    data: newSummary,
  };
};
