export const documentTypes = [
  { id: 1, name: "RUC" },
  { id: 2, name: "DNI" },
  { id: 3, name: "CARNÉ DE EXTRANJERÍA" },
  { id: 4, name: "PASAPORTE" },
];

export const TYPE_DNI = "DNI";
export const TYPE_RUC = "RUC";
export const TYPE_CARNET_DE_EXTRANJERIA = "CARNET DE EXTRANJERÍA";
export const TYPE_PASAPORTE = "PASAPORTE";
