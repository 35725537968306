import React from "react";
import style from "./ClientButton.module.css";
import { CheckIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";

interface IClientButtonProps {
  client: IClient;
  setSelectedClient: (client: IClient) => void;
  isClientSelected: boolean;
  initials: string;
}

const ClientButton = ({
  client,
  setSelectedClient,
  isClientSelected,
  initials,
}: IClientButtonProps) => {
  return (
    <button
      key={client.id}
      className={`${style.client__container} ${
        isClientSelected ? style.client__container_selected : ""
      }`}
      onClick={() => setSelectedClient(client)}
    >
      <span className={style.client__info__container}>
        <div className={style.client__initials}>
          <p
            className={`${style.client__initials__text} ${
              isClientSelected ? style.client__initials_hide : ""
            }`}
          >
            {initials}
          </p>
          <span
            className={`${style.client__initials__icon}	${
              !isClientSelected ? style.client__initials_hide : ""
            }`}
          >
            <Icon path={CheckIcon} color={"#937cf4"} size={20} />
          </span>
        </div>
        <span className={style.client__info}>
          <p className={style.client__name}>
            {client.name} {client.lastName}
          </p>
          {(client.phoneNumber || client.email) && (
            <p className={style.client__contact}>
              {client.phoneNumber || client.email}
            </p>
          )}
        </span>
      </span>
      <div className={style.client__details}>
        <p className={style.client__document}>
          {client.documentType.name}: {client.documentNumber}
        </p>
        {/* <span
          className={`${style.client__category} ${
            isClientSelected ? style.client__category_selected : ""
          }`}
        >
          {client.category?.name && (
            <HiMiniTag
              size={12}
              color={isClientSelected ? "#8365f3" : "#626262"}
            />
          )}
          {client.category?.name || "Sin categoría"}
        </span> */}
      </div>
    </button>
  );
};

export default ClientButton;
