// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BtFgo0KLXV1k_M1icpv7 {
  width: 100%;
  position: relative;
  max-width: 730px;
  min-width: 40px;
}

.JLSo7fKXWnS9F1E9HKMr {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  margin-top: 4px;
}

.vPX3l1DwRk1u1YpEvKm9 {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Espacio entre el loader y el TextInput */
}

._taBxxXzDtKLT_enExqU {
  /* Estilos para el spinner, asegúrate de que gire */
  animation: Hc8aIU0AWcn9CZipZoZ4 1s linear infinite;
}

@keyframes Hc8aIU0AWcn9CZipZoZ4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/ClientSearch/ClientSearch.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW,EAAE,2CAA2C;AAC1D;;AAEA;EACE,mDAAmD;EACnD,kDAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".search__container {\n  width: 100%;\n  position: relative;\n  max-width: 730px;\n  min-width: 40px;\n}\n\n.clients__container {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  max-height: 300px;\n  overflow-y: auto;\n  background-color: white;\n  border: 1px solid #ccc;\n  z-index: 1000;\n  margin-top: 4px;\n}\n\n.input__wrapper {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem; /* Espacio entre el loader y el TextInput */\n}\n\n.spinner {\n  /* Estilos para el spinner, asegúrate de que gire */\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search__container": `BtFgo0KLXV1k_M1icpv7`,
	"clients__container": `JLSo7fKXWnS9F1E9HKMr`,
	"input__wrapper": `vPX3l1DwRk1u1YpEvKm9`,
	"spinner": `_taBxxXzDtKLT_enExqU`,
	"spin": `Hc8aIU0AWcn9CZipZoZ4`
};
export default ___CSS_LOADER_EXPORT___;
