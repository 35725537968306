import React from "react";
import style from "./EmptyMessage.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";

interface IEmptyMessageProps {
  icon: {
    name: string;
    size: number;
  };
  text: string;
  subtext: string;
  action?: () => void;
}

const EmptyMessage = ({ action, icon, text, subtext }: IEmptyMessageProps) => {
  if (!icon.size) icon.size = 100;

  return (
    <button
      className={`${style.empty__container} ${
        action ? style.empty__container__action : ""
      }`}
      onClick={action}
    >
      <Icon path={icon.name} color="#45348e" size={icon.size} />
      <p className={style.empty__message}>{text}</p>
      <p className={style.empty__submessage}>{subtext}</p>
    </button>
  );
};

export default EmptyMessage;
