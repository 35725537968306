import { updateNotification } from "@ReduxService/states/notificationSlice";
import { downloadCashMovements_service } from "@Services/movements";

export const getBase64donwloadMovements = async (payload, dispatch) => {
  const obj = {
    searParameter:
      payload.searParameter === undefined ? "" : payload.searParameter,
    idBox: payload.idBox === "Todas" ? "" : payload.idBox,
    type: payload.type === undefined ? "" : payload.type,
    concept: payload.concept === "" ? "" : payload.concept,
    startDate: payload.startDate === "" ? "" : payload.startDate,
    endDate: payload.endDate === "" ? "" : payload.endDate,
  };

  const response: IResponse = await downloadCashMovements_service(obj);
  if (!response.isSuccessful) {
    dispatch(
      updateNotification({
        message: response.message,
        status: 400,
        toastTimeDuration: 4000,
      })
    );
  }
  return response;
};
