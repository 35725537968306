import React from "react";
import style from "./SaleAppointmentRow.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { ClockIcon, TimesIcon } from "@Models/icons";
import SaleAppointmentServiceRow from "../SaleAppointmentServiceRow/SaleAppointmentServiceRow";
import { removeSaleAppointment } from "@ReduxService/states/currentSaleSlice";
import { useAppDispatch } from "@Hooks/store";

interface ISaleAppointmentRow {
  sale: ISaleAppointment;
}

const SaleAppointmentRow = ({ sale }: ISaleAppointmentRow) => {
  const dispatch = useAppDispatch();

  return (
    <div className={style.sales__container}>
      <div className={style.appointment__title}>
        {/* <span className={style.appointment__title__info}>
          <Icon path={ClockIcon} size={14} color={"#937cf4"} />{" "}
          <h5>
            Hoy: {sale.appointment.startTime} - {sale.appointment.endTime}
          </h5>
        </span> */}
        <button
          className={style.appointment__title__button__remove}
          onClick={() => dispatch(removeSaleAppointment(sale.index))}
        >
          <Icon path={TimesIcon} size={10} color={"#d82121"} />
          Eliminar
        </button>
      </div>
      {sale.appointment.services.map((service: ISaleService) => (
        <SaleAppointmentServiceRow
          key={service.index + service.item.name}
          appointmentIndex={sale.index}
          sale={service}
        />
      ))}
    </div>
  );
};

export default SaleAppointmentRow;
