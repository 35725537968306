import React, { useState } from "react";
// @ts-ignore
import { SaleMovementsReportView } from "@viuti/recursos";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { getLoungeEmployees } from "@Adapters/getLoungeEmployees.adapter";
import { getMovements } from "@Adapters/getMovements.adapter";
import { getFirstDayOfMonth } from "@Utilities/getFirstDayOfMonth";
import { getCurrentDate } from "@Utilities/getCurrentDate";
import { classCell, typeMovements } from "@Models/const/constMovements";
import { redirectToMainPage } from "@Utilities/Navigation";
import useConceptsMovements from "@Hooks/useConceptsMovements/useConceptsMovements";
import useBoxes from "@Hooks/useBoxes/useBoxes";
import {
  updateCurrentPagination,
  updateShouldReloadTable,
} from "@ReduxService/states/movementsSlice";
import useSelectBox from "@Hooks/useSelectBox/useSelectBox";
import {
  EXPORT_MOVEMENTS_UUID,
  REGISTER_MOVEMENTS_UUID,
} from "@Models/const/securityAccess";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getBase64donwloadMovements } from "@Adapters/getBase64donwloadMovements.adapter";
import { addConcept } from "@ReduxService/states/conceptsSlice";

const MovementsView = () => {
  const [form, setForm] = useState({
    searchParameter: "",
    idBox: "",
    type: "",
    concept: "",
    startDate: getFirstDayOfMonth(),
    endDate: getCurrentDate(),
  });
  const [initialForm, setInitialForm] = useState({
    searchParameter: "",
    idBox: "",
    type: "",
    concept: "",
    startDate: getFirstDayOfMonth(),
    endDate: getCurrentDate(),
  });

  const [isCreationMovementModalVisible, setIsCreationMovementModalVisible] =
    useState(false);
  const [isEditMovementModalVisible, setIsEditMovementModalVisible] =
    useState(false);
  const [isDeleteMovementModalVisible, setIsDeleteMovementModalVisible] =
    useState(false);
  const [isEditingHistoryModalVisible, setIsEditingHistoryModalVisible] =
    useState(false);

  const dispatch = useAppDispatch();
  const concepts = useConceptsMovements();
  const boxes = useBoxes(dispatch);
  const reduxState = useAppSelector((state) => state);
  const { currentPagination } = useAppSelector((state) => state.movements);
  // Se selecciona la caja por defecto cuando se carga la página
  useSelectBox(boxes, boxes.saleBoxId, form, setForm, setInitialForm);

  return (
    <SaleMovementsReportView
      filters={form}
      setFilters={setForm}
      initialFilters={initialForm}
      setInitialFilters={setInitialForm}
      movementConcepts={concepts}
      movementTypes={typeMovements}
      reduxSelector={reduxState}
      onHeaderBackClick={() => redirectToMainPage("movimientos")}
      getEmployees={() => getLoungeEmployees(dispatch)}
      getMovements={() =>
        getMovements(
          form.idBox,
          form.type,
          form.searchParameter,
          form.concept,
          form.startDate,
          form.endDate,
          currentPagination,
          dispatch
        )
      }
      createMovementModalVisibility={{
        isVisible: isCreationMovementModalVisible,
        setIsVisible: setIsCreationMovementModalVisible,
      }}
      editMovementModalVisibility={{
        isVisible: isEditMovementModalVisible,
        setIsVisible: setIsEditMovementModalVisible,
      }}
      deleteMovementModalVisibility={{
        isVisible: isDeleteMovementModalVisible,
        setIsVisible: setIsDeleteMovementModalVisible,
      }}
      editingHistoryModalVisibility={{
        isVisible: isEditingHistoryModalVisible,
        setIsVisible: setIsEditingHistoryModalVisible,
      }}
      classCell={classCell}
      currentDate={getCurrentDate}
      firstDayOfMonth={getFirstDayOfMonth}
      boxesInfo={boxes}
      securityUuids={{
        EXPORT_MOVEMENTS_UUID,
        REGISTER_MOVEMENTS_UUID,
      }}
      onNotification={(notification) =>
        dispatch(updateNotification(notification))
      }
      getCsvMovementsReport={() => getBase64donwloadMovements(form, dispatch)}
      onCurrentPaginationChange={(pagination) =>
        dispatch(updateCurrentPagination(pagination))
      }
      onShouldReloadTableChange={(shouldReload) =>
        dispatch(updateShouldReloadTable(shouldReload))
      }
      useSelectBox={useSelectBox}
      onConceptCreation={(concept) => dispatch(addConcept(concept))}
    />
  );
};

export default MovementsView;
