import { format } from "date-fns";

export const convertDateFormat = (date: string): string => {
  // Manually parse the date and time string
  const parts = date.split(" ");
  const dateParts = parts[0].split("/");
  const timeParts = parts[1].split(":");
  const month = parseInt(dateParts[0]) - 1; // JavaScript months are 0-indexed
  const day = parseInt(dateParts[1]);
  const year = parseInt(dateParts[2]);
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);

  // Create a Date object
  const formattedDate = new Date(year, month, day, hours, minutes, seconds);

  // Format the date into the new format
  const formattedDateString = format(formattedDate, "dd/MM/yyyy hh:mm aa");

  return formattedDateString;
};
