import axios from "axios";
import { baseUrl, config } from "./constGlobals";

export const getReserveCommands_service = async (idClient: number) => {
  const url = `${baseUrl}/SaleBox/ReserveComandaClient?idClient=${idClient}`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener las comandas.",
      data: [],
    };
  }
};
