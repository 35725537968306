// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ppr8yKC0XYWD76H59Z00 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/PayoutModal/components/SaleIndicators/SaleIndicators.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,oBAAoB;EACpB,gCAAgC;AAClC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  padding-bottom: 15px;\n  border-bottom: 1px solid #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ppr8yKC0XYWD76H59Z00`
};
export default ___CSS_LOADER_EXPORT___;
