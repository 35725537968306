import axios from "axios";
import { config, baseUrl } from "./constGlobals";

export const openCloseSaleBox_service = async (saleBoxId: number) => {
  const url = `${baseUrl}/SaleBox/OpenCloseSaleBox?saleBoxId=${saleBoxId}`;
  const response = await axios.put(url, {}, config);
  try {
    return {
      isSuccessful: true,
      ...response.data,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al abrir o cerrar la caja.",
      data: {},
    };
  }
};
