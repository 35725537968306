import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { updateRegisteredClients } from "@ReduxService/states/saleConfigSlice";
import { getClientsByText_service } from "@Services/clients";

export const getClientsByText = async (
  search: string,
  dispatch: AppDispatch
) => {
  const response: IResponse = await getClientsByText_service(search);

  if (!response.isSuccessful) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
    return response;
  }

  const formattedResponse: IClient[] = response.data.map((client: any) => ({
    id: client.customerId,
    name:
      client.firstName.charAt(0).toUpperCase() +
      client.firstName.slice(1).toLowerCase(),
    lastName:
      client.lastName.charAt(0).toUpperCase() +
      client.lastName.slice(1).toLowerCase(),
    email: client.email,
    gender: client.gender,
    phoneNumber: client.phone,
    birthdate: client.birthdate,
    documentType: {
      id: client.documentTypeId,
      name: client.documentType,
    },
    category: client.category.categoryId
      ? {
          id: client.category.categoryId,
          name: client.category.categoryName,
          discount: {
            services: client.category.serviceDiscount,
            products: client.category.productDiscount,
          },
        }
      : null,
    documentNumber: client.customerDocument,
  }));

  dispatch(updateRegisteredClients(formattedResponse));

  return {
    status: STATUS_OK,
    message: "Clientes obtenidos correctamente",
    data: response.data,
  };
};
