import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateCompanyServices } from "@ReduxService/states/saleConfigSlice";
import { getCompanyServices_service } from "@Services/companyServices";

export const getCompanyServices = async (dispatch: AppDispatch) => {
  const response: IResponse = await getCompanyServices_service();

  if (!response.isSuccessful) {
    return response;
  }

  let formattedResponse: IService[] = [];

  response.data.forEach((service: any) => {
    service.prices.map((priceService: any) => {
      formattedResponse.push({
        id: service.idService,
        priceId: priceService.idPrice,
        name: `${service.nameService}: ${priceService.namePrice}`,
        price: priceService.price,
        igv: priceService.igv,
        total: priceService.totalPrice,
        isFree: priceService.free === 1,
        availableEmployees: service.employees.map((employee: any) => {
          return {
            id: employee.idEmployee,
            name: employee.nameEmployee,
            lastName: employee.lastnameEmployee,
          };
        }),
      });
    });
  });

  dispatch(updateCompanyServices(formattedResponse));

  return response;
};
