import React from "react";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import DowngradeModal from "@Components/ui/organisms/DowngradeModal/DowngradeModal";

export const changeModalDowngrade = (
  modalContext: AcceptCancelModalContextProps
) => {
  const {
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = <DowngradeModal />;

  handleTitleChange("Caja deshabilitada");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: false,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
