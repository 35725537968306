// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChEbIj89b82HUgYWTFZw {
  display: flex;
  flex-direction: row;
  gap: 5px;
  background-color: #e8e3ff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: default;
}

.o6WbBzVjiiQLI1DdAHup {
  font-size: 0.8rem;
  font-weight: 700;
  color: #937cf4;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/SaleIndicator/SaleIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".sale_indicator__container {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n  background-color: #e8e3ff;\n  padding: 4px 8px;\n  border-radius: 4px;\n  cursor: default;\n}\n\n.sale_indicator__number {\n  font-size: 0.8rem;\n  font-weight: 700;\n  color: #937cf4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sale_indicator__container": `ChEbIj89b82HUgYWTFZw`,
	"sale_indicator__number": `o6WbBzVjiiQLI1DdAHup`
};
export default ___CSS_LOADER_EXPORT___;
