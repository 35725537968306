// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WPEtg6TGPit8XUnO83zA {
  background: #f5f5f5;
  background-size: cover;
  height: fit-content;
  width: 100%;
  margin-top: 1rem;
}

.VbZMLfoCZDgacgfpPMZ8 {
  display: block;
  margin: 10px 0 0 0;
  font-weight: 400;
  font-size: 20px;
  color: #392b75;
}

.UKjyyMi0BpjR0SiDsop4 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(200px);
  margin: 0;
  border-radius: 16px;
}

.gEla4Bi803TsmllKXhmg {
  width: 200px;
  height: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/InitialView/InitialView.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".container {\n  background: #f5f5f5;\n  background-size: cover;\n  height: fit-content;\n  width: 100%;\n  margin-top: 1rem;\n}\n\n.subtitle {\n  display: block;\n  margin: 10px 0 0 0;\n  font-weight: 400;\n  font-size: 20px;\n  color: #392b75;\n}\n\n.loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  backdrop-filter: blur(200px);\n  margin: 0;\n  border-radius: 16px;\n}\n\n.animatedLoader {\n  width: 200px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WPEtg6TGPit8XUnO83zA`,
	"subtitle": `VbZMLfoCZDgacgfpPMZ8`,
	"loader": `UKjyyMi0BpjR0SiDsop4`,
	"animatedLoader": `gEla4Bi803TsmllKXhmg`
};
export default ___CSS_LOADER_EXPORT___;
