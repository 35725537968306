// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yr9agxHMigjrCt1wmcgh {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1rem;
}

.qMGTSZkIxn9ktO9E9rNg {
  font: 600 0.9rem "Mulish", sans-serif;
  color: #252525;
  text-align: center;
}

.XaFr0vPMLeFUQxzjTtQ1 {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--color-text-secondary);
  text-align: center;
  max-width: 500px;
  margin-bottom: 1rem;
}

.DNrku5UyYTKxtIT_msrE {
  margin: 8px 0;
  text-align: left;
}

.VxodIrV9Brjqh5ZOInLQ {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 0.825rem;
  cursor: pointer;
}

.VxodIrV9Brjqh5ZOInLQ input[type="checkbox"] {
  cursor: pointer;
  width: 0.875rem;
  height: 0.875rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/EBillingAmountModal/EBillingAmountModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;EAEvB,SAAS;AACX;;AAEA;EACE,qCAAqC;EACrC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,WAAW;EACX,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".body {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  gap: 1rem;\n}\n\n.title {\n  font: 600 0.9rem \"Mulish\", sans-serif;\n  color: #252525;\n  text-align: center;\n}\n\n.description {\n  font-size: 0.875rem;\n  font-weight: 400;\n  color: var(--color-text-secondary);\n  text-align: center;\n  max-width: 500px;\n  margin-bottom: 1rem;\n}\n\n.checkboxContainer {\n  margin: 8px 0;\n  text-align: left;\n}\n\n.checkboxLabel {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  color: #666;\n  font-size: 0.825rem;\n  cursor: pointer;\n}\n\n.checkboxLabel input[type=\"checkbox\"] {\n  cursor: pointer;\n  width: 0.875rem;\n  height: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `yr9agxHMigjrCt1wmcgh`,
	"title": `qMGTSZkIxn9ktO9E9rNg`,
	"description": `XaFr0vPMLeFUQxzjTtQ1`,
	"checkboxContainer": `DNrku5UyYTKxtIT_msrE`,
	"checkboxLabel": `VxodIrV9Brjqh5ZOInLQ`
};
export default ___CSS_LOADER_EXPORT___;
