import React, { useEffect, useState } from "react";
import style from "./AddServiceModal.module.css";
import { useAppDispatch } from "@Hooks/store";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import AddSaleServiceRow from "@Components/ui/atoms/AddSaleServiceRow/AddSaleServiceRow";
import { addSalesServices } from "@ReduxService/states/currentSaleSlice";

interface IAddServiceModalProps {
  modalContext: AcceptCancelModalContextProps;
}

const AddServiceModal = ({ modalContext }: IAddServiceModalProps) => {
  const dispatch = useAppDispatch();

  const [values, setValues] = useState<
    ISaleService[]
    // @ts-ignore
  >([{ item: {} as IService, employee: "" }]);

  useEffect(() => {
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      textButtonTwo: "Agregar",
      actionButtonTwo: () => {
        dispatch(addSalesServices(values));

        // @ts-ignore
        setValues([{ item: {} as IService, employee: "" }]);
        modalContext.handleVisibilityChange({
          isVisible: false,
          setIsVisible: modalContext.visibility.setIsVisible,
        });
      },
      isDisabled: values.some((value) => {
        return !value.employeeId;
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div className={style.body}>
      {values.map((value, index) => {
        const uniqueKey = `${index}-${value.item?.id}`;
        return (
          <AddSaleServiceRow
            key={uniqueKey}
            saleIndex={index}
            values={values}
            setValues={setValues}
          />
        );
      })}
    </div>
  );
};

export default AddServiceModal;
