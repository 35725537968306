import React, { useEffect } from "react";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import PackageModificationModal from "@Components/ui/organisms/PackageModificationModal/PackageModificationModal";
import { SaleTypeId } from "@Models/const/salesOptions";

export const changeModalPackageModifications = (
  modalContext: AcceptCancelModalContextProps,
  packageItem: IPackage,
  sale: {
    item: ISaleItem;
    summary?: {
      quantity: string;
      subtotal: number;
      discount: number;
      addition: number;
      modifierIsPercentage: boolean;
      total: number;
    };
  },
  type: SaleTypeId
) => {
  const {
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = (
    <PackageModificationModal
      key={Date.now()}
      modalContext={modalContext}
      packageItem={packageItem}
      sale={sale}
      type={type}
    />
  );

  handleTitleChange(packageItem.name);
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: false,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
