import {
  createSlice,
  type PayloadAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { updateSaleBoxOnList } from "./saleBoxesSlice";

const DEFAULT_STATE = {
  id: 0,
  name: "",
  isOpen: false,
  hasElectronicBill: false,
  businessName: "",
  businessAddress: "",
  ruc: "0",
  localName: "",
} as ISaleBox;

const initialState: ISaleBox = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const selectedSaleBoxSlice = createSlice({
  name: "selectedSaleBox",
  initialState,
  reducers: {
    updateSelectedSaleBox: (state, action: PayloadAction<ISaleBox>) => {
      return action.payload;
    },
  },
});

export const updateSelectedSaleBoxAndList = createAsyncThunk(
  "selectedSaleBox/updateAndReflectInList",
  async (saleBox: ISaleBox, { dispatch }) => {
    // Update the selected sale box
    dispatch(updateSelectedSaleBox(saleBox));
    // Update the sale box on the list
    dispatch(updateSaleBoxOnList(saleBox));
  }
);

export const { updateSelectedSaleBox } = selectedSaleBoxSlice.actions;

export default selectedSaleBoxSlice.reducer;
