import React from "react";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import AddAppointmentsModal from "@Components/ui/organisms/AddAppointmentsModal/AddAppointmentsModal";
import AddReserveCommandsModal from "@Components/ui/organisms/AddReserveCommandsModal/AddReserveCommandsModal";

export const changeModalCommandsAppointments = async (
  modalContext: AcceptCancelModalContextProps,
  idClient: number
) => {
  const {
    buttonTwo,
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleNoSizeLimitChange,
    handleClose,
  } = modalContext;

  const ModalChildren = (
    <AddReserveCommandsModal modalContext={modalContext} idClient={idClient} />
  );

  handleTitleChange("Agregar reservas y comandas a caja");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: true,
  });
  handleButtonTwoChange({
    ...buttonTwo,
    textButtonTwo: "Agregar",
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
