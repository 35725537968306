import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch, store } from "@ReduxService/index";
import { updateFinishedSaleResponse } from "@ReduxService/states/currentSaleSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { INewSalePayload, postNewSale_service } from "@Services/sales";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const postNewSale = async (
  dispatch: AppDispatch,
  state: ReturnType<typeof store.getState>,
  methods: {
    paymentMethods: ISalePaymentMethod[];
    giftcardPayments: IGiftcardPayment[];
  },
  isFreeSale: boolean = false
) => {
  const { paymentMethods, giftcardPayments } = methods;

  const client = state.currentSale.genericClient || state.currentSale.client;

  const billingDocument = state.currentSale.billingDocument;

  const salebox = state.selectedSaleBox;
  const sales = state.currentSale.sales;
  const formattedSales = [];

  sales.services.forEach((service) => {
    formattedSales.push({
      priceServiceId: service.item.priceId,
      localProductId: 0,
      commandId: 0,
      employeeId: service.employeeId,
      promoId: 0,
      quantity: Number(service.summary.quantity),
      discount: Math.abs(Number(service.summary.discount)) || 0,
      surcharge: Number(service.summary.addition) || 0,
    });
  });

  sales.products.forEach((product) => {
    formattedSales.push({
      priceServiceId: 0,
      localProductId: product.item.localId,
      commandId: 0,
      employeeId: product.employeeId,
      promoId: 0,
      quantity: Number(product.summary.quantity),
      discount: Math.abs(Number(product.summary.discount)) || 0,
      surcharge: Number(product.summary.addition) || 0,
    });
  });

  sales.appointments.forEach((appointment) => {
    appointment.appointment.services.forEach((service) => {
      formattedSales.push({
        priceServiceId: service.item.priceId,
        localProductId: 0,
        reserveId: appointment.appointment.id,
        commandId: 0,
        employeeId: service.employeeId,
        promoId: 0,
        quantity: Number(service.summary.quantity),
        discount: Math.abs(Number(service.summary.discount)) || 0,
        surcharge: Number(service.summary.addition) || 0,
        reserveDetailId: Number(service.item.reserveDetailId),
      });
    });
  });

  sales.packages.forEach((packageSale) => {
    formattedSales.push({
      priceServiceId: 0,
      localProductId: 0,
      commandId: 0,
      employeeId: 0,
      promoId: 0,
      quantity: Number(packageSale.summary.quantity),
      discount: 0,
      surcharge: 0,
      packageItemPayment: {
        packageId: packageSale.item.id,
        packageItemPaymentDetail: packageSale.services
          .map((service) => ({
            priceServiceId: service.idService,
            localProductId: null,
            employeeId: service.employeeId,
          }))
          .concat(
            packageSale.products.map((product) => ({
              priceServiceId: null,
              localProductId: product.idProduct,
              employeeId: product.employeeId,
            }))
          ),
      },
      reserveId: 0,
      reserveDetailId: 0,
    });
  });

  const itemsCommands = sales.commands.flatMap((command) => {
    return command.command.itemsGroup.map((itemGroup) => {
      return {
        commandId: command.command.comandaId,
        CommandDetailId: itemGroup.item.CommandDetailId,
        discount: itemGroup.summary.discount,
        employeeId: itemGroup.item.employeeId,
        localProductId:
          itemGroup.item.localProductId === null
            ? 0
            : itemGroup.item.localProductId,
        priceServiceId:
          itemGroup.item.priceServiceId === null
            ? 0
            : itemGroup.item.priceServiceId,
        promoId: 0,
        quantity: itemGroup.summary.quantity,
        surcharge: itemGroup.summary.addition,
        reserveId: command.command.reserveId,
        reserveDetailId: itemGroup.item.reserveDetailId,
      };
    });
  });

  itemsCommands.forEach((item) => {
    formattedSales.push(item);
  });

  sales.giftcards.forEach((giftcard) => {
    formattedSales.push({
      priceServiceId: 0,
      localProductId: 0,
      commandId: 0,
      employeeId: giftcard.employeeId,
      quantity: giftcard.summary.quantity,
      discount: 0,
      surcharge: 0,
      giftcardId: giftcard.item.id,
    });
  });

  const payload: INewSalePayload = {
    idPaymentClient: client.id,
    // @ts-ignore
    typeDocumentoPaymentId: billingDocument,
    saleBoxId: salebox.id,
    payments: isFreeSale
      ? [
          {
            idPaymentMethod: 1,
            paymentAmount: 0,
          },
        ]
      : paymentMethods.map((paymentMethod) => {
          return {
            idPaymentMethod: Number(paymentMethod.paymentCategory.id),
            paymentAmount: parseFloat(paymentMethod.value),
          };
        }),
    giftcardPayments: giftcardPayments.map((giftcardPayment) => {
      return {
        giftcardId: giftcardPayment.giftcardId,
        amount: parseFloat(giftcardPayment.amount),
      };
    }),
    idPrommotion: [],
    items: formattedSales,
    isFreeSale,
  };
  const cobra_venta = 57;
  trackUserAction(cobra_venta);
  const response: IResponse = await postNewSale_service(payload);

  if (response.status !== STATUS_OK) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status.toString(),
        toastTimeDuration: 4000,
      })
    );
    return response;
  }

  const dataTicket =
    response.data?.dataTicket !== null
      ? {
          hash: response.data?.dataTicket?.hash,
          qrData: response.data?.dataTicket?.qrData,
        }
      : null;

  const formattedResponse = {
    isSuccessful: true,
    status: response.status,
    message: "Venta realizada correctamente",
    data: {
      saleId: response.data.idSaleDocument,
      saledocumentsDetailsId: response.data.idSaleDocumentDetails,
      billing: {
        serie: response.data.serie,
        document: response.data.correlative,
      },
      dataTicket,
    },
  };

  dispatch(updateFinishedSaleResponse(formattedResponse));

  dispatch(
    updateNotification({
      message: formattedResponse.message,
      status: response.status,
      toastTimeDuration: 4000,
    })
  );

  return formattedResponse;
};
