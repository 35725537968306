// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DVDaFSBdgToRWum0FEMu {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  gap: 20px;
  min-width: 580px;
  min-height: 300px;
  max-height: 485px;
}

.DVDaFSBdgToRWum0FEMu::-webkit-scrollbar {
  width: 8px;
}

.DVDaFSBdgToRWum0FEMu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.DVDaFSBdgToRWum0FEMu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.DVDaFSBdgToRWum0FEMu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (width < 746px) {
  .DVDaFSBdgToRWum0FEMu {
    min-width: 100%;
  }
}

.fONSwF22SW0TwiKoaeKA {
  text-align: center;
  font: 800 1rem "Mulish", sans-serif;
  color: #45348e;
}

.lkt0o9QrCVOZ5SO_Y5Kg {
  font: 600 0.875rem "Mulish", sans-serif;
}

.ObWgn3f9Uc5N8HzCXjLK {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.DVmN5xYL6b9LRtpvjD8v {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff82;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/AddPackagesModal/AddPackagesModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;EACrB,6BAA6B;;EAE7B,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,cAAc;AAChB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;;EAEX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,2BAA2B;AAC7B","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n  max-height: 60vh;\n  overflow-y: auto;\n  scrollbar-width: thin;\n  scrollbar-color: #888 #f1f1f1;\n\n  gap: 20px;\n  min-width: 580px;\n  min-height: 300px;\n  max-height: 485px;\n}\n\n.body::-webkit-scrollbar {\n  width: 8px;\n}\n\n.body::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n.body::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 4px;\n}\n\n.body::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n@media screen and (width < 746px) {\n  .body {\n    min-width: 100%;\n  }\n}\n\n.empty__message {\n  text-align: center;\n  font: 800 1rem \"Mulish\", sans-serif;\n  color: #45348e;\n}\n\n.empty__submessage {\n  font: 600 0.875rem \"Mulish\", sans-serif;\n}\n\n.packages__container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-color: #ffffff82;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `DVDaFSBdgToRWum0FEMu`,
	"empty__message": `fONSwF22SW0TwiKoaeKA`,
	"empty__submessage": `lkt0o9QrCVOZ5SO_Y5Kg`,
	"packages__container": `ObWgn3f9Uc5N8HzCXjLK`,
	"loading": `DVmN5xYL6b9LRtpvjD8v`
};
export default ___CSS_LOADER_EXPORT___;
