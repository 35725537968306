import React from "react";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import NeedsCashCountModal from "@Components/ui/organisms/NeedsCashCountModal/NeedsCashCountModal";

export const changeModalCashCount = (
  modalContext: AcceptCancelModalContextProps,
  saleBox: ISaleBox,
  isRequired: boolean = true
) => {
  const {
    buttonTwo,
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = (
    <NeedsCashCountModal
      saleBox={saleBox}
      modalContext={modalContext}
      isRequired={isRequired}
    />
  );

  handleTitleChange("Arqueo pendiente");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: false,
  });
  handleButtonTwoChange({
    ...buttonTwo,
    textButtonTwo: "Agregar",
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
