import axios from "axios";
import { baseUrl, config } from "./constGlobals";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getSalesDocuments_service = async (payload) => {
  const url = `${baseUrl}/SalesDocuments/SalesDocuments?searParameter=${payload.searchParameter}&idBox=${payload.idBox}&typeDocumetnId=${payload.typeDocumentId}&status=${payload.status}&statusFE=${payload.statusFE}&startDate=${payload.startDate}&endDate=${payload.endDate}&page=${payload.page}`;
  const carga_documentos_venta = 65;
  trackUserAction(carga_documentos_venta);
  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los documentos de venta. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const cancelOperation_service = async (
  idSaleDocument: string,
  observation: string
) => {
  const url = `${baseUrl}/Payment/CancelOperation?idSaleDocument=${idSaleDocument}&reason=${observation}`;
  const anular_ticket = 67;
  trackUserAction(anular_ticket);

  try {
    const response: IResponse = await axios
      .delete(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al cancelar la operación. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const getSalesDocumentDetail_service = async (
  saleDocumentId: string,
  saleBoxId: string
) => {
  const url = `${baseUrl}/SalesDocuments/SalesDocumentDetail?saleDocumentId=${saleDocumentId}&saleBoxId=${saleBoxId}`;
  const ve_detalles_de_documento = 66;
  trackUserAction(ve_detalles_de_documento);

  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener el detalle del documento de venta. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const getSalePDF_service = async (saleDocumentId: string) => {
  const url = `${baseUrl}/Payment/GetSalePDF?idSaleDocument=${saleDocumentId}`;

  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al obtener el PDF",
      data: [],
    };
  }
};

export const getSaleCDR_service = async (saleDocumentId: string) => {
  const url = `${baseUrl}/Payment/GetSaleCDR?idSaleDocument=${saleDocumentId}`;

  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al obtener el CDR",
      data: [],
    };
  }
};

export const getSaleXML_service = async (saleDocumentId: string) => {
  const url = `${baseUrl}/Payment/GetSaleXML?idSaleDocument=${saleDocumentId}`;

  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al obtener el XML",
      data: [],
    };
  }
};

export const downloadSaleDocument_service = async (payload) => {
  const url = `${baseUrl}/SalesDocuments/DonwloadSaleDocumentReportBase64string?searParameter=${payload.searchParameter}&idBox=${payload.idBox}&typeDocumetnId=${payload.typeDocumentId}&status=${payload.status}&statusFE=${payload.statusFE}&startDate=${payload.startDate}&endDate=${payload.endDate}`;
  const descargar_reporte = 68;
  trackUserAction(descargar_reporte);

  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message || "Error al descargar el reporte",
      data: [],
    };
  }
};

export const resendPayment_service = async (saleDocumentId: string) => {
  const url = `${baseUrl}/Payment/ResendPayment`;

  try {
    const response = await axios.post(url, { saleDocumentId }, config);
    return {
      isSuccessful: true,
      status: response.status,
      message: response.data.message,
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Error al retransmitir el documento a SUNAT",
      data: [],
    };
  }
};
