import React, { useContext } from "react";
import style from "./SaleProductRow.module.css";
// @ts-ignore
import { Icon, SelectInput, TextInput } from "@viuti/recursos";
import { useDispatch } from "react-redux";
import {
  removeSaleProduct,
  updateSaleProductEmployee,
  updateSaleProductQuantity,
  updateSaleServiceEmployee,
} from "@ReduxService/states/currentSaleSlice";
import { TimesIcon } from "@Models/icons";
import { changeModalEditPrices } from "@Hooks/UpdateModalType/changeModalEditPrices/changeModalEditPrices";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import { useAppSelector } from "@Hooks/store";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { PRODUCTS_TYPE } from "@Models/const/salesOptions";

interface ISaleProductRow {
  sale: ISaleProduct;
}

const SaleProductRow = ({ sale }: ISaleProductRow) => {
  const dispatch = useDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const loungeEmployees = useAppSelector(
    (state) => state.saleConfig.loungeEmployees
  );
  const availableEmployees = loungeEmployees.map((employee) => ({
    value: employee.id,
    name: `${employee.name} ${employee.lastName}`,
  }));
  const saleWarehouse = useAppSelector(
    (state) => state.saleConfig.warehouses
  ).find((warehouse) => warehouse.id === sale.item.warehouseId);

  // Verificar si hay modificación de precio
  const hasModification =
    sale.summary.discount > 0 || sale.summary.addition > 0;

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.value.replace(/\D/g, "");

    dispatch(
      updateSaleProductQuantity({
        index: sale.index,
        quantity: quantity,
        subtotal: parseFloat(
          ((sale.item.price + sale.item.igv) * parseInt(quantity || "1"))
            .toFixed(2)
            .toString()
        ),
        total: (
          (parseFloat(String(sale.item.price + sale.item.igv)) -
            parseFloat(String(sale.summary.discount)) +
            parseFloat(String(sale.summary.addition))) *
          parseInt(quantity || "1")
        ).toFixed(2) as any,
      })
    );
  };

  const handleQuantityBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const quantity = e.target.value || "0";
    const quantityToNumber = parseInt(quantity);

    if (quantityToNumber === 0) {
      dispatch(
        updateSaleProductQuantity({
          index: sale.index,
          quantity: "1",
          subtotal: sale.summary.subtotal,
          total: sale.summary.total,
        })
      );
    }
  };

  return (
    <div className={style.sales__container__body__sales__row}>
      <span className={style.sales__container__body__sales__row__column}>
        {sale.item.name}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        {saleWarehouse?.name}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <SelectInput
          items={availableEmployees}
          value={sale.employeeId}
          handleChange={(e) => {
            dispatch(
              updateSaleProductEmployee({
                index: sale.index,
                employeeId: e.target.value,
              })
            );
          }}
        />
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <TextInput
          value={sale.summary.quantity}
          inputProps={{
            placeholder: "1",
            type: "number",
            min: 0,
            max: sale.item.stock,
            onChange: handleQuantityChange,
            onBlur: handleQuantityBlur,
          }}
          width="50px"
        />
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <button
          className={style.sales__container__body__sales__row__column__price}
          onClick={() =>
            changeModalEditPrices(modalContext, sale, PRODUCTS_TYPE, null)
          }
        >
          {hasModification && (
            <span className={style.price__modifier__indicator} />
          )}
          {formatToSoles(sale.item.price + sale.item.igv, false)}
        </button>
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        {sale.summary.total}
      </span>
      <span className={style.sales__container__body__sales__row__column}>
        <button
          className={style.sales__container__body__sales__row__column__remove}
          onClick={() => dispatch(removeSaleProduct(sale.index))}
        >
          <Icon path={TimesIcon} size={12} color={"#45348e"} />
        </button>
      </span>
    </div>
  );
};

export default SaleProductRow;
