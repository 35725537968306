import axios from "axios";
import { warehousesBaseUrl, config } from "./constGlobals";

export const getWarehouses_service = async () => {
  const url = `${warehousesBaseUrl}/Warehouses`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los almacenes.",
      data: [],
    };
  }
};

export const getWarehouseProducts_service = async (warehouseId: number) => {
  const url = `${warehousesBaseUrl}/LocalProducts/Search?idWareHouse=${warehouseId}`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los productos del almacén.",
      data: [],
    };
  }
};
