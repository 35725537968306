import { createSlice } from "@reduxjs/toolkit";

const initialState: ISaleBoxReportProps = {
  fetchResponse: {
    isSuccessful: false,
    status: null,
    message: "",
    data: null,
  },
  movements: {
    data: null,
    currentPage: 1,
    totalPages: 1,
  },
  report: null,
  fromDate: "",
  toDate: "",
  salebox: "",
};

export const saleBoxReportSlice = createSlice({
  name: "saleBoxReport",
  initialState,
  reducers: {
    updateReport: (state, action) => {
      state.fetchResponse = action.payload;
      state.report = action.payload.data;
    },
    updateReportMovements: (state, action) => {
      state.movements = action.payload;
    },
    updateReportFilters: (state, action) => {
      const { name, value } = action.payload.target;
      state[name] = value;
    },
  },
});

export const { updateReport, updateReportMovements, updateReportFilters } =
  saleBoxReportSlice.actions;
export default saleBoxReportSlice.reducer;
