import {
  OPEN_APPOINTMENTS_MODAL,
  OPEN_CLIENT_MODAL,
  OPEN_PRODUCTS_MODAL,
  OPEN_SERVICES_MODAL,
  OPEN_PACKAGES_MODAL,
  OPEN_GIFTCARDS_MODAL,
} from "./actions";

interface ICombination {
  key: string;
  macKey?: string;
  ctrlKey?: boolean;
  altKey?: boolean;
  shiftKey?: boolean;
  metaKey?: boolean;
  action: number;
}

const combinations: ICombination[] = [
  {
    key: "c",
    macKey: "ç",
    altKey: true,
    action: OPEN_CLIENT_MODAL,
  },
  {
    key: "1",
    macKey: "¡",
    altKey: true,
    action: OPEN_PRODUCTS_MODAL,
  },
  {
    key: "2",
    macKey: "™",
    altKey: true,
    action: OPEN_APPOINTMENTS_MODAL,
  },
  {
    key: "3",
    macKey: "£",
    altKey: true,
    action: OPEN_SERVICES_MODAL,
  },
  {
    key: "4",
    macKey: "¢",
    altKey: true,
    action: OPEN_PACKAGES_MODAL,
  },
  // {
  //   key: "5",
  //   macKey: "∞",
  //   altKey: true,
  //   action: OPEN_GIFTCARDS_MODAL,
  // },
];

export default combinations;
