import { getGiftcardDataByCode } from "@Services/giftcards";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { AppDispatch } from "@ReduxService/index";

export const getGiftcardDataByCode_adapter = async (
  code: string,
  dispatch: AppDispatch,
  signal?: AbortSignal
) => {
  const response = await getGiftcardDataByCode(code, signal);

  if (!response.isSuccess) {
    if (response.status !== 499) {
      dispatch(
        updateNotification({
          message: response.message,
          status: 400,
          toastTimeDuration: 4000,
        })
      );
    }
    return {
      isValid: false,
      status: response?.status,
      availableAmount: null,
    };
  }

  return {
    isValid: true,
    status: response?.status,
    availableAmount: response?.data?.remainder,
    id: response?.data?.giftcardId,
  };
};
