// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XWoiZAHTGkDamKma46DK {
  .h9dzpvTykqT0ZSjdRT6w {
    border-top: 2px solid #000;
  }
}

.h9dzpvTykqT0ZSjdRT6w {
  grid-column: span 3;
  text-align: right;
}

.cm6BmWshg9Pj5OFTCuNd {
  position: relative;
  opacity: 0.7;
  z-index: 1;
}

.cm6BmWshg9Pj5OFTCuNd::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 12px;
  width: 97%;
  height: 1px;
  background-color: #000000;
  pointer-events: none;
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/CashregisterMovementsTable/CashregisterMovementsTable.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,oBAAoB;EACpB,YAAY;AACd","sourcesContent":[".closeMovementRow {\n  .mergedCell {\n    border-top: 2px solid #000;\n  }\n}\n\n.mergedCell {\n  grid-column: span 3;\n  text-align: right;\n}\n\n.inactiveRow {\n  position: relative;\n  opacity: 0.7;\n  z-index: 1;\n}\n\n.inactiveRow::after {\n  content: \"\";\n  position: absolute;\n  top: 50%;\n  left: 12px;\n  width: 97%;\n  height: 1px;\n  background-color: #000000;\n  pointer-events: none;\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeMovementRow": `XWoiZAHTGkDamKma46DK`,
	"mergedCell": `h9dzpvTykqT0ZSjdRT6w`,
	"inactiveRow": `cm6BmWshg9Pj5OFTCuNd`
};
export default ___CSS_LOADER_EXPORT___;
