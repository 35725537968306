import axios from "axios";
import { employeesBaseUrl, config } from "./constGlobals";

export const getLoungeEmployees_service = async () => {
  const url = `${employeesBaseUrl}/Team/TeamMembers`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los empleados.",
      data: [],
    };
  }
};
