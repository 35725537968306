import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateAccount } from "@ReduxService/states/saleConfigSlice";
import { getAccountInfo_service } from "@Services/accountInfo";

export const getAccountInfo = async (dispatch: AppDispatch) => {
  let response = localStorage.getItem("account_info");

  if (!response) {
    const response: IResponse = await getAccountInfo_service();

    if (!response.isSuccessful) {
      return response;
    }

    const formattedResponse: IAccountInfo = {
      id: response.data.idEmployee,
      name: response.data.name,
      lastName: response.data.lastName,
      alias: response.data.alias,
      email: response.data.email,
      lounge: {
        id: response.data.localId,
        name: response.data.localName,
      },
      company: {
        id: response.data.companyId,
        name: response.data.companyName,
      },
      profilePicture: response.data.profileImage,
    };

    dispatch(updateAccount(formattedResponse));

    return response;
  }

  const parsedResponse = JSON.parse(response);
  const formattedResponse: IAccountInfo = {
    id: parsedResponse.employee.id,
    name: parsedResponse.employee.name,
    lastName: parsedResponse.employee.lastName,
    alias: parsedResponse.employee.alias,
    email: parsedResponse.employee.email,
    lounge: {
      id: parsedResponse.lounge.id,
      name: parsedResponse.lounge.name,
    },
    company: {
      id: parsedResponse.company.id,
      name: parsedResponse.company.name,
    },
    profilePicture: parsedResponse.employee.profileImage,
  };

  dispatch(updateAccount(formattedResponse));

  return {
    isSuccessful: true,
    status: STATUS_OK,
    message: "Información de cuenta obtenida correctamente",
    data: parsedResponse,
  };
};
