import React, { useEffect, useState } from "react";
import { LoadingPulseIcon } from "../../models/icons";
import style from "./InitialView.module.css";

import ListCashregisters from "@Pages/InitialView/views/ListCashregisters/ListCashregisters";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { getSaleBoxes } from "@Adapters/getSaleBoxes.adapter";

export const MainView = () => {
  const [isFetchingSaleBoxes, setIsFetchingSaleBoxes] = useState(true);
  const dispatch = useAppDispatch();
  const saleBoxesList = useAppSelector((state: any) => state.saleBoxesList);

  useEffect(() => {
    getSaleBoxes(dispatch).then((res) => {
      const fetchedSaleBoxes: ISaleBox[] = res.data;
      const lastSaleBoxVisited = localStorage.getItem("lastSaleboxVisited");
      const validateLastSaleBoxVisited = fetchedSaleBoxes?.find(
        (saleBox: any) => saleBox.id === Number(lastSaleBoxVisited || 0)
      );

      if (lastSaleBoxVisited && validateLastSaleBoxVisited) {
        const saleBox = fetchedSaleBoxes?.find(
          (saleBox: any) => saleBox.id === Number(lastSaleBoxVisited)
        );
        if (saleBox) {
          window.location.href = `/cajas/${lastSaleBoxVisited}`;
        } else {
          setIsFetchingSaleBoxes(false);
        }
      } else if (
        fetchedSaleBoxes?.length === 1 &&
        fetchedSaleBoxes[0]?.isOpen
      ) {
        window.location.href = `/cajas/${fetchedSaleBoxes[0]?.id}`;
      } else {
        localStorage.removeItem("lastSaleboxVisited");
        setIsFetchingSaleBoxes(false);
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="viuti-front-mainContent">
      {isFetchingSaleBoxes ? (
        <div className={style.loader}>
          <img
            className={style.AnimatedLoader}
            src={LoadingPulseIcon}
            alt="AnimatedLoader"
          />
        </div>
      ) : (
        <ListCashregisters saleBoxes={saleBoxesList} />
      )}
    </div>
  );
};
