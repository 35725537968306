export const closeSaleBoxProps = {
  messages: {
    salebox_already_open: "",
    salebox_want_to_open: "",
    cash_count__note: "",
    cash_count__note_already_open: "",
    cash_count__success: "Caja cerrada con éxito",
    cash_count__success_instruction:
      "Aguarda un momento, estamos redirigiéndote al listado de cajas.",
  },
  inputs: {
    cash__value: "Cantidad de efectivo:",
    submit__button: "Cerrar Caja",
    submit__button_continue: "Continuar",
  },
};
