import React, { useContext, useEffect, useState } from "react";
import combinations from "./combinations";
import {
  OPEN_APPOINTMENTS_MODAL,
  OPEN_CLIENT_MODAL,
  OPEN_PACKAGES_MODAL,
  OPEN_PRODUCTS_MODAL,
  OPEN_SERVICES_MODAL,
  OPEN_GIFTCARDS_MODAL,
} from "./actions";
import { useDispatch } from "react-redux";
import { changeModalClient } from "@Hooks/UpdateModalType/changeModalClient/changeModalClient";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import {
  ClientsModalStateContext,
  ClientsModalStateContextProps,
} from "@Hooks/NewClientsModalStateContext";
import { handleSaleOptionAction } from "@Hooks/handleSaleOptionAction/handleSaleOptionAction";
import {
  APPOINTMENTS_TYPE,
  PACKAGES_TYPE,
  PRODUCTS_TYPE,
  SERVICES_TYPE,
  GIFTCARDS_TYPE,
} from "@Models/const/salesOptions";
import { useAppSelector } from "@Hooks/store";

const useShortcuts = () => {
  const dispatch = useDispatch();
  const client = useAppSelector((state) => state.currentSale.client);
  const genericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const selectedClient = genericClient || client;
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const clientModalContext = useContext(
    ClientsModalStateContext
  ) as ClientsModalStateContextProps;

  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    setIsModalActive(modalContext.visibility.isVisible);
  }, [modalContext.visibility]);

  useEffect(() => {
    const handleKeyboardEvent = (event: KeyboardEvent) => {
      const combination = combinations.find((combination) => {
        return (
          (combination.key === event.key || combination.macKey === event.key) &&
          ((combination.ctrlKey && event.ctrlKey) ||
            (combination.altKey && event.altKey) ||
            (combination.shiftKey && event.shiftKey) ||
            (combination.metaKey && event.metaKey))
        );
      });

      if (combination) {
        switch (combination.action) {
          case OPEN_CLIENT_MODAL:
            if (isModalActive) return;
            changeModalClient(
              modalContext,
              clientModalContext,
              dispatch,
              () => {
                modalContext.handleVisibilityChange({
                  isVisible: false,
                  setIsVisible: () => {},
                });
              }
            );
            break;
          case OPEN_PRODUCTS_MODAL:
            if (isModalActive) return;
            handleSaleOptionAction(
              PRODUCTS_TYPE,
              dispatch,
              selectedClient,
              modalContext,
              clientModalContext,
              client
            );
            break;
          case OPEN_APPOINTMENTS_MODAL:
            if (isModalActive) return;
            handleSaleOptionAction(
              APPOINTMENTS_TYPE,
              dispatch,
              selectedClient,
              modalContext,
              clientModalContext,
              client
            );
            break;
          case OPEN_SERVICES_MODAL:
            if (isModalActive) return;
            handleSaleOptionAction(
              SERVICES_TYPE,
              dispatch,
              selectedClient,
              modalContext,
              clientModalContext,
              client
            );
            break;
          case OPEN_PACKAGES_MODAL:
            if (isModalActive) return;
            handleSaleOptionAction(
              PACKAGES_TYPE,
              dispatch,
              selectedClient,
              modalContext,
              clientModalContext,
              client
            );
            break;
          case OPEN_GIFTCARDS_MODAL:
            if (isModalActive) return;
            handleSaleOptionAction(
              GIFTCARDS_TYPE,
              dispatch,
              selectedClient,
              modalContext,
              clientModalContext,
              client
            );
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyboardEvent);

    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent);
    };
    // eslint-disable-next-line
  }, [client, isModalActive]);
};

export default useShortcuts;
