import { AppDispatch } from "@ReduxService/index";
import { updateSaleBillingDocument } from "@ReduxService/states/currentSaleSlice";

export const handleBillingDocumentChange = (
  e: any,
  dispatch: AppDispatch,
  setValues: Function
) => {
  const { value } = e.target;
  setValues((prev: any) => ({
    ...prev,
    billingDocument: value,
  }));

  dispatch(updateSaleBillingDocument(value));
};
