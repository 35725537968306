import { AppDispatch } from "@ReduxService/index";
import { updateLoungeEmployees } from "@ReduxService/states/saleConfigSlice";
import { getLoungeEmployees_service } from "@Services/loungeEmployees";
import { capitalizeWords } from "@Utilities/CapitalizeWords";

export const getLoungeEmployees = async (dispatch: AppDispatch) => {
  const response: IResponse = await getLoungeEmployees_service();

  if (!response.isSuccessful) {
    return response;
  }

  let formattedResponse: IEmployee[] = response.data
    .map((employee: any) => {
      if (!employee.name) return null;
      return {
        id: employee.idEmployee,
        name: capitalizeWords(employee.name) || "",
        lastName: capitalizeWords(employee.lastName) || "",
        email: employee.email || "",
        phoneNumber: employee.phoneNumber || "",
        profilePicture: employee.profileImage,
        position: employee.typeOfPosition,
      };
    })
    .filter((employee: IEmployee | null) => employee !== null);

  dispatch(updateLoungeEmployees(formattedResponse));

  return response;
};
