import React, { useContext } from "react";

// @ts-ignore
import styles from "./ListCashregisters.module.css";
import { CashRegisterStrokeIcon } from "@Models/icons";
import { Icon } from "@Models/resources-components";
import { useAppDispatch } from "@Hooks/store";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import { changeModalSalebox } from "@Hooks/UpdateModalType/changeModalSalebox/changeModalSalebox";
import { changeModalCashCount } from "@Hooks/UpdateModalType/changeModalCashCount/changeModalCashCount";
import { CREATE_SALES_UUID } from "@Models/const/securityAccess";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";
import { changeModalDowngrade } from "@Hooks/UpdateModalType/changeModalDowngrade/changeModalDowngrade";

export function ListCashregisters({
  saleBoxes,
}: Readonly<IListSaleBoxesProps>) {
  const dispatch = useAppDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;

  const handleSaleBoxClick = (saleBox: ISaleBox) => {
    if (saleBox.isDowngraded) {
      changeModalDowngrade(modalContext);
      return;
    }

    if (saleBox.needsCashCount) {
      //eslint-disable-next-line no-alert
      changeModalCashCount(modalContext, saleBox, false);
    } else {
      changeModalSalebox(saleBox, modalContext, dispatch, true);
    }
  };

  return (
    <div className={styles.ListCashregisters}>
      <h1 className={styles.ListCashregisters__title}>
        Iniciar Caja Registradora
      </h1>
      <h2 className={styles.ListCashregisters__subtitle}>
        Selecciona una caja registradora para comenzar.
      </h2>
      {saleBoxes.length > 0 ? (
        <section className={styles.ListCashregisters__container}>
          {saleBoxes.map((saleBox: ISaleBox) => (
            <button
              key={saleBox.id}
              className={`${styles.ListCashregisters__card} ${
                saleBox.isDowngraded
                  ? styles["ListCashregisters__card--disabled"]
                  : ""
              }`}
              onClick={() =>
                validateModuleAccess(CREATE_SALES_UUID) &&
                handleSaleBoxClick(saleBox)
              }
            >
              <div
                className={`${styles.ListCashregisters__card__state} ${
                  saleBox.isOpen
                    ? styles.ListCashregisters__card__isOpened
                    : styles.ListCashregisters__card__isClosed
                }`}
                title={saleBox.isOpen ? "Caja abierta" : "Caja cerrada"}
              />
              <span className={styles.ListCashregisters__card__content}>
                <Icon
                  path={CashRegisterStrokeIcon}
                  color={"#937cf4"}
                  size={48}
                />
                <h3 className={styles.ListCashregisters__card__title}>
                  {saleBox.name}
                </h3>
              </span>
            </button>
          ))}
        </section>
      ) : (
        <p className={styles.ListCashregisters__empty}>
          No hemos podido encontrar cajas registradoras disponibles.
          <br />
          Si crees que esto es un error, por favor, contacta a nuestro equipo de
          soporte para obtener asistencia.
        </p>
      )}
    </div>
  );
}

export default ListCashregisters;
