import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = {
  saleDocuments: [],
  pages: 0,
  currentPagination: 1,
  shouldReloadTable: false,
};

const initialState: ISaleDocumentSlice = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const saleDocumentsSlice = createSlice({
  name: "saleDocumentsSlice",
  initialState,
  reducers: {
    updateSaleDocuments: (state, action) => {
      state.saleDocuments = action.payload;
    },
    updatePages: (state, action: PayloadAction<number>) => {
      state.pages = action.payload;
    },
    updateCurrentPagination: (state, action: PayloadAction<number>) => {
      state.currentPagination = action.payload;
    },
    updateShouldReloadTable: (state, action: PayloadAction<boolean>) => {
      state.shouldReloadTable = action.payload;
    },
  },
});

export const {
  updateSaleDocuments,
  updatePages,
  updateCurrentPagination,
  updateShouldReloadTable,
} = saleDocumentsSlice.actions;

export default saleDocumentsSlice.reducer;
