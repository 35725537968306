import React, { useContext, useEffect, useState, forwardRef } from "react";

// @ts-ignore
import style from "./SalesOptionsList.module.css";
import { useAppSelector } from "@Hooks/store";
import TertiaryButton from "@Components/ui/atoms/TertiaryButton/TertiaryButton";
import { AddIcon } from "@Models/icons";
import { GENERIC_CLIENT_ID } from "@Models/const/clients";
import ClientSearch from "@Components/ui/molecules/ClientSearch/ClientSearch";
import {
  addSaleAppointments,
  updateClient,
  updateCommands,
  updateGenericClient,
} from "@ReduxService/states/currentSaleSlice";
import AddClientModal from "@Components/ui/organisms/AddClientModal/AddClientModal";
import {
  ClientsModalStateContext,
  ClientsModalStateContextProps,
} from "@Hooks/NewClientsModalStateContext";
import { getReserveCommands_adapter } from "@Adapters/getReserveCommands";

// Componente principal
const SalesOptionsList = forwardRef<
  HTMLInputElement,
  { screenWidth: number; dispatch: any; callback: () => void }
>(({ screenWidth, dispatch, callback }, inputRef) => {
  // Selección de cliente actual
  const client = useAppSelector((state) => state.currentSale.client);
  const genericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const selectedClient = genericClient || client;

  const [isSearchVisible, setIsSearchVisible] = useState(true);
  const [isCreatingClient, setIsCreatingClient] = useState(false);

  // Verificar si hay un cliente seleccionado
  const isClientSelected = selectedClient !== null;

  // Efecto para mostrar el buscador si no hay cliente seleccionado
  useEffect(() => {
    if (!isClientSelected) {
      setIsSearchVisible(true);
    }
  }, [isClientSelected]);

  // Manejar la selección de un cliente
  const handleSelectClient = async (client: IClient) => {
    if (client.id === GENERIC_CLIENT_ID) {
      dispatch(updateGenericClient(client));
    } else {
      const response = await getReserveCommands_adapter(client.id);
      if (response.isSuccessful) {
        dispatch(updateCommands(response.data.commands));
        dispatch(addSaleAppointments(response.data.appointments));
        dispatch(updateClient(client));
        dispatch(updateGenericClient(null));
      }
    }
    callback(); // Llamar al callback después de seleccionar el cliente
    setIsSearchVisible(false); // Cerrar la búsqueda
  };
  const clientModalContext = useContext(
    ClientsModalStateContext
  ) as ClientsModalStateContextProps;
  const handleAddClient = () => {
    setIsCreatingClient(true);
    // Aquí puedes añadir cualquier lógica adicional que necesites replicar
    // del componente SelectClientModal, como actualizar contextos o estados.
  };
  useEffect(() => {
    clientModalContext.handleVisibilityChange({
      isVisible: isCreatingClient,
      setIsVisible: setIsCreatingClient,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingClient]);

  return (
    <div className={style.SelectedCashregisterBody__left__salesOptions}>
      {!isClientSelected && isSearchVisible && (
        <ClientSearch
          onSelectClient={handleSelectClient}
          onCloseSearch={() => setIsSearchVisible(false)}
          inputRef={inputRef as React.RefObject<HTMLInputElement>}
        />
      )}
      {!isClientSelected && (
        <div className={style.addClientButtonContainer}>
          <TertiaryButton
            buttonProps={{
              onClick: handleAddClient,
            }}
            icon={AddIcon}
            value="Añadir nuevo cliente"
          />
        </div>
      )}
      <AddClientModal
        handleCreateClient={(client: IClient) => handleSelectClient(client)}
        setIsVisible={setIsCreatingClient}
      />
    </div>
  );
});

export default SalesOptionsList;
