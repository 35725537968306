import { ERROR_CONST, WARNING_CONST } from "@Models/const/common";
import { useContext, useEffect, useRef } from "react";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "../../../hooks/AcceptCancelModalContext";
import { changeModalEBillingAmount } from "../../../hooks/UpdateModalType/changeModalEBillingAmount/changeModalEBillingAmount";
import { useAppSelector } from "../../../hooks/store";

export const useEBillingModal = () => {
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const selectedSaleBox = useAppSelector((state) => state.selectedSaleBox);
  const electronicBilling = useAppSelector(
    (state) => state.saleConfig.electronicBilling
  );
  const electronicBillingAmmount = useAppSelector(
    (state) => state.saleConfig.electronicBillingAmmount
  );
  const hasShownModal = useRef(false);

  useEffect(() => {
    const storedWarningDate = localStorage.getItem("lastEBillingWarningDate");
    const shouldShowWarning =
      !storedWarningDate ||
      storedWarningDate !== electronicBilling.lastPurchaseDate;
    if (
      electronicBilling.isRunningOutOfDocuments &&
      !hasShownModal.current &&
      selectedSaleBox.hasElectronicBill &&
      shouldShowWarning
    ) {
      hasShownModal.current = true;
      const EBillingStatus =
        electronicBillingAmmount > 0 ? WARNING_CONST : ERROR_CONST;
      changeModalEBillingAmount(modalContext, EBillingStatus, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    electronicBilling.isRunningOutOfDocuments,
    electronicBilling.lastPurchaseDate,
    selectedSaleBox.hasElectronicBill,
    modalContext,
  ]);
};
