// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fQ5BHx_w4ITIa8hB0lQ1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1rem;
}

.W_NJy3dnYxd6GXbbUf54 {
  font: 600 1rem "Mulish", sans-serif;
  color: #252525;
  text-align: center;
}

.EOcJkHYmJNUa81Sy8x_n {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--color-text-secondary);
  text-align: center;
  max-width: 500px;
  margin-bottom: 1rem;
}

.q9nSVLlwCXQEqcCF5qXy {
  width: 100%;
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 546px) {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/SavedSaleModal/SavedSaleModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;EAEvB,SAAS;AACX;;AAEA;EACE,mCAAmC;EACnC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,SAAS;;EAET;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".body {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  gap: 1rem;\n}\n\n.title {\n  font: 600 1rem \"Mulish\", sans-serif;\n  color: #252525;\n  text-align: center;\n}\n\n.description {\n  font-size: 0.8rem;\n  font-weight: 400;\n  color: var(--color-text-secondary);\n  text-align: center;\n  max-width: 500px;\n  margin-bottom: 1rem;\n}\n\n.buttonsContainer {\n  width: 100%;\n  display: flex;\n  gap: 1rem;\n\n  @media screen and (max-width: 546px) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `fQ5BHx_w4ITIa8hB0lQ1`,
	"title": `W_NJy3dnYxd6GXbbUf54`,
	"description": `EOcJkHYmJNUa81Sy8x_n`,
	"buttonsContainer": `q9nSVLlwCXQEqcCF5qXy`
};
export default ___CSS_LOADER_EXPORT___;
