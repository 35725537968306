// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YUqTyyx72zZI8E7wNy1y {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  gap: 10px;

  @media screen and (width < 400px) {
    display: none;
  }
}

.iZN3G1fnQIH1p4FYyXdi {
  font-size: 1rem;
  color: #45348e;
}

.tiMHQb7IgRH1HlacIao4 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SaleboxReport/components/PaymentMethodsChart/PaymentMethodsChart.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,SAAS;;EAET;IACE,aAAa;EACf;AACF;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: -webkit-fill-available;\n  gap: 10px;\n\n  @media screen and (width < 400px) {\n    display: none;\n  }\n}\n\n.label {\n  font-size: 1rem;\n  color: #45348e;\n}\n\n.content {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  border: 1px solid #e5e5e5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `YUqTyyx72zZI8E7wNy1y`,
	"label": `iZN3G1fnQIH1p4FYyXdi`,
	"content": `tiMHQb7IgRH1HlacIao4`
};
export default ___CSS_LOADER_EXPORT___;
