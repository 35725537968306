import React, { useContext, useEffect, useState } from "react";
// @ts-ignore
import styles from "./PayoutForm.module.css";

import {
  ButtonWithoutIcon,
  SelectInput,
  TextInput,
} from "@Models/resources-components";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { handleBillingDocumentChange } from "./functions/handleBillingDocumentChange";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import { changeModalClient } from "@Hooks/UpdateModalType/changeModalClient/changeModalClient";
import {
  ClientsModalStateContext,
  ClientsModalStateContextProps,
} from "@Hooks/NewClientsModalStateContext";
import { updateGenericClient } from "@ReduxService/states/currentSaleSlice";
import { GENERIC_CLIENT_ID } from "@Models/const/clients";
import { changeModalPayout } from "@Hooks/UpdateModalType/changeModalPayout/changeModalPayout";
import BillingDocumentsBullet from "@Components/ui/atoms/BillingDocumentsBullet/BillingDocumentsBullet";
import { changeModalEBillingAmount } from "@Hooks/UpdateModalType/changeModalEBillingAmount/changeModalEBillingAmount";
import {
  ERROR_CONST,
  SUCCESS_CONST,
  WARNING_CONST,
} from "@Models/const/common";

const PayoutForm = () => {
  const dispatch = useAppDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const clientModalContext = useContext(
    ClientsModalStateContext
  ) as ClientsModalStateContextProps;
  const selectedClient = useAppSelector((state) => state.currentSale.client);
  const storeGenericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const selectedBillingDocument = useAppSelector(
    (state) => state.currentSale.billingDocument
  );

  const billingDocuments = useAppSelector(
    (state) => state.saleConfig.billingDocuments
  );
  const availableBillingDocuments = billingDocuments.filter(
    (billingDocument) => billingDocument.isAvailable
  );
  const electronicBillingAmmount = useAppSelector(
    (state) => state.saleConfig.electronicBillingAmmount
  );
  const sales = useAppSelector((state) => state.currentSale.sales);

  const [values, setValues] = useState({
    couponCode: "",
    giftcardCode: "",
    client: selectedClient || storeGenericClient,
    billingDocument:
      selectedBillingDocument?.id || selectedBillingDocument || "",
  });

  const summary = useAppSelector((state) => state.currentSale.summary);

  const [isSubmitBttnDisabled, setIsSubmitBttnDisabled] = useState(true);
  const isBillingDisabled =
    values.billingDocument && values.billingDocument === 2 && values.client
      ? Number(values.client.documentType?.id) !== 1 ||
        values.client.id === GENERIC_CLIENT_ID
      : false;

  // Check if the submit button should be disabled
  useEffect(() => {
    const { client, billingDocument } = values;

    // Función auxiliar para verificar empleados en paquetes
    const hasValidEmployees = () => {
      return sales.packages.every((pkg) => {
        // Verifica empleados en servicios del paquete
        const validServices = pkg.services.every(
          (service) => service.employeeId && service.employeeId !== 0
        );

        // Verifica empleados en productos del paquete
        const validProducts = pkg.products.every(
          (product) => product.employeeId && product.employeeId !== 0
        );

        return validServices && validProducts;
      });
    };

    if (
      client &&
      billingDocument &&
      (sales.products.length > 0 ||
        sales.services.length > 0 ||
        sales.appointments.length > 0 ||
        sales.packages.length > 0 ||
        sales.commands.length > 0 ||
        sales.giftcards.length > 0) &&
      !isBillingDisabled &&
      hasValidEmployees()
    ) {
      setIsSubmitBttnDisabled(false);
    } else {
      setIsSubmitBttnDisabled(true);
    }
  }, [values, sales, isBillingDisabled]);

  // Update values when selected client or billing document changes
  useEffect(() => {
    setValues({
      ...values,
      client: storeGenericClient || selectedClient,
      billingDocument:
        selectedBillingDocument?.id || selectedBillingDocument || "",
    });
    // eslint-disable-next-line
  }, [selectedClient, storeGenericClient, selectedBillingDocument]);

  const handleSubmitPayout = () => {
    let type;
    if (electronicBillingAmmount >= 50) {
      type = SUCCESS_CONST;
    } else if (electronicBillingAmmount <= 0) {
      type = ERROR_CONST;
    } else {
      type = WARNING_CONST;
    }

    if (
      // @ts-ignore
      selectedBillingDocument !== 5 &&
      electronicBillingAmmount <= 0
    ) {
      changeModalEBillingAmount(modalContext, type);
    } else {
      changeModalPayout(modalContext, dispatch);
    }
  };

  // Update billing document when selectedBillingDocument changes (used for stored data)
  useEffect(() => {
    handleBillingDocumentChange(
      { target: { value: selectedBillingDocument } },
      dispatch,
      setValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBillingDocument]);

  const clientInputValue =
    values.client?.id === GENERIC_CLIENT_ID
      ? "Cliente genérico"
      : `${values.client?.name} ${values.client?.lastName}`;

  const billingDocumentsOptions = availableBillingDocuments.map(
    (billingDocument) => ({
      value: billingDocument.id,
      name: billingDocument.name,
    })
  );

  return (
    <form
      className={styles.PayoutFormContainer}
      onSubmit={(e) => e.preventDefault()}
    >
      <section className={styles.PayoutFormContainer__information}>
        <div className={styles.PayoutFormContainer__top}>
          <SelectInput
            label="Tipo de comprobante"
            value={values.billingDocument || ""}
            name="billingDocument"
            items={billingDocumentsOptions}
            handleChange={(e) =>
              handleBillingDocumentChange(e, dispatch, setValues)
            }
            required
          />
          {selectedBillingDocument && values.billingDocument !== 5 && (
            <BillingDocumentsBullet />
          )}
          <div className={styles.PayoutFormContainer__top__clientContainer}>
            <div
              className={
                styles.PayoutFormContainer__top__clientContainer__inputContainer
              }
            >
              <TextInput
                label="Facturar a..."
                value={values.client?.id !== undefined ? clientInputValue : ""}
                name="clientId"
                placeholder="Seleccionar cliente"
                disabled
                required
              />
              <button
                className={
                  styles.PayoutFormContainer__top__clientContainer__inputContainer__clickHandler
                }
                onClick={(e) => {
                  e.preventDefault();
                  changeModalClient(
                    modalContext,
                    clientModalContext,
                    dispatch,
                    () => {
                      modalContext.handleVisibilityChange({
                        isVisible: false,
                        setIsVisible: () => {},
                      });
                    }
                  );
                }}
              />
            </div>
            <span
              className={styles.PayoutFormContainer__top__checkboxContainer}
            >
              <input
                type="checkbox"
                name="clientId"
                id="clientId"
                checked={values.client?.id === GENERIC_CLIENT_ID}
                onChange={(e) => {
                  dispatch(
                    updateGenericClient(
                      e.target.checked
                        ? ({
                            id: GENERIC_CLIENT_ID,
                            name: "Cliente genérico",
                            lastName: "",
                          } as IClient)
                        : null
                    )
                  );
                }}
              />
              <label
                htmlFor="clientId"
                className={styles.PayoutFormContainer__top__checkboxLabel}
              >
                Cliente genérico
              </label>
            </span>
            {isBillingDisabled && (
              <span
                className={
                  styles.PayoutFormContainer__top__clientContainer__warning
                }
              >
                Para emitir facturas electrónicas, el cliente debe tener RUC
                como tipo de documento.
              </span>
            )}
          </div>
          {/* <TextInput
						label="Giftcard"
						value={values.giftcardCode}
						name="giftcardCode"
						placeholder="Escribe el código de la giftcard"
					/>
					<TextInput
						label="Cupón o código de descuento"
						value={values.couponCode}
						name="couponCode"
						placeholder="Escribe el código del cupón o descuento"
					/> */}
        </div>
        <div className={styles.PayoutFormContainer__bottom}>
          <strong>
            <p className={styles.PayoutFormContainer__bottom__title}>Resumen</p>
          </strong>

          <span className={styles.PayoutFormContainer__bottom__item}>
            <p>Subtotal</p>
            <p>{formatToSoles(summary.subtotal)}</p>
          </span>
          <span className={styles.PayoutFormContainer__bottom__item}>
            <p>IGV</p>
            <p>{formatToSoles(summary.igv)}</p>
          </span>
          <span className={styles.PayoutFormContainer__bottom__item}>
            <p>ICBP</p>
            <p>{formatToSoles(summary.icbp)}</p>
          </span>
          <span
            className={`${styles.PayoutFormContainer__bottom__item} ${styles.PayoutFormContainer__bottom__item__discount}`}
          >
            <p>Descuento</p>
            <p>{`${summary.discount > 0 ? "-" : ""}${formatToSoles(
              summary.discount
            )}`}</p>
          </span>
          <span className={styles.PayoutFormContainer__bottom__item}>
            <strong>
              <p
                className={`${styles.PayoutFormContainer__bottom__title} ${styles.PayoutFormContainer__bottom__item}`}
              >
                Total a pagar
              </p>
            </strong>
            <strong>
              <p className={styles.PayoutFormContainer__bottom__title}>
                {formatToSoles(summary.total)}
              </p>
            </strong>
          </span>
        </div>
      </section>
      <ButtonWithoutIcon
        isPrimary
        textBttn={"Cobrar"}
        handleClick={handleSubmitPayout}
        isDisabled={isSubmitBttnDisabled}
        width="100%"
      />
    </form>
  );
};

export default PayoutForm;
