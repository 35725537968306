import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { getClientsByText } from "@Adapters/getClientsByText.adapter";
import { useDebounce } from "@Hooks/useDebounce/useDebounce";
import ClientButton from "@Components/ui/organisms/SelectClientModal/components/ClientButton/ClientButton";
import style from "./ClientSearch.module.css";
import { GENERIC_CLIENT_ID } from "@Models/const/clients";
// @ts-ignore
import { TextInput, LoadingSpinner } from "@viuti/recursos";
import { MagnifyingGlassIcon } from "@Models/icons";

interface ClientSearchProps {
  onSelectClient: (client: IClient) => void;
  onCloseSearch: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const ClientSearch = ({
  onSelectClient,
  onCloseSearch,
  inputRef,
}: ClientSearchProps) => {
  const dispatch = useAppDispatch();
  const registeredClients = useAppSelector(
    (state) => state.saleConfig.registeredClients
  );
  const [searchClientValue, setSearchClientValue] = useState("");
  const [isSearchingClients, setIsSearchingClients] = useState(false);
  const [genericClientAvailable, setGenericClientAvailable] = useState(true);
  const debouncedSearchTerm = useDebounce(searchClientValue, 300);
  const [isResultsVisible, setIsResultsVisible] = useState(false);

  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        await getClientsByText(debouncedSearchTerm, dispatch);
        setIsSearchingClients(false);
      })();
    }
  }, [debouncedSearchTerm, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        !document.getElementById("client-search-container")?.contains(target)
      ) {
        setIsResultsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchClientValue.length > 0) {
      setIsSearchingClients(true);
      setIsResultsVisible(true);
    } else {
      setIsSearchingClients(false);
      setIsResultsVisible(false);
    }
  }, [searchClientValue]);

  const handleClientSelection = (client: IClient) => {
    if (client.id === GENERIC_CLIENT_ID && !genericClientAvailable) {
      alert(
        "El cliente genérico no está disponible. Por favor, seleccione otro cliente."
      );
      return;
    }
    onSelectClient(client);
    onCloseSearch();
  };

  return (
    <div id="client-search-container" className={style.search__container}>
      <div className={style.input__wrapper}>
        <TextInput
          label=""
          placeholder="Buscar cliente por nombre o documento"
          leftIcon={MagnifyingGlassIcon}
          value={searchClientValue}
          handleChange={(e) => setSearchClientValue(e.target.value)}
          disabled={false}
          inputProps={{ maxLength: 100 }}
          required={true}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Lógica al presionar Enter
            }
          }}
          inputMode="text"
          inputRef={inputRef}
          onFocus={() => setIsResultsVisible(true)}
          width={isSearchingClients ? "95%" : "100%"}
        />
        {isSearchingClients && (
          <LoadingSpinner isVisible={isSearchingClients} size={18} />
        )}
      </div>
      {isResultsVisible && registeredClients.length > 0 && (
        <div className={style.clients__container}>
          {registeredClients.length > 0
            ? registeredClients.map((client) => (
                <ClientButton
                  key={client.id}
                  client={client}
                  setSelectedClient={handleClientSelection}
                  isClientSelected={false}
                  initials={`${client.name.charAt(0)}${client.lastName.charAt(
                    0
                  )}`}
                />
              ))
            : null}
        </div>
      )}
    </div>
  );
};

export default ClientSearch;
