import { useEffect } from "react";
import style from "./NeedsCashCountModal.module.css";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
// @ts-ignore
import { ButtonWithoutIcon, Icon, validateModuleAccess } from "@viuti/recursos";
import { QueuedCalendarIcon } from "@Models/icons";
import { changeModalSalebox } from "@Hooks/UpdateModalType/changeModalSalebox/changeModalSalebox";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { CREATE_SALES_UUID } from "@Models/const/securityAccess";

interface INeedsCashCountModalProps {
  modalContext: AcceptCancelModalContextProps;
  saleBox: ISaleBox;
  isRequired: boolean;
}

const NeedsCashCountModal = ({
  modalContext,
  saleBox,
  isRequired = true,
}: INeedsCashCountModalProps) => {
  const dispatch = useAppDispatch();
  const availableSaleBoxes = useAppSelector((state) => state.saleBoxesList);

  useEffect(() => {
    modalContext.handleShowButtonsChange({
      showButtonClose: availableSaleBoxes.length > 1,
      showButtonTwo: false,
    });
    modalContext.handleActionButtonCloseChange(() => {
      modalContext.handleClose();
      if (localStorage.getItem("lastSaleboxVisited")) {
        window.location.href = "/cajas";
        localStorage.removeItem("lastSaleboxVisited");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.body}>
      <Icon path={QueuedCalendarIcon} size={48} color="#45348e" />
      <h2 className={style.title}>
        Completa tu arqueo diario ahora mismo para continuar.
      </h2>
      <p className={style.description}>
        No olvides realizar tu arqueo diario para mantener un registro preciso
        de tus transacciones y asegurar la integridad de tus registros
        financieros.
      </p>
      <ButtonWithoutIcon
        isPrimary
        textBttn={"Cerrar caja"}
        handleClick={() =>
          changeModalSalebox(saleBox, modalContext, dispatch, false, isRequired)
        }
        width="100%"
      />
    </div>
  );
};

export default NeedsCashCountModal;
