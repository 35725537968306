import axios from "axios";
import { loungesBaseUrl, config } from "./constGlobals";

export const getBillingDocuments_service = async (saleBoxId: number) => {
  const url = `${loungesBaseUrl}/Billing/DocumentSaleBox?idSaleBox=${saleBoxId}`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los tipos de facturas.",
      data: [],
    };
  }
};
