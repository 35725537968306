import React, { useEffect } from "react";
import style from "./PackageDetailsModal.module.css";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { formatToSoles } from "@Utilities/FormatsHandler";
// @ts-ignore
import { IconWrapper } from "@viuti/recursos";
import {
  HiCalendarDays,
  HiTag,
  HiCurrencyDollar,
  HiShoppingCart,
  HiClock,
} from "react-icons/hi2";

interface IPackageDetailsModalProps {
  modalContext: AcceptCancelModalContextProps;
  packageItem: IPackage;
}

const PackageDetailsModal = ({
  modalContext,
  packageItem,
}: IPackageDetailsModalProps) => {
  const isStockAvailable = packageItem.stock === null || packageItem.stock > 0;
  const stockMessage = isStockAvailable
    ? packageItem.stock === null
      ? "Stock ilimitado"
      : `Hay ${packageItem.stock} disponibles`
    : "Sin stock";
  const allPaymentMethods = packageItem.paymentMethods.length === 0;

  useEffect(() => {
    modalContext.handleShowButtonsChange({
      showButtonClose: true,
      showButtonTwo: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.body}>
      <div className={style.package_icons}>
        <div className={style.icon_container}>
          <span
            className={`${style.icon_wrapper} ${
              packageItem.isAvailable && packageItem.isValid
                ? style.available_icon
                : style.unavailable_icon
            }`}
          >
            <IconWrapper icon={HiCalendarDays} className={style.icon} />
          </span>
          <span className={style.icon_label}>
            {packageItem.isAvailable
              ? "Promoción disponible"
              : packageItem.conflictMessage}
          </span>
        </div>
        <div className={style.icon_container}>
          <span
            className={`${style.icon_wrapper} ${
              isStockAvailable ? style.available_icon : style.unavailable_icon
            }`}
          >
            <IconWrapper icon={HiTag} className={style.icon} />
          </span>
          <span className={style.icon_label}>
            {isStockAvailable ? stockMessage : "Sin stock"}
          </span>
        </div>
        <div className={style.icon_container}>
          <span className={style.icon_wrapper}>
            <IconWrapper
              icon={HiCurrencyDollar}
              className={style.icon}
              color="#45348e"
            />
          </span>
          <span className={style.icon_label}>
            {formatToSoles(packageItem.price + packageItem.igv)}
          </span>
        </div>
      </div>

      {packageItem.products?.length > 0 && (
        <div className={style.section}>
          <h3 className={style.sectionTitle}>Productos incluidos</h3>
          <ul className={style.list}>
            {packageItem.products.map((product) => (
              <li key={product.id} className={style.listItem}>
                <IconWrapper
                  icon={HiShoppingCart}
                  className={style.item_icon}
                  color="#45348e"
                />
                {product.name} - {formatToSoles(product.price + product.igv)}
              </li>
            ))}
          </ul>
        </div>
      )}

      {packageItem.services?.length > 0 && (
        <div className={style.section}>
          <h3 className={style.sectionTitle}>Servicios incluidos</h3>
          <ul className={style.list}>
            {packageItem.services.map((service) => (
              <li key={service.priceId} className={style.listItem}>
                <IconWrapper
                  icon={HiClock}
                  className={style.item_icon}
                  color="#45348e"
                />
                {service.name} - {formatToSoles(service.price + service.igv)}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={style.section}>
        <h3 className={style.sectionTitle}>Medios de pago disponibles</h3>
        <ul className={style.payment_methods}>
          {allPaymentMethods ? (
            <li className={style.payment_method}>Todos los medios de pago</li>
          ) : (
            packageItem.paymentMethods.map((method, index) => (
              <li key={index} className={style.payment_method}>
                {method.name}
              </li>
            ))
          )}
        </ul>
      </div>

      <div className={style.section}>
        <h3 className={style.sectionTitle}>Términos y condiciones</h3>
        <p className={style.terms_conditions}>{packageItem.condition}</p>
      </div>
    </div>
  );
};

export default PackageDetailsModal;
