// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QA1hh2s4ZnqJcoL1ogwA {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1rem;
}

.pfltkWUkc5c9kuX34rgU {
  font: 600 1rem "Mulish", sans-serif;
  color: #252525;
  text-align: center;
}

.sJQAkBJwDfHMQoX2c4z3 {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--color-text-secondary);
  text-align: center;
  max-width: 500px;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/NeedsCashCountModal/NeedsCashCountModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;EAEvB,SAAS;AACX;;AAEA;EACE,mCAAmC;EACnC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".body {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  gap: 1rem;\n}\n\n.title {\n  font: 600 1rem \"Mulish\", sans-serif;\n  color: #252525;\n  text-align: center;\n}\n\n.description {\n  font-size: 0.8rem;\n  font-weight: 400;\n  color: var(--color-text-secondary);\n  text-align: center;\n  max-width: 500px;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `QA1hh2s4ZnqJcoL1ogwA`,
	"title": `pfltkWUkc5c9kuX34rgU`,
	"description": `sJQAkBJwDfHMQoX2c4z3`
};
export default ___CSS_LOADER_EXPORT___;
