// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bFx3icAmImE3EuXak3Sr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.VuoppanTSpKKCjOwTYln {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  position: sticky;
  left: 0px;
}

.ReE04jXhAMLCA80N8CbG {
  display: flex;
  align-items: center;
  gap: 4px;

  h5 {
    color: #3a3a3a;
  }
}

.E_5rMr8kgud0LHVjiHHA {
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #d82121;

  &:hover {
    text-decoration: underline;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/SaleAppointmentRow/SaleAppointmentRow.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,8BAA8B;EAC9B,QAAQ;EACR,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;;EAER;IACE,cAAc;EAChB;AACF;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,cAAc;;EAEd;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".sales__container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 10px;\n}\n\n.appointment__title {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: space-between;\n  gap: 4px;\n  position: sticky;\n  left: 0px;\n}\n\n.appointment__title__info {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  h5 {\n    color: #3a3a3a;\n  }\n}\n\n.appointment__title__button__remove {\n  border: none;\n  border-radius: 50%;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 4px;\n  color: #d82121;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sales__container": `bFx3icAmImE3EuXak3Sr`,
	"appointment__title": `VuoppanTSpKKCjOwTYln`,
	"appointment__title__info": `ReE04jXhAMLCA80N8CbG`,
	"appointment__title__button__remove": `E_5rMr8kgud0LHVjiHHA`
};
export default ___CSS_LOADER_EXPORT___;
