import React, { useEffect, useState } from "react";
//@ts-ignore
import styled from "./PrintableTicketHTML.module.css";
import { formatToSoles } from "@Utilities/FormatsHandler";
import qrSvg from "@Utilities/converterQrSvg";

interface Props {
  billingData: {
    TITLE_NAME: string;
    BUSINESS: {
      NAME: string;
      RUC: string;
      RESPONSABLE: string;
    };
    LOCAL: {
      NAME: string;
      LOCATION: string;
      EMAIL: string;
      PHONE: string;
    };
    BILLING: {
      DOCUMENT: string;
      CODE: string;
      PRODUCTS: {
        DESCRIPTION: string;
        AMOUNT: number;
        TOTAL: number;
      }[];
    };
    CLIENT: {
      DOCUMENT_TYPE: string;
      DOCUMENT: string;
      NAME: string;
      LOCATION: string;
    };
    PRICING: {
      OP_ICBPER: number;
      OP_EXONERADAS: number;
      OP_IGV: number;
      OP_GRAVADAS: number;
      OP_DESCUENTO: number;
      OP_DESCUENTO_GIFT: number;
      OP_TOTAL: number;
    };
    PAYMENT_METHODS: {
      DESCRIPTION: string;
      AMOUNT: number;
    }[];
    DATA_TICKET: {
      HASH: string;
      QR_DATA: string;
    };
    IS_FREE_SALE: boolean;
  };
  reference: any;
}
// The reference is need to react-to-print library
const PrintableTicketHTML = (props: Props) => {
  const {
    TITLE_NAME,
    BUSINESS,
    LOCAL,
    BILLING,
    CLIENT,
    PRICING,
    PAYMENT_METHODS,
    DATA_TICKET,
    IS_FREE_SALE,
  } = props.billingData;

  const currentDate = new Date();
  const [cashBack, setCashBack] = useState(0);
  const [todaysDate, setTodaysDate] = useState(currentDate);

  const formattedDate = `F. Emision ${todaysDate.toLocaleDateString(
    "es-ES"
  )} ${todaysDate.toLocaleTimeString()}`;

  useEffect(() => {
    setTodaysDate(currentDate);
    const paymentMethodTotal = PAYMENT_METHODS.map(
      (paymentMethod) => paymentMethod.AMOUNT
    ).reduce((acc, curr) => acc + parseFloat(String(curr)), 0);
    setCashBack(PRICING.OP_TOTAL - paymentMethodTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PAYMENT_METHODS]);

  return (
    <section ref={props.reference} className={styled.printContainer}>
      <header>
        <h1>Caja - {TITLE_NAME}</h1>
        <p>{LOCAL.NAME}</p>
        {BUSINESS.RUC && (
          <>
            <p>{BUSINESS.NAME}</p>
            <p>RUC {BUSINESS.RUC}</p>
            <p>Dirección de factura: {LOCAL.LOCATION}</p>
          </>
        )}
        {/* <p>{LOCAL.EMAIL}</p> */}
        {/* <p>Telf. {LOCAL.PHONE}</p> */}
        <p>{formattedDate}</p>
      </header>

      <article className={styled.billCode}>
        <p>{BILLING.DOCUMENT}</p>
        <p>{BILLING.CODE}</p>
      </article>

      <article className={styled.clientInformation}>
        <p>
          {CLIENT.DOCUMENT_TYPE}: {CLIENT.DOCUMENT}
        </p>
        <p>Sr(s): {CLIENT.NAME}</p>
      </article>

      <tr className={styled.trHeader}>
        <td>DESCRIPCIÓN</td>
        <td>CANT</td>
        <td>TOTAL</td>
      </tr>
      {BILLING.PRODUCTS.map((product) => (
        <tr key={product.DESCRIPTION} className={styled.productRow}>
          <td className={styled.productRow__name}>{product.DESCRIPTION}</td>
          <td>{product.AMOUNT}</td>
          <td>{product.TOTAL}</td>
        </tr>
      ))}
      <article className={styled.price__detail}>
        <p>OP. EXONERADAS S/.</p>
        <p>{formatToSoles(PRICING.OP_EXONERADAS, false)}</p>
        <p>OP. GRAVADAS S/.</p>
        <p>{formatToSoles(PRICING.OP_GRAVADAS, false)}</p>
        <p>OP. IGV S/.</p>
        <p>{formatToSoles(PRICING.OP_IGV, false)}</p>
        <p>OP. ICBPER S/.</p>
        <p>{formatToSoles(PRICING.OP_ICBPER, false)}</p>
        <p>Descuento S/.</p>
        <p>
          {formatToSoles(
            `${PRICING.OP_DESCUENTO > 0 ? "-" : ""}${PRICING.OP_DESCUENTO}`,
            false
          )}
        </p>
        {/* <p>Descuento Gift S/.</p>
        <p>
          {formatToSoles(
            `${PRICING.OP_DESCUENTO_GIFT > 0 ? "-" : ""}${
              PRICING.OP_DESCUENTO_GIFT
            }`,
            false
          )}
        </p> */}
        <p>OP. TOTAL S/.</p>
        <p>{formatToSoles(PRICING.OP_TOTAL, false)}</p>
        <p>VUELTO S/.</p>
        <p>{formatToSoles(cashBack, false)}</p>
      </article>

      {IS_FREE_SALE && (
        <p className={styled.freeSale}>VENTA DE TRANSFERENCIA GRATUITA</p>
      )}
      {!IS_FREE_SALE && (
        <>
          {PAYMENT_METHODS.map((paymentMethod) => (
            <tr
              key={paymentMethod.DESCRIPTION}
              className={styled.billing__usedType}
            >
              <td className={styled.billingType}>
                {paymentMethod.DESCRIPTION}
              </td>
              <td>{formatToSoles(paymentMethod.AMOUNT)}</td>
            </tr>
          ))}
        </>
      )}

      <p style={{ textAlign: "left", marginBottom: "20px", marginTop: "10px" }}>
        Resp. {BUSINESS.RESPONSABLE}
      </p>

      <footer className={styled.billing__footer}>
        <p>ESTA ES UNA REPRESENTACIÓN IMPRESA DEL</p>
        <p>DOCUMENTO DE VENTA ELECTRÓNICA</p>
        {DATA_TICKET?.HASH && (
          <p className={styled.qrContainer__hash}>{DATA_TICKET?.HASH}</p>
        )}
        <p className={styled.message}>
          Para consultar este documento diríjase a:
          https://www.operador.pe/buscar
        </p>
        <strong className={styled.qrContainer__thanks}>
          ** Gracias por su visita **
        </strong>
        {DATA_TICKET?.QR_DATA && (
          <div
            className={styled.qrContainer__qr}
            dangerouslySetInnerHTML={{ __html: qrSvg(DATA_TICKET?.QR_DATA) }}
          />
        )}
      </footer>
    </section>
  );
};

export default PrintableTicketHTML;
