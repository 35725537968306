import React, { createContext, useMemo, useState } from "react";

export interface AcceptCancelModalContextProps {
  title: string | Element;
  handleTitleChange: Function;
  visibility: {
    isVisible: boolean;
    setIsVisible: Function | null;
  };
  handleVisibilityChange: Function;
  showButtons: {
    showButtonClose: boolean;
    showButtonTwo: boolean;
  };
  handleShowButtonsChange: Function;
  buttonOne?: {
    textButtonOne: string;
    actionButtonOne: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
  buttonTwo?: {
    textButtonTwo: string;
    actionButtonTwo: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
  handleButtonOneChange: Function;
  handleButtonTwoChange: Function;
  children: Element | null;
  modalChildren: any;
  handleChildrenChange: Function;
  actionButtonClose: Function | null;
  handleActionButtonCloseChange: Function;
  noSizeLimit: boolean;
  handleNoSizeLimitChange: Function;
  handleClose: Function;
  footer: Object | null;
}

const AcceptCancelModalContext = createContext<
  AcceptCancelModalContextProps | {}
>({});

const AcceptCancelModalProvider = ({ children }) => {
  const [title, setTitle] = useState<string | Element>("");
  const [visibility, setVisibility] = useState<{
    isVisible: boolean;
    setIsVisible: Function | null;
  }>({
    isVisible: false,
    setIsVisible: () => {},
  });
  const [showButtons, setShowButtons] = useState<{
    showButtonClose: boolean;
    showButtonOne: boolean;
    showButtonTwo: boolean;
  }>({
    showButtonClose: false,
    showButtonOne: false,
    showButtonTwo: false,
  });
  const [buttonOne, setButtonOne] = useState<{
    textButtonOne: string;
    actionButtonOne: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  }>({
    textButtonOne: "",
    actionButtonOne: () => {},
    isDisabled: false,
    isLoading: false,
  });
  const [buttonTwo, setButtonTwo] = useState<{
    textButtonTwo: string;
    actionButtonTwo: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  }>({
    textButtonTwo: "",
    actionButtonTwo: () => {},
    isDisabled: false,
    isLoading: false,
  });
  const [footer, setFooter] = useState<Object | null>(null);
  const [modalChildren, setModalChildren] = useState<any>(null);
  const [actionButtonClose, setActionButtonClose] = useState<Function>(
    () => null
  );
  const [noSizeLimit, setNoSizeLimit] = useState(false);

  const handleTitleChange = (title: string | Element) => {
    setTitle(title);
  };

  const handleVisibilityChange = (visibility: {
    isVisible: boolean;
    setIsVisible: Function | null;
  }) => {
    setVisibility(visibility);
  };

  const handleShowButtonsChange = (showButtons: {
    showButtonClose: boolean;
    showButtonOne: boolean;
    showButtonTwo: boolean;
  }) => {
    setShowButtons(showButtons);
  };

  const handleButtonOneChange = (buttonOne: {
    textButtonOne: string;
    actionButtonOne: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  }) => {
    setButtonOne(buttonOne);
  };

  const handleButtonTwoChange = (buttonTwo: {
    textButtonTwo: string;
    actionButtonTwo: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  }) => {
    setButtonTwo(buttonTwo);
  };

  const handleChildrenChange = (modalChildren: any) => {
    setModalChildren(modalChildren);
  };

  const handleActionButtonCloseChange = (actionButtonClose: Function) => {
    setActionButtonClose(() => actionButtonClose);
  };

  const handleNoSizeLimitChange = (noSizeLimit: boolean) => {
    setNoSizeLimit(noSizeLimit);
  };
  const handleFooterChange = (footer: Object | null) => {
    setFooter(footer);
  };

  const handleClose = () => {
    handleVisibilityChange({
      isVisible: false,
      setIsVisible: visibility.setIsVisible,
    });
  };

  const contextValue = useMemo(
    () => ({
      title,
      visibility,
      showButtons,
      buttonTwo,
      modalChildren,
      actionButtonClose,
      noSizeLimit,
      footer,
      handleTitleChange,
      handleVisibilityChange,
      handleShowButtonsChange,
      handleButtonOneChange,
      handleButtonTwoChange,
      handleFooterChange,
      handleChildrenChange,
      handleActionButtonCloseChange,
      handleNoSizeLimitChange,
      handleClose,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      title,
      visibility,
      showButtons,
      buttonTwo,
      modalChildren,
      actionButtonClose,
      noSizeLimit,
      footer,
    ]
  );

  return (
    <AcceptCancelModalContext.Provider value={contextValue}>
      {children}
    </AcceptCancelModalContext.Provider>
  );
};

export { AcceptCancelModalContext, AcceptCancelModalProvider };
