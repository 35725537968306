import React from "react";
// @ts-ignore
import { SelectInput, Icon } from "@viuti/recursos";
import style from "./SaleCommandRow.module.css";
import { TimesIcon } from "@Models/icons";
import { useAppDispatch } from "@Hooks/store";
import { removeSaleCommand } from "@ReduxService/states/currentSaleSlice";

// Definimos la interfaz para el objeto sale
interface SaleItem {
  reserveDetailId: number;
  CommandDetailId: number;
  localProductId: number | null;
  packageId: number | null;
  priceServiceId: number;
  quantity: number;
  price: number;
  unitPrice: number;
  description: string;
  employeeId: number;
  employeeName: string;
  packageItem: any | null;
  warehouseId: number | null;
  warehouseName: string | null;
}

interface SaleSummary {
  quantity: string;
  subtotal: string;
  discount: number;
  addition: number;
  modifierIsPercentage: boolean;
  total: string;
}

interface SaleCommand {
  reserveId: number;
  comandaId: number;
  itemsGroup: {
    index: string;
    item: SaleItem;
    summary: SaleSummary;
  }[];
}

interface Sale {
  index: string;
  command: SaleCommand;
}

export const SaleCommandRow = ({ sale }: { sale: Sale }) => {
  const dispatch = useAppDispatch();
  return (
    <div className={style.sales__container}>
      <button
        className={style.sales__container__body__sales__row__button__remove}
        onClick={() => dispatch(removeSaleCommand(sale.index))}
      >
        <Icon path={TimesIcon} size={10} color={"#d82121"} />
        Eliminar
      </button>
      {sale.command.itemsGroup.map((group) => (
        <div
          key={group.index}
          className={style.sales__container__body__sales__row}
        >
          <span className={style.sales__container__body__sales__row__column}>
            {group.item.description}
          </span>
          <span className={style.sales__container__body__sales__row__column}>
            {group.item?.warehouseName || "No aplica"}
          </span>
          <span className={style.sales__container__body__sales__row__column}>
            {group.item?.employeeName || "No aplica"}
          </span>
          <span className={style.sales__container__body__sales__row__column}>
            {group.item.quantity}
          </span>
          <span className={style.sales__container__body__sales__row__column}>
            {group.item.unitPrice.toFixed(2)}
          </span>
          <span className={style.sales__container__body__sales__row__column}>
            {group.item.price}
          </span>
        </div>
      ))}
    </div>
  );
};

// {sale.command.itemsGroup.map((group) => (
//   <div key={group.index} className={style.sales__container__body__sales__row__column}>
//     <span>{group.item.description}</span>
//     <span>{group.item.unitPrice.toFixed(2)}</span>
//     <span>{group.item.employeeName}</span>
//     {/* Aquí podrías agregar más detalles o funcionalidades, como un SelectInput */}
//   </div>
// ))}
