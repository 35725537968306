import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updatePackages } from "@ReduxService/states/saleConfigSlice";
import { getPackages_service } from "@Services/packages";
import { capitalizeFirstLetter } from "@Utilities/CapitalizeWords";

export const getPackages = async (dispatch: AppDispatch) => {
  const response: IResponse = await getPackages_service();

  if (response.status !== STATUS_OK) {
    return response;
  }

  const formattedResponse: IPackage[] = response.data.map(
    (packageItem: any) => {
      return {
        id: packageItem.packageId,
        name: packageItem.packageName,
        price: packageItem.value,
        igv: packageItem.tax || 0,
        condition: packageItem.condition,
        isValid: packageItem.validity,
        isAvailable: packageItem.available,
        conflictMessage: packageItem.conflictMessage,
        stock: packageItem.stock,
        products: packageItem.promotionProductItems?.map((product: any) => {
          return {
            // warehouseId: product.warehouse.warehouseId,
            id: product.productId,
            localId: product.localProductId,
            name: product.productName,
            // code: product.code,
            price: product.value,
            igv: product.tax,
            // stock: product.stock,
            // brand: {
            // 	id: product.brand.brandId,
            // 	name: product.brand.brandName,
            // },
            // subBrand: {
            // 	id: product.line?.lineId || null,
            // 	name: product.line?.lineName || null,
            // },
            // categories: product.category.map((category: any) => {
            // 	return {
            // 		id: category.categoryId,
            // 		name: category.categoryName,
            // 	};
            // }),
          };
        }),
        services: packageItem.serviceItems?.map((service: any) => {
          return {
            // id: service.serviceId,
            priceId: service.serviceId, // TODO: Cambiar serviceId por priceId en un futuro
            name: capitalizeFirstLetter(service.serviceName),
            price: service.value,
            igv: service.tax,
            total: service.price,
            // isFree: service.free === 1,
            availableEmployees: service.serviceEmployees.map(
              (employee: any) => ({
                id: employee.employeeId,
                name: capitalizeFirstLetter(
                  employee.employeeName.split(" ")[0]
                ),
                lastName: capitalizeFirstLetter(
                  employee.employeeName.split(" ")[1]
                ),
              })
            ),
          };
        }),
        paymentMethods: packageItem.meansPayment.map((paymentMethod: any) => {
          return {
            id: paymentMethod.categoryPaymentId,
            name: capitalizeFirstLetter(
              paymentMethod.categoryPaymentDescription
            ),
            subCategories: {
              id: paymentMethod.subCategoryPaymentId,
              name: capitalizeFirstLetter(
                paymentMethod.subCategoryPaymentDescription
              ),
            },
          };
        }),
      };
    }
  );

  dispatch(updatePackages(formattedResponse));

  return {
    status: STATUS_OK,
    message: "Paquetes obtenidos correctamente",
    data: formattedResponse,
  };
};
