import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { useParams } from "react-router-dom";
import { getAccountInfo } from "@Adapters/getAccountInfo.adapter";
import { getBillingDocuments } from "@Adapters/getBillingDocuments.adapter";
import { getPaymentMethods } from "@Adapters/getPaymentMethods.adapter";
import { getCompanyServices } from "@Adapters/getCompanyServices.adapter";
import { getLoungeEmployees } from "@Adapters/getLoungeEmployees.adapter";
import { getWarehouses } from "@Adapters/getWarehouses.adapter";
import { changeModalSavedSale } from "@Hooks/UpdateModalType/changeModalSavedSale/changeModalSavedSale";
import { changeModalCashCount } from "@Hooks/UpdateModalType/changeModalCashCount/changeModalCashCount";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";
import { CREATE_SALES_UUID } from "@Models/const/securityAccess";

const useFetchSalebox = (fetchResponses: {
  saleBoxes: {
    loading: boolean;
    error: boolean;
  };
}) => {
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const dispatch = useAppDispatch();
  const selectedSaleBox = useAppSelector((state) => state.selectedSaleBox);
  const { saleBoxId } = useParams<{ saleBoxId: string }>();

  const [criticalError, setCriticalError] = useState(null);

  useEffect(() => {
    if (
      fetchResponses.saleBoxes.loading === false &&
      fetchResponses.saleBoxes.error === false
    ) {
      localStorage.setItem("lastSaleboxVisited", saleBoxId);
      (async () => {
        const [
          _accountInfo,
          _billingDocuments,
          _paymentMethods,
          _companyServices,
          _loungeEmployees,
          _warehouses,
        ] = await Promise.all([
          getAccountInfo(dispatch),
          getBillingDocuments(dispatch, Number(saleBoxId)),
          getPaymentMethods(dispatch),
          getCompanyServices(dispatch),
          getLoungeEmployees(dispatch),
          getWarehouses(dispatch),
        ]);

        const hasError =
          !_accountInfo.isSuccessful ||
          !_billingDocuments.isSuccessful ||
          !_paymentMethods.isSuccessful ||
          !_companyServices.isSuccessful ||
          !_loungeEmployees.isSuccessful ||
          !_warehouses.isSuccessful;

        setCriticalError(hasError);

        if (localStorage.getItem("lastSaleboxSale") === saleBoxId) {
          const persistedState = localStorage.getItem("lastSavedSale");
          if (persistedState) changeModalSavedSale(modalContext);
        } else {
          localStorage.removeItem("lastSavedSale");
          localStorage.removeItem("lastSaleboxSale");
        }

        if (selectedSaleBox.needsCashCount) {
          //eslint-disable-next-line no-alert
          validateModuleAccess(CREATE_SALES_UUID, false) &&
            changeModalCashCount(modalContext, selectedSaleBox, true);
        }
      })();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResponses.saleBoxes.loading]);

  return criticalError;
};

export default useFetchSalebox;
