import React from "react";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import AddGiftcardsModal from "@Components/ui/organisms/AddGiftcardsModal/AddGiftcardsModal";

export const changeModalGiftcards = (
  modalContext: AcceptCancelModalContextProps
) => {
  const {
    buttonTwo,
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = <AddGiftcardsModal modalContext={modalContext} />;

  handleTitleChange("Agregar giftcards");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: true,
  });
  handleButtonTwoChange({
    ...buttonTwo,
    textButtonTwo: "Seleccionar",
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
