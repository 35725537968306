import React, { useEffect, useState } from "react";
import style from "./EditPriceModal.module.css";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
// @ts-ignore
import { LoadingSpinner, TextInput } from "@viuti/recursos";
import {
  ADDITION_TYPE,
  DISCOUNT_TYPE,
  PERCENTAGE_TYPE,
  SOLES_TYPE,
} from "@Models/const/priceModifier";
import { handleQuantityChange } from "./functions/handleQuantityChange";
import { handleQuantityBlur } from "./functions/handleQuantityBlur";
import {
  fixDecimal,
  formatToSoles,
  formatToPercentage,
} from "@Utilities/FormatsHandler";
import useEditPriceSubmit from "./hooks/useSubmit";
import { handleModifierTypeChange } from "./functions/handleModifierTypeChange";
import { handleIsPercentageChange } from "./functions/handleIsPercentageChange";
import { getItemNetPrice } from "@Adapters/getItemNetPrice.adapter";
import { useAppDispatch } from "@Hooks/store";
import { SaleTypeId } from "@Models/const/salesOptions";

interface IEditPriceModalProps {
  modalContext: AcceptCancelModalContextProps;
  sale: {
    appointmentIndex?: string;
    index?: string;
    item: ISaleItem;
    summary?: IItemSummary;
  };
  type: SaleTypeId;
}

interface IDiscount {
  id: string;
  origin: string;
  amount: number;
  isApplied: boolean;
}

const EditPriceModal = ({ modalContext, sale, type }: IEditPriceModalProps) => {
  const dispatch = useAppDispatch();
  const [summary, setSummary] = useState<IItemSummary>(sale.summary);
  const [modifierType, setModifierType] = useState(DISCOUNT_TYPE);
  const [backendSummary, setBackendSummary] = useState({
    grossPrice: 0,
    taxes: 0,
    subtotal: 0,
    discount: 0,
    total: 0,
  });
  const [isFetchingPrice, setIsFetchingPrice] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [discounts, setDiscounts] = useState<IDiscount[]>([
    {
      id: "1",
      origin: "Descuento por temporada",
      amount: 5,
      isApplied: true,
    },
    {
      id: "2",
      origin: "Cupón de cliente frecuente",
      amount: 3,
      isApplied: true,
    },
    { id: "3", origin: "Promoción especial", amount: 2, isApplied: false },
  ]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const itemPrice = sale.item.price + sale.item.igv;

    let lastDiscount = Math.abs(sale.summary.discount);
    let lastAddition = Math.abs(sale.summary.addition);

    if (sale.summary.modifierIsPercentage) {
      const discountPercentage = fixDecimal((lastDiscount / itemPrice) * 100);
      const additionPercentage = fixDecimal((lastAddition / itemPrice) * 100);

      lastDiscount = discountPercentage;
      lastAddition = additionPercentage;
    } else {
      lastDiscount = fixDecimal(lastDiscount);
      lastAddition = fixDecimal(lastAddition);
    }

    setSummary({
      ...sale.summary,
      subtotal: itemPrice,
      discount: lastDiscount,
      addition: lastAddition,
    });
  }, [sale]);

  const saleEditedDiscount = summary.discount || 0;
  const saleEditedAddition = summary.addition || 0;

  const modifierPercentage =
    (saleEditedAddition * summary.subtotal) / 100 -
    (saleEditedDiscount * summary.subtotal) / 100;

  const totalWithPercentage =
    Number(summary.subtotal) + Number(modifierPercentage);

  const modifierSoles = Number(saleEditedAddition) - Number(saleEditedDiscount);
  const totalWithSoles = Number(summary.subtotal) + Number(modifierSoles);

  useEffect(() => {
    // @ts-ignore
    setSummary({
      ...summary,
      total: summary.modifierIsPercentage
        ? parseFloat(String(totalWithPercentage)).toFixed(2)
        : parseFloat(String(totalWithSoles)).toFixed(2),
    } as IItemSummary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalWithPercentage, totalWithSoles, modifierSoles, modifierPercentage]);

  useEffect(() => {
    setIsSubmitDisabled(
      backendSummary.total < 0 || isFetchingPrice || errorMessage !== null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendSummary, isFetchingPrice, errorMessage, summary.quantity]);

  useEditPriceSubmit(
    modalContext,
    modifierType,
    sale,
    summary,
    {
      modifierSoles: modifierSoles,
      modifierPercentage: modifierPercentage,
    },
    backendSummary,
    type,
    isSubmitDisabled
  );

  const updateSummary = async () => {
    setIsFetchingPrice(true);
    const response = await getItemNetPrice(dispatch, sale, summary, type);
    if (response.isSuccessful) {
      setBackendSummary(response.data);
    }
    setIsFetchingPrice(false);
  };

  useEffect(() => {
    updateSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifierType, summary.modifierIsPercentage, sale]);

  // const handleDiscountToggle = (id: string) => {
  //   setDiscounts(
  //     discounts.map((discount) =>
  //       discount.id === id
  //         ? { ...discount, isApplied: !discount.isApplied }
  //         : discount
  //     )
  //   );
  // };

  // useEffect(() => {
  //   const totalDiscount = discounts
  //     .filter((d) => d.isApplied)
  //     .reduce((sum, d) => sum + d.amount, 0);

  //   // Actualizar el resumen con el nuevo descuento total
  //   setSummary((prev) => ({
  //     ...prev,
  //     discount: totalDiscount,
  //   }));

  //   // Actualizar el backend
  //   updateSummary();
  // }, [discounts]);

  useEffect(() => {
    modalContext.handleShowButtonsChange({
      showButtonClose: true,
      showButtonTwo: true,
    });
  }, [modalContext]);

  const handleQuantityChange = (e, summary, modifierType, setSummary) => {
    const input = e.target.value;

    // Validar que solo se permita un punto decimal y números
    const decimalRegex = /^\d*\.?\d{0,2}$/;
    if (!decimalRegex.test(input) && input !== "") {
      return;
    }

    let error = null;
    const value = parseFloat(input) || 0;

    if (modifierType === DISCOUNT_TYPE) {
      if (summary.modifierIsPercentage) {
        if (value > 100) {
          error = "El descuento no puede superar el 100%";
        }
      } else {
        if (value > summary.subtotal) {
          error = "El descuento no puede superar el precio total";
        }
      }
    } else if (modifierType === ADDITION_TYPE) {
      if (!summary.modifierIsPercentage && value > summary.subtotal) {
        error = "El aumento no puede superar el precio total";
      }
    }

    setErrorMessage(error);
    setSummary({
      ...summary,
      [modifierType === DISCOUNT_TYPE ? "discount" : "addition"]: input,
    });
  };

  return (
    <div className={style.body}>
      <div className={style.body__radio__group__container}>
        <span className={style.body__radio__group__label}>
          Tipo de modificación:
        </span>
        <div className={style.body__radio__group}>
          <input
            type="radio"
            name="modifierType"
            id="radio1"
            value={DISCOUNT_TYPE}
            onChange={(e) =>
              handleModifierTypeChange(
                e,
                {
                  type: modifierType,
                  setModifierType,
                },
                {
                  value: summary,
                  setSummary,
                }
              )
            }
            checked={modifierType === DISCOUNT_TYPE}
            defaultChecked
          />
          <label htmlFor="radio1">Descuento</label>
        </div>
        {/* <div className={style.body__radio__group}>
          <input
            type="radio"
            name="modifierType"
            id="radio2"
            value={ADDITION_TYPE}
            onChange={(e) =>
              handleModifierTypeChange(
                e,
                {
                  type: modifierType,
                  setModifierType,
                },
                {
                  value: summary,
                  setSummary,
                }
              )
            }
            checked={modifierType === ADDITION_TYPE}
          />
          <label htmlFor="radio2">Aumento</label>
        </div> */}
      </div>
      <div className={style.body__radio__group__container}>
        <span className={style.body__radio__group__label}>
          Modificación expresada en:
        </span>
        <div className={style.body__radio__group}>
          <input
            type="radio"
            name="modifierIsPercentage"
            id="radio3"
            value={SOLES_TYPE}
            onChange={(e) =>
              handleIsPercentageChange(
                e,
                {
                  type: modifierType,
                  setModifierType,
                },
                {
                  value: summary,
                  setSummary,
                },
                {
                  totalWithPercentage,
                  totalWithSoles,
                }
              )
            }
            checked={!summary.modifierIsPercentage}
          />
          <label htmlFor="radio3">Soles</label>
        </div>
        <div className={style.body__radio__group}>
          <input
            type="radio"
            name="modifierIsPercentage"
            id="radio4"
            value={PERCENTAGE_TYPE}
            onChange={(e) =>
              handleIsPercentageChange(
                e,
                {
                  type: modifierType,
                  setModifierType,
                },
                {
                  value: summary,
                  setSummary,
                },
                {
                  totalWithPercentage,
                  totalWithSoles,
                }
              )
            }
            checked={summary.modifierIsPercentage}
          />
          <label htmlFor="radio4">Porcentaje</label>
        </div>
      </div>
      <div className={style.body__input__container}>
        <TextInput
          label="Cantidad"
          value={
            modifierType === DISCOUNT_TYPE ? summary.discount : summary.addition
          }
          flex={1}
          placeholder="100.00"
          inputProps={{
            onClick: (e: any) => {
              // @ts-ignore
              setSummary({
                ...summary,
                discount: "",
                addition: "",
              } as IItemSummary);
            },
            onChange: (e) =>
              handleQuantityChange(e, summary, modifierType, setSummary),
            onBlur: (e: any) => {
              handleQuantityBlur(
                e,
                dispatch,
                sale,
                summary,
                modifierType,
                setSummary
              );
              updateSummary();
            },
            max: summary.modifierIsPercentage ? 100 : undefined,
            type: "text",
            inputMode: "decimal",
            pattern: "[0-9]*\\.?[0-9]*",
          }}
          prefix={modifierType === DISCOUNT_TYPE ? "-" : "+"}
          suffix={summary.modifierIsPercentage ? "%" : "S/"}
          width="100%"
          error={errorMessage}
          touched={errorMessage}
        />
        <span className={style.body__input__spinner__container}>
          <LoadingSpinner isVisible={isFetchingPrice} size={18} />
        </span>
      </div>
      <section className={style.body__summary__container}>
        <div
          className={`${style.body__summary__item} ${style.body__summary__item_first}`}
        >
          <span className={style.body__summary__item__label}>
            Cantidad de items
          </span>
          <span className={style.body__summary__item__value}>
            x{summary.quantity} uds.
          </span>
        </div>
        <div
          className={`${style.body__summary__item} ${style.body__summary__item_first}`}
        >
          <span className={style.body__summary__item__label}>Precio bruto</span>
          <span className={style.body__summary__item__value}>
            {formatToSoles(backendSummary.grossPrice)}
          </span>
        </div>
        <div
          className={`${style.body__summary__item} ${style.body__summary__item_first}`}
        >
          <span className={style.body__summary__item__label}>Descuentos</span>
          <span className={style.body__summary__item__value}>
            {formatToSoles(backendSummary.discount)}
          </span>
        </div>
        {/* <div className={style.body__discounts__container}>
          {discounts.map((discount) => (
            <div
              key={discount.id}
              className={`${style.body__discount__item} ${
                discount.isApplied
                  ? style.body__discount__item_applied
                  : style.body__discount__item_not_applied
              }`}
            >
              <input
                type="checkbox"
                checked={discount.isApplied}
                onChange={() => handleDiscountToggle(discount.id)}
                className={style.body__discount__checkbox}
              />
              <span className={style.body__discount__origin}>
                {discount.origin}
              </span>
              <span className={style.body__discount__amount}>
                {formatToPercentage(discount.amount)}
              </span>
            </div>
          ))}
        </div> */}
        <div className={style.body__summary__item}>
          <span className={style.body__summary__item__label}>Total</span>

          <span className={style.body__summary__item__value}>
            {formatToSoles(backendSummary.total)}
          </span>
        </div>
        <div className={style.body__summary__item}>
          <span
            className={`${style.body__summary__item__info__label} 
            ${style.body__summary__item__info__label_small}`}
          >
            Impuestos (IGV)
          </span>

          <span className={style.body__summary__item__info__value}>
            {formatToSoles(backendSummary.taxes)}
          </span>
        </div>
        <div className={style.body__summary__item}>
          <span
            className={`${style.body__summary__item__info__label} 
            ${style.body__summary__item__info__label_small}`}
          >
            Subtotal
          </span>

          <span className={style.body__summary__item__info__value}>
            {formatToSoles(backendSummary.subtotal)}
          </span>
        </div>
      </section>
    </div>
  );
};

export default EditPriceModal;
