// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sV3GqSFj28SulOLx0iq1 {
  position: relative;
}

.igpz3se2zRcWVlan_vRz {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  gap: 6px;

  position: sticky;
  left: 0px;
  color: #45348e;
  padding-top: 12px;
}

.p171cUxb8akvqGl_TNKL {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/SaleTable/SaleTable.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;;EAER,gBAAgB;EAChB,SAAS;EACT,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".sales__container__body__sales {\n  position: relative;\n}\n\n.sales__container__body__category {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  white-space: nowrap;\n  gap: 6px;\n\n  position: sticky;\n  left: 0px;\n  color: #45348e;\n  padding-top: 12px;\n}\n\n.sales__container__body__sales__container {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sales__container__body__sales": `sV3GqSFj28SulOLx0iq1`,
	"sales__container__body__category": `igpz3se2zRcWVlan_vRz`,
	"sales__container__body__sales__container": `p171cUxb8akvqGl_TNKL`
};
export default ___CSS_LOADER_EXPORT___;
