// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YTzSFcok6BE8INcBIzok {
  display: flex;
  flex-direction: column;

  gap: 20px;
  min-width: 580px;
  min-height: 400px;
  max-height: 485px;
  overflow-y: auto;
}

@media screen and (width < 746px) {
  .YTzSFcok6BE8INcBIzok {
    min-width: 100%;
  }
}

.TByg2vQvAoMStnR01mz7 {
  text-align: center;
  font: 800 1rem "Mulish", sans-serif;
  color: #45348e;
}

.egwGYARI12eWeYzLCRzM {
  font: 600 0.875rem "Mulish", sans-serif;
}

.PfKByUctNSzNoJAFeRt9 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff82;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/AddProductsModal/AddProductsModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,cAAc;AAChB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;;EAEX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,2BAA2B;AAC7B","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n\n  gap: 20px;\n  min-width: 580px;\n  min-height: 400px;\n  max-height: 485px;\n  overflow-y: auto;\n}\n\n@media screen and (width < 746px) {\n  .body {\n    min-width: 100%;\n  }\n}\n\n.empty__message {\n  text-align: center;\n  font: 800 1rem \"Mulish\", sans-serif;\n  color: #45348e;\n}\n\n.empty__submessage {\n  font: 600 0.875rem \"Mulish\", sans-serif;\n}\n\n.loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-color: #ffffff82;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `YTzSFcok6BE8INcBIzok`,
	"empty__message": `TByg2vQvAoMStnR01mz7`,
	"empty__submessage": `egwGYARI12eWeYzLCRzM`,
	"loading": `PfKByUctNSzNoJAFeRt9`
};
export default ___CSS_LOADER_EXPORT___;
