import React from "react";
import style from "./PaymentMethodsChart.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useAppSelector } from "@Hooks/store";
import { capitalizeFirstLetter } from "@Utilities/CapitalizeWords";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    /* tooltip: {
			enabled: true,
			backgroundColor: '#000',
			titleColor: '#fff',
			bodyColor: '#fff',
			titleFont: {
				size: 16,
			},
			bodyFont: {
				size: 14,
			},
			callbacks: {
				label: (context) => {
					const label = context.dataset.label || '';
					if (context.parsed.y !== null) {
						label.concat(`: ${context.parsed.y}`);
					}
					return label;
				},
			},
		}, */
  },
};

const PaymentMehodChart = () => {
  const paymentMethods =
    useAppSelector((state) => state.saleboxReports.report.payments) || [];

  const labels = paymentMethods.map((method) => {
    if (method.subCategory === "efectivo") {
      return "Efectivo";
    } else {
      return `${capitalizeFirstLetter(
        method.subCategory
      )} (${method.category.toLowerCase()})`;
    }
  });

  function colorFn(ind) {
    const colors = ["#572F90", "#B554CC", "#937cf4", "#e8e3ff"];
    return colors[ind % colors.length];
  }

  const data = {
    labels,
    datasets: [
      {
        label: "",
        showLabel: false,
        data: paymentMethods.map((method) => method.amount),
        backgroundColor: labels.map((label, ind) => colorFn(ind)),
        borderRadius: 10,
        maxWidth: "10px",
      },
    ],
  };

  return (
    <article className={style.container}>
      <h4 className={style.label}>Medios de pago</h4>
      <div className={style.content}>
        <Bar options={options} data={data} updateMode="resize" />
      </div>
    </article>
  );
};

export default PaymentMehodChart;
