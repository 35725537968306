import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { updateClientAppointments } from "./saleConfigSlice";
import { createAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = {
  billingDocument: null,
  paymentMethods: null,
  client: null,
  genericClient: null,
  summary: {
    subtotal: 0,
    prepayment: 0,
    discount: 0,
    discountGiftcard: 0,
    igv: 0,
    icbp: 0,
    total: 0,
  },
  finishedSaleResponse: {
    status: 0,
    message: "",
    data: null,
  },
  sales: {
    services: [],
    products: [],
    packages: [],
    appointments: [],
    commands: [],
    giftcards: [],
  },
  giftcardPayments: [],
  isFreeSale: false,
};

const initialState: ICurrentSale = (() => {
  return DEFAULT_STATE;
})();

export const currentSaleSlice = createSlice({
  name: "currentSale",
  initialState,
  reducers: {
    updateInitialState: (state, action: PayloadAction<ICurrentSale>) => {
      return action.payload;
    },
    resetSale: (state) => {
      state.billingDocument = null;
      state.paymentMethods = null;
      state.client = null;
      state.genericClient = null;
      state.finishedSaleResponse = {
        status: 0,
        message: "",
        data: null,
      };
      state.sales = {
        services: [],
        products: [],
        packages: [],
        appointments: [],
        commands: [],
        giftcards: [],
      };
      state.isFreeSale = false;
    },
    resetClientSales: (state) => {
      state.client = null;
      state.genericClient = null;
      state.sales = {
        services: [],
        products: [],
        packages: [],
        appointments: [],
        commands: [],
        giftcards: [],
      };
    },
    updateSaleBillingDocument: (
      state,
      action: PayloadAction<IBillingDocument>
    ) => {
      state.billingDocument = action.payload;
    },
    updateSalePaymentMethods: (
      state,
      action: PayloadAction<ISalePaymentMethod[]>
    ) => {
      state.paymentMethods = action.payload;
    },
    updateGenericClient: (state, action: PayloadAction<IClient>) => {
      state.genericClient = action.payload;
      if (state.client === null && state.genericClient === null) {
        state.sales = {
          services: [],
          products: [],
          packages: [],
          appointments: [],
          commands: [],
          giftcards: [],
        };
      }
    },
    updateClient: (state, action: PayloadAction<IClient>) => {
      state.client = action.payload;
      updateClientAppointments([]);
    },
    updateSummmary: (state, action: PayloadAction<ISaleSummary>) => {
      state.summary = action.payload;
    },
    updateFinishedSaleResponse: (
      state,
      action: PayloadAction<IFinishedSaleResponse>
    ) => {
      state.finishedSaleResponse = action.payload;
    },
    updateGiftcardDiscount: (state, action: PayloadAction<number>) => {
      state.summary.discountGiftcard = action.payload;
      state.summary.total = state.summary.total - action.payload;
    },
    updateIsFreeSale: (state, action) => {
      state.isFreeSale = action.payload;
    },
    // 🟠 NOTE: The following actions refers to service sales
    addSalesServices: (state, action: PayloadAction<ISaleService[]>) => {
      action.payload.forEach((service) => {
        const adaptedService = {
          //index: state.sales.services[state.sales.services.length - 1].index + 1,
          index: uuidv4(),
          item: service.item,
          employeeId: service.employeeId,
          summary: {
            quantity: "1",
            subtotal: parseFloat(
              (service.item.price + service.item.igv).toString()
            ).toFixed(2) as any,
            discount: 0,
            addition: 0,
            modifierIsPercentage: false,
            total: service.item.price + service.item.igv,
          },
        };
        state.sales.services.push(adaptedService);
      });
    },
    updateSaleServiceSummary: (
      state,
      action: PayloadAction<{ index: string; summary: IItemSummary }>
    ) => {
      state.sales.services.find(
        (service) => service.index === action.payload.index
      ).summary = action.payload.summary;
    },
    updateSaleServiceEmployee: (
      state,
      action: PayloadAction<{ index: string; employeeId: number }>
    ) => {
      const service = state.sales.services.find(
        (s) => s.index === action.payload.index
      );
      if (service) {
        service.employeeId = action.payload.employeeId;
      }
    },
    removeSaleService: (state, action: PayloadAction<string>) => {
      const newServices = state.sales.services.filter(
        (service) => service.index !== action.payload
      );
      state.sales.services = newServices;
    },
    // 🔵 NOTE: The following actions refers to product sales
    addSaleProducts: (state, action: PayloadAction<ISaleProduct[]>) => {
      action.payload.forEach((product) => {
        const adaptedProduct = {
          index: uuidv4(),
          item: product.item,
          employeeId: product.employeeId,
          summary: {
            quantity: "1",
            subtotal: parseFloat(
              (product.item.price + product.item.igv).toString()
            ).toFixed(2) as any,
            discount: 0,
            addition: 0,
            modifierIsPercentage: false,
            total: product.item.price + product.item.igv,
          },
        };
        state.sales.products.push(adaptedProduct);
      });
    },
    updateSaleProductQuantity: (
      state,
      action: PayloadAction<{
        index: string;
        quantity: string;
        subtotal: number;
        total: number;
      }>
    ) => {
      const findProductSale = state.sales.products.find(
        (product) => product.index === action.payload.index
      );
      findProductSale.summary.quantity = action.payload.quantity;
      findProductSale.summary.subtotal = action.payload.subtotal;
      findProductSale.summary.total = action.payload.total;
    },
    updateSaleProductSummary: (
      state,
      action: PayloadAction<{ index: string; summary: IItemSummary }>
    ) => {
      state.sales.products.find(
        (product) => product.index === action.payload.index
      ).summary = action.payload.summary;
    },
    removeSaleProduct: (state, action: PayloadAction<string>) => {
      const newProducts = state.sales.products.filter(
        (product) => product.index !== action.payload
      );
      state.sales.products = newProducts;
    },
    updateSaleProductEmployee: (
      state,
      action: PayloadAction<{ index: string; employeeId: number }>
    ) => {
      const product = state.sales.products.find(
        (p) => p.index === action.payload.index
      );
      if (product) {
        product.employeeId = action.payload.employeeId;
      }
    },
    // 🟣 NOTE: The following actions refers to appointment sales
    addSaleAppointments: (state, action: PayloadAction<ISaleAppointment[]>) => {
      state.sales.appointments = action.payload;
    },
    updateSaleAppointmentServiceEmployee: (
      state,
      action: PayloadAction<{
        appointmentIndex: string;
        serviceIndex: string;
        employeeId: number;
      }>
    ) => {
      const appointment = state.sales.appointments.find(
        (appointment) => appointment.index === action.payload.appointmentIndex
      );
      if (appointment) {
        const service = appointment.appointment.services.find(
          (service) => service.index === action.payload.serviceIndex
        );
        if (service) {
          service.employeeId = action.payload.employeeId;
        }
      }
    },
    updateSaleAppointmentSummary: (
      state,
      action: PayloadAction<{
        appointmentIndex: string;
        serviceIndex: string;
        summary: any;
      }>
    ) => {
      const appointment = state.sales.appointments.find(
        (appointment) => appointment.index === action.payload.appointmentIndex
      );
      if (appointment) {
        const service = appointment.appointment.services.find(
          (service) => service.index === action.payload.serviceIndex
        );
        if (service) {
          service.summary = action.payload.summary;
        }
      }
    },
    updateCommands: (state, action: PayloadAction<ISaleCommand[]>) => {
      state.sales.commands = action.payload;
    },
    removeSaleAppointment: (state, action: PayloadAction<string>) => {
      const newAppointments = state.sales.appointments.filter(
        (appointment) => appointment.index !== action.payload
      );
      state.sales.appointments = newAppointments;
    },
    removeSaleCommand: (state, action: PayloadAction<string>) => {
      state.sales.commands = state.sales.commands.filter(
        (command) => command.index !== action.payload
      );
    },
    // 🟣 NOTE: The following actions refers to package sales
    addSalePackages: (state, action: PayloadAction<ISalePackage[]>) => {
      action.payload.forEach((packageSale) => {
        const existingPackage = state.sales.packages.find(
          (pkg) => pkg.item.id === packageSale.item.id
        );

        if (existingPackage) {
          // Si el paquete ya existe, incrementamos la cantidad
          const currentQuantity = parseInt(existingPackage.summary.quantity);
          existingPackage.summary.quantity = (currentQuantity + 1).toString();
          existingPackage.summary.subtotal = parseFloat(
            (
              (currentQuantity + 1) *
              (packageSale.item.price + packageSale.item.igv)
            ).toFixed(2)
          );
          existingPackage.summary.total = existingPackage.summary.subtotal;
        } else {
          // Si es un nuevo paquete, lo añadimos
          const adaptedPackage = {
            index: uuidv4(),
            item: packageSale.item,
            summary: {
              quantity: "1",
              subtotal: parseFloat(
                (packageSale.item.price + packageSale.item.igv).toString()
              ).toFixed(2) as any,
              discount: 0,
              addition: 0,
              modifierIsPercentage: false,
              total: packageSale.item.price + packageSale.item.igv,
            },
            products: [],
            services: [],
          };
          state.sales.packages.push(adaptedPackage);
        }
      });
    },
    removeSalePackage: (state, action: PayloadAction<string>) => {
      const newPackages = state.sales.packages.filter(
        (pkg) => pkg.index !== action.payload
      );
      state.sales.packages = newPackages;
    },
    updateSalePackageQuantity: (
      state,
      action: PayloadAction<{
        index: string;
        quantity: string;
        subtotal: number;
        total: number;
      }>
    ) => {
      const packageToUpdate = state.sales.packages.find(
        (pkg) => pkg.index === action.payload.index
      );
      if (packageToUpdate) {
        packageToUpdate.summary.quantity = action.payload.quantity;
        packageToUpdate.summary.subtotal = action.payload.subtotal;
        packageToUpdate.summary.total = action.payload.total;
      }
    },
    updateSalePackageEmployee: (
      state,
      action: PayloadAction<{
        packageIndex: string;
        itemId: number;
        isService: boolean;
        employeeId: number;
      }>
    ) => {
      const packageToUpdate = state.sales.packages.find(
        (pkg) => pkg.index === action.payload.packageIndex
      );

      if (packageToUpdate) {
        if (action.payload.isService) {
          // Actualizar empleado para servicio
          const serviceIndex = packageToUpdate.services.findIndex(
            (s) => s.idService === action.payload.itemId
          );

          if (serviceIndex >= 0) {
            packageToUpdate.services[serviceIndex].employeeId =
              action.payload.employeeId;
          } else {
            packageToUpdate.services.push({
              idService: action.payload.itemId,
              employeeId: action.payload.employeeId,
              idPackage: packageToUpdate.item.id,
            });
          }
        } else {
          // Actualizar empleado para producto
          const productIndex = packageToUpdate.products.findIndex(
            (p) => p.idProduct === action.payload.itemId
          );

          if (productIndex >= 0) {
            packageToUpdate.products[productIndex].employeeId =
              action.payload.employeeId;
          } else {
            packageToUpdate.products.push({
              idProduct: action.payload.itemId,
              employeeId: action.payload.employeeId,
              idPackage: packageToUpdate.item.id,
            });
          }
        }
      }
    },
    addSaleGiftcards: (state, action) => {
      const currentEmployeeId =
        JSON.parse(localStorage.getItem("account_info") || "{}").employee?.id ||
        "";

      const newGiftcards = action.payload.map((giftcard) => ({
        index: uuidv4(),
        item: giftcard,
        employeeId: currentEmployeeId,
        summary: {
          quantity: 1,
          subtotal: giftcard.price,
          total: giftcard.price,
          discount: 0,
          addition: 0,
        },
      }));
      state.sales.giftcards.push(...newGiftcards);
    },
    removeSaleGiftcard: (state, action: PayloadAction<string>) => {
      state.sales.giftcards = state.sales.giftcards.filter(
        (giftcard) => giftcard.index !== action.payload
      );
    },
    updateSaleGiftcardQuantity: (
      state,
      action: PayloadAction<{
        index: string;
        quantity: string;
        subtotal: number;
        total: number;
      }>
    ) => {
      const findGiftcard = state.sales.giftcards.find(
        (giftcard) => giftcard.index === action.payload.index
      );
      if (findGiftcard) {
        findGiftcard.summary.quantity = action.payload.quantity;
        findGiftcard.summary.subtotal = action.payload.subtotal;
        findGiftcard.summary.total = action.payload.total;
      }
    },
    updateSaleGiftcardEmployee: (state, action) => {
      const findGiftcard = state.sales.giftcards.find(
        (giftcard) => giftcard.index === action.payload.index
      );
      if (findGiftcard) {
        findGiftcard.employeeId = action.payload.employeeId;
      }
    },
    // Añadir un nuevo giftcard payment
    addGiftcardPayment: (state) => {
      state.giftcardPayments.push({
        giftcardCode: "",
        amount: "",
        giftcardId: undefined,
      });
    },
    // Actualizar un giftcard payment existente
    updateGiftcardPayment: (
      state,
      action: PayloadAction<{
        index: number;
        payment: {
          giftcardCode?: string;
          amount?: string;
          giftcardId?: number;
        };
      }>
    ) => {
      const { index, payment } = action.payload;
      state.giftcardPayments[index] = {
        ...state.giftcardPayments[index],
        ...payment,
      };
    },
    // Eliminar un giftcard payment
    removeGiftcardPayment: (state, action: PayloadAction<number>) => {
      state.giftcardPayments = state.giftcardPayments.filter(
        (_, index) => index !== action.payload
      );
    },
  },
});

export const {
  updateInitialState,
  resetSale,
  resetClientSales,
  updateSaleBillingDocument,
  updateSalePaymentMethods,
  updateClient,
  updateGenericClient,
  updateSummmary,
  updateFinishedSaleResponse,
  addSalesServices,
  updateSaleServiceSummary,
  updateSaleServiceEmployee,
  removeSaleService,
  updateCommands,
  addSaleProducts,
  updateSaleProductQuantity,
  updateSaleProductSummary,
  removeSaleProduct,
  updateSaleProductEmployee,
  addSaleAppointments,
  updateSaleAppointmentServiceEmployee,
  updateSaleAppointmentSummary,
  removeSaleAppointment,
  addSalePackages,
  updateSalePackageQuantity,
  removeSalePackage,
  updateSalePackageEmployee,
  removeSaleCommand,
  addSaleGiftcards,
  removeSaleGiftcard,
  updateSaleGiftcardQuantity,
  updateSaleGiftcardEmployee,
  updateGiftcardDiscount,
  addGiftcardPayment,
  updateGiftcardPayment,
  removeGiftcardPayment,
  updateIsFreeSale,
} = currentSaleSlice.actions;

export default currentSaleSlice.reducer;
