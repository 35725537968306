import { getSaleBoxes } from "@Adapters/getSaleBoxes.adapter";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const useGetSaleBoxesOptions = (dispatch) => {
  const [boxes, setBoxes] = React.useState([]);
  const { saleBoxId } = useParams();
  useEffect(() => {
    (async () => {
      // Se obtienen las cajas de venta
      const boxes = await getSaleBoxes(dispatch);
      if (boxes.status === 200) {
        // se adaptan las cajas de venta para el select
        const adaptedBoxes = boxes.data.map((box) => {
          return {
            name:
              box.name.length > 17 ? box.name.slice(0, 17) + "..." : box.name,
            value: box.id,
          };
        });
        // Se setean las cajas de venta
        setBoxes(adaptedBoxes);
      } else {
        updateNotification({
          message: "Hubo un error al obtener las cajas. Intentalo de nuevo.",
          status: "400",
          toastTimeDuration: 3000,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { boxes, saleBoxId };
};

export default useGetSaleBoxesOptions;
