// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EDD1fdxJ29_rooHZ57i3 {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  color: #4d4d4d;
  gap: 0.5rem;
  font: 800 clamp(0.6rem, 0.88rem, 0.9rem) "Mulish", sans-serif;
  white-space: nowrap;
  transition: all 150ms ease-in-out;
  border: 1px solid #c4c4c4;

  &:hover {
    background-color: #ececec;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/TertiaryButton/TertiaryButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,cAAc;EACd,WAAW;EACX,6DAA6D;EAC7D,mBAAmB;EACnB,iCAAiC;EACjC,yBAAyB;;EAEzB;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".button {\n  position: relative;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 8px 16px;\n  border-radius: 8px;\n  background-color: #fff;\n  cursor: pointer;\n  color: #4d4d4d;\n  gap: 0.5rem;\n  font: 800 clamp(0.6rem, 0.88rem, 0.9rem) \"Mulish\", sans-serif;\n  white-space: nowrap;\n  transition: all 150ms ease-in-out;\n  border: 1px solid #c4c4c4;\n\n  &:hover {\n    background-color: #ececec;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `EDD1fdxJ29_rooHZ57i3`
};
export default ___CSS_LOADER_EXPORT___;
