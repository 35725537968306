import React, { useEffect, useState } from "react";
import style from "./AddSaleProductRow.module.css";
// @ts-ignore
import { Icon, SearchWithColoredBorder, SelectInput } from "@viuti/recursos";
import { AddIcon, TimesIcon } from "@Models/icons";
import { useAppSelector } from "@Hooks/store";

const AddSaleProductRow = ({
  saleIndex,
  values,
  setValues,
  loungeEmployees,
  selectedWarehouseId,
}) => {
  const selectedWarehouse = useAppSelector((state) =>
    state.saleConfig.warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouseId
    )
  );
  const [searchProductsValue, setSearchProductsValue] = useState("");
  const [productsResults, setProductsResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const currentValue: {
    item: IProduct;
    employeeId: number | string;
  } = values[saleIndex];
  const availableEmployees = loungeEmployees?.map((employee) => ({
    value: employee.id,
    name: `${employee.name} ${employee.lastName}`,
  }));
  const renderResults: IProduct[] =
    currentValue.item?.name === ""
      ? selectedWarehouse?.products
      : productsResults;

  const handleSetProductSearch = (value: string) => {
    const updatedValues = [...values];
    updatedValues[saleIndex] = {
      item: { name: value } as IProduct,
      employee: "",
    };
    setValues(updatedValues);
  };

  useEffect(() => {
    if (searchProductsValue === "") {
      setShowSearchResults(false);
    } else {
      setShowSearchResults(true);
    }
  }, [searchProductsValue]);

  const handleProductSearch = (value: string) => {
    setSearchProductsValue(value);
    handleSetProductSearch(value);

    if (currentValue.item?.name !== value) {
      const updatedValues = [...values];
      updatedValues[saleIndex] = {
        item: { name: "" } as IProduct,
        employee: "",
      };
      setValues(updatedValues);
    }

    const results = selectedWarehouse.products?.filter((product) => {
      return product.name.toLowerCase().includes(value.toLowerCase());
    });

    setProductsResults(results);
    setShowSearchResults(true);
  };

  const handleSelectProduct = (item: IProduct, optionIndex: number) => {
    setSearchProductsValue(item.name);

    const updatedValues = [...values];
    updatedValues[optionIndex] = { item, employee: "" };

    setValues(updatedValues);
    setShowSearchResults(false);
  };

  const handleEmployeeChange = (e: any, optionIndex: number) => {
    const newValues = [...values];
    newValues[optionIndex].employeeId = e.target.value;
    setValues(newValues);
  };

  const handleAddProduct = () => {
    const newValues = [...values];
    newValues.unshift(currentValue);
    newValues[saleIndex] = { product: {} as IProduct, employee: "" };

    setSearchProductsValue("");
    setProductsResults([]);
    setShowSearchResults(true);

    setValues(newValues);
  };

  useEffect(() => {
    setSearchProductsValue("");
    setProductsResults([]);
  }, [selectedWarehouseId]);

  return (
    <div className={style.form__row}>
      <span className={style.form__row__input_container}>
        <SearchWithColoredBorder
          label="Producto"
          value={currentValue.item?.name || searchProductsValue}
          placeholder={"Buscar producto"}
          setValue={handleProductSearch}
          allData={renderResults}
          onClickResult={(product) => handleSelectProduct(product, saleIndex)}
          showResults={showSearchResults}
          searchBy={["name", "code"]}
          clearInput={false}
          required
        />
      </span>
      <span className={style.form__row__input_container}>
        <SelectInput
          label="Empleado responsable"
          value={currentValue.employeeId}
          handleChange={(e) => handleEmployeeChange(e, saleIndex)}
          items={availableEmployees}
          required
          disabled={!availableEmployees}
        />
      </span>
      {saleIndex === 0 ? (
        <span
          className={`${style.form__row__input__icon} ${
            !currentValue.employeeId
              ? style.form__row__input__icon_disabled
              : ""
          }`}
        >
          <button
            onClick={handleAddProduct}
            disabled={!currentValue.employeeId}
          >
            <Icon path={AddIcon} size={15} color={"#382b75"} />
          </button>
        </span>
      ) : (
        <span
          className={`${style.form__row__input__icon} ${style.form__row__input__icon_delete}`}
        >
          <button
            onClick={() =>
              setValues(values.filter((_, index) => index !== saleIndex))
            }
          >
            <Icon path={TimesIcon} size={15} color={"#ba1a1a"} />
          </button>
        </span>
      )}
    </div>
  );
};

export default AddSaleProductRow;
