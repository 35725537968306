import React from "react";
import styles from "./ChangeSaleBoxModal.module.css";
// @ts-ignore
import { IconWrapper } from "@viuti/recursos";
import { HiLockClosed } from "react-icons/hi2";
import { changeModalCashCount } from "@Hooks/UpdateModalType/changeModalCashCount/changeModalCashCount";
import { changeModalSalebox } from "@Hooks/UpdateModalType/changeModalSalebox/changeModalSalebox";
import { changeModalDowngrade } from "@Hooks/UpdateModalType/changeModalDowngrade/changeModalDowngrade";

export const ChangeSaleBoxModal = ({
  modalContext,
  dispatch,
  selectedSaleBox,
  saleBoxesList,
}) => {
  const handleSaleBoxClick = (saleBox: ISaleBox) => {
    if (saleBox.isDowngraded) {
      changeModalDowngrade(modalContext);
      return;
    }

    if (saleBox.needsCashCount) {
      changeModalCashCount(modalContext, saleBox, false);
    } else {
      changeModalSalebox(saleBox, modalContext, dispatch, true);
    }
  };

  return (
    <ul className={`${styles.changeCashregisterButton__PopUp}`}>
      {saleBoxesList?.map((saleBox: ISaleBox) => {
        let statusText = "";
        if (saleBox.id === selectedSaleBox.id) {
          statusText = "Seleccionada";
        } else if (saleBox.isOpen) {
          statusText = "Abierta";
        } else {
          statusText = "Cerrada";
        }
        return (
          <button
            key={saleBox.id}
            className={`${styles.changeCashregisterButton__PopUp__Element} ${
              saleBox.isDowngraded
                ? styles.changeCashregisterButton__PopUp__Element__Disabled
                : ""
            }`}
            onClick={() => handleSaleBoxClick(saleBox)}
            disabled={saleBox.id === selectedSaleBox.id}
          >
            <div
              className={styles.changeCashregisterButton__PopUp__Element__Name}
            >
              {saleBox.name}
            </div>

            <div
              className={
                styles.changeCashregisterButton__PopUp__Element__Status
              }
            >
              <span
                className={`${
                  saleBox.isOpen
                    ? styles.changeCashregisterButton__PopUp__Element__Status__Open
                    : styles.changeCashregisterButton__PopUp__Element__Status__Closed
                } ${
                  saleBox.id === selectedSaleBox.id
                    ? styles.changeCashregisterButton__PopUp__Element__Status__Selected
                    : ""
                }`}
              />
              {statusText}
              {saleBox.isDowngraded && (
                <span
                  className={
                    styles.changeCashregisterButton__PopUp__Element__Status__DowngradeIcon
                  }
                >
                  <IconWrapper icon={HiLockClosed} size={12} color="#937cf4" />
                </span>
              )}
            </div>
          </button>
        );
      })}
    </ul>
  );
};
