// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k3WRqP0rNygDwPmsv2pp {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  padding: 6px 8px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.LUTOlWFt7mBZS0CKOWFs {
  background-color: #f4feff;
  border-color: #00bcd4;

  &:hover {
    background-color: #e7fdff;
  }
}

.Zb_uczC9PjLw5qtZsfND {
  background-color: #fffcf4;
  border-color: #ffc107;

  &:hover {
    background-color: #fff8e7;
  }
}

.IhEke8bHpzlQNXl1jNDk {
  background-color: #fff7f4;
  border-color: #ff5722;

  &:hover {
    background-color: #ffebe4;
  }
}

._2zcZq41ecsViS52yil9 {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 600 0.8rem "Mulish", sans-serif;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/BillingDocumentsBullet/BillingDocumentsBullet.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;;EAE9B,WAAW;EACX,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;;EAErB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;;EAErB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;;EAErB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qCAAqC;EACrC,QAAQ;AACV","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  width: 100%;\n  border-radius: 16px;\n  border: 1px solid #e0e0e0;\n  padding: 6px 8px;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n}\n\n.container__ok {\n  background-color: #f4feff;\n  border-color: #00bcd4;\n\n  &:hover {\n    background-color: #e7fdff;\n  }\n}\n\n.container__warning {\n  background-color: #fffcf4;\n  border-color: #ffc107;\n\n  &:hover {\n    background-color: #fff8e7;\n  }\n}\n\n.container__danger {\n  background-color: #fff7f4;\n  border-color: #ff5722;\n\n  &:hover {\n    background-color: #ffebe4;\n  }\n}\n\n.content {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font: 600 0.8rem \"Mulish\", sans-serif;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `k3WRqP0rNygDwPmsv2pp`,
	"container__ok": `LUTOlWFt7mBZS0CKOWFs`,
	"container__warning": `Zb_uczC9PjLw5qtZsfND`,
	"container__danger": `IhEke8bHpzlQNXl1jNDk`,
	"content": `_2zcZq41ecsViS52yil9`
};
export default ___CSS_LOADER_EXPORT___;
