import { STATUS_OK } from "@Models/httpsStatus";
import {
  updateMovements,
  updatePages,
} from "@ReduxService/states/movementsSlice";
import { getMovements_service } from "@Services/movements";
import { capitalizeWords } from "@Utilities/CapitalizeWords";
import { convertDate } from "@Utilities/convertDate";
import { convertTime } from "@Utilities/convertTime";

export const getMovements = async (
  idBox,
  movementType,
  data,
  concept,
  startDate,
  endDate,
  page,
  dispatch
) => {
  if (idBox === "Todas") {
    idBox = "";
  }
  if (movementType === "Todos") {
    movementType = "";
  }
  if (concept === "Todos") {
    concept = "";
  }
  const payload = {
    saleBoxId: idBox,
    typeMovement: movementType,
    searchParameter: data,
    typeMovementConcept: concept,
    startDate: startDate,
    endDate: endDate,
    page: page,
  };

  const response: IResponse = await getMovements_service(payload);

  if (!response.isSuccessful) {
    updateMovements([]);
    return response;
  }

  const movements: IMovement[] = response.data.movement.map((movement) => {
    return {
      saleBoxName: movement.saleBoxName,
      type:
        movement.typeMovement === "SALIDA"
          ? "Egreso"
          : capitalizeWords(movement.typeMovement),
      date:
        convertDate(movement.dateMovement) +
        " - " +
        convertTime(movement.hourMovement),
      dateMovement: convertDate(movement.dateMovement),
      hourMovement: convertTime(movement.hourMovement),
      concept: movement.concept,
      observation: movement.observation,
      employeeName: capitalizeWords(movement.employeeName),
      amount: movement.amount,
      saleDocumentId: movement.saleDocumentId,
      modified: movement.modified,
      id: movement.movementId,
    };
  });

  dispatch(updateMovements(movements));
  dispatch(updatePages(response.data.maxPageSize));

  return {
    status: STATUS_OK,
    message: "Movimientos de venta obtenidos correctamente",
    data: {
      movement: movements,
      maxPageSize: response.data.maxPageSize,
    },
  };
};
