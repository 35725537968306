import React from "react";

import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import EditPriceModal from "@Components/ui/organisms/EditPriceModal/EditPriceModal";
import { SaleTypeId } from "@Models/const/salesOptions";

export const changeModalEditPrices = (
  modalContext: AcceptCancelModalContextProps,
  sale: {
    item: ISaleItem;
    summary?: {
      quantity: string;
      subtotal: number;
      discount: number;
      addition: number;
      modifierIsPercentage: boolean;
      total: number;
    };
  },
  type: SaleTypeId,
  appointmentIndex: string
) => {
  const {
    buttonTwo,
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = (
    <EditPriceModal
      modalContext={modalContext}
      sale={{ ...sale, appointmentIndex }}
      type={type}
      key={sale.item.id}
    />
  );

  handleTitleChange("Modificar precio");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: true,
  });
  handleButtonTwoChange({
    ...buttonTwo,
    textButtonTwo: "Modificar",
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
