import { DISCOUNT_TYPE, PERCENTAGE_TYPE } from "@Models/const/priceModifier";

export const handleModifierTypeChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  modifier: {
    type: string;
    setModifierType: React.Dispatch<React.SetStateAction<string>>;
  },
  summary: {
    value: IItemSummary;
    setSummary: React.Dispatch<React.SetStateAction<IItemSummary>>;
  }
) => {
  const { type, setModifierType } = modifier;
  const { value, setSummary } = summary;

  const isPercentage = e.target.value === PERCENTAGE_TYPE;
  const previouseModifier = type === DISCOUNT_TYPE ? "discount" : "addition";
  setModifierType(e.target.value);

  setSummary({
    ...value,
    [previouseModifier]: "0",
    [e.target.value]: value[previouseModifier],
    modifierIsPercentage: isPercentage,
  } as IItemSummary);
};
