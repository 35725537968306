import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateSelectedSaleBoxAndList } from "@ReduxService/states/selectedSaleBoxSlice";
import { openSaleBox_service } from "@Services/saleBox";

export const postOpenSaleBox = async (
  initialCash: string,
  saleBox: ISaleBox,
  dispatch: AppDispatch
) => {
  const response: IResponse = await openSaleBox_service({
    req: {
      saleBoxId: saleBox.id,
      amount: Number(initialCash.replace(",", "") || 0),
      cashCountType: 1,
    },
  });

  if (response.status === STATUS_OK || response.status === 201) {
    // Update the selected sale box and the sale box on the list
    dispatch(
      updateSelectedSaleBoxAndList({
        ...saleBox,
        isOpen: true,
      })
    );
    localStorage.removeItem("lastSaleboxVisited");
  }

  return response;
};
