import { getReserveCommands_adapter } from "@Adapters/getReserveCommands";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { useAppSelector, useAppDispatch } from "@Hooks/store";
import { useEffect, useState } from "react";
import style from "./AddReserveCommandsModal.module.css";
import { updateCommandsAppointments } from "@ReduxService/states/reserveCommands";
import {
  addSaleAppointments,
  updateCommands,
} from "@ReduxService/states/currentSaleSlice";

// @ts-ignore
import { Icon } from "@viuti/recursos";
import { CalendarIcon, CommandIcon } from "@Models/icons";

interface IAddReserveCommandsModalProps {
  modalContext: AcceptCancelModalContextProps;
  idClient: number;
}

const AddReserveCommandsModal = ({
  modalContext,
  idClient,
}: IAddReserveCommandsModalProps) => {
  const dispatch = useAppDispatch();
  const appointments = useAppSelector(
    (state) => state.reserveCommands.appointments
  );
  const commands = useAppSelector((state) => state.reserveCommands.commands);

  const sales = useAppSelector((state) => state.currentSale);

  // Inicializar los estados con las reservas y comandas seleccionadas en sales
  const [selectedAppointments, setSelectedAppointments] = useState<Set<string>>(
    new Set(sales.sales.appointments.map((appointment) => appointment.index))
  );
  const [selectedCommands, setSelectedCommands] = useState<Set<string>>(
    new Set(sales.sales.commands.map((command) => command.index))
  );

  // Estado para controlar si el botón "Agregar" está deshabilitado
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(
    selectedAppointments.size === 0 && selectedCommands.size === 0
  );

  // Función para manejar el cambio de estado de los checkboxes
  const handleCheckboxChange = (
    id: string,
    type: "appointment" | "command"
  ) => {
    if (type === "appointment") {
      setSelectedAppointments((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(id)) {
          newSet.delete(id);
        } else {
          newSet.add(id);
        }
        // Actualizar el estado del botón
        const isDisabled = newSet.size === 0 && selectedCommands.size === 0;
        setIsAddButtonDisabled(isDisabled);
        modalContext.handleButtonTwoChange({
          ...modalContext.buttonTwo,
          isDisabled,
        });
        return newSet;
      });
    } else {
      setSelectedCommands((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(id)) {
          newSet.delete(id);
        } else {
          newSet.add(id);
        }
        // Actualizar el estado del botón
        const isDisabled = selectedAppointments.size === 0 && newSet.size === 0;
        setIsAddButtonDisabled(isDisabled);
        modalContext.handleButtonTwoChange({
          ...modalContext.buttonTwo,
          isDisabled,
        });
        return newSet;
      });
    }
  };

  useEffect(() => {
    (async () => {
      const response = await getReserveCommands_adapter(idClient);
      if (response.isSuccessful) {
        dispatch(
          updateCommandsAppointments({
            commands: response.data.commands || [],
            appointments: response.data.appointments || [],
          })
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient]);

  useEffect(() => {
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      textButtonTwo: "Agregar",
      actionButtonTwo: () => {
        // Manejar los elementos seleccionados
        const selectedAppointmentsArray = appointments.filter(
          (appointmentWrapper) =>
            selectedAppointments.has(appointmentWrapper.index)
        );
        const selectedCommandsArray = commands.filter((commandWrapper) =>
          selectedCommands.has(commandWrapper.index)
        );

        // Enviar las reservas y comandas completas
        dispatch(updateCommands(selectedCommandsArray));
        dispatch(addSaleAppointments(selectedAppointmentsArray));

        modalContext.handleClose();
      },
      isDisabled: isAddButtonDisabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointments, selectedCommands, isAddButtonDisabled]);

  // Renderizar la lista de reservas y comandas
  return (
    <div className={style.container}>
      <div className={style.sectionTitle}>
        <h3>Reservas</h3>
        <Icon path={CalendarIcon} color="#45348e" size={16} />
      </div>
      <div className={style.section}>
        {appointments.map((appointmentWrapper) => (
          <div key={appointmentWrapper.index} className={style.item}>
            <label className={style.checkboxLabel}>
              <input
                type="checkbox"
                className={style.checkbox}
                checked={selectedAppointments.has(appointmentWrapper.index)}
                onChange={() =>
                  handleCheckboxChange(appointmentWrapper.index, "appointment")
                }
              />
              <div className={style.itemContent}>
                {appointmentWrapper.appointment.services.map((service) => (
                  <div key={service.index} className={style.serviceDetails}>
                    <span className={style.serviceName}>
                      {service.item.name}
                    </span>

                    <span className={style.employeeName}>
                      {service.item.availableEmployees[0]?.name ||
                        "Sin asignar"}
                    </span>
                  </div>
                ))}
              </div>
            </label>
          </div>
        ))}
      </div>

      <div className={style.sectionTitle}>
        <h3>Comandas</h3>
        <Icon path={CommandIcon} color="#45348e" size={16} />
      </div>
      <div className={style.section}>
        {commands.map((commandWrapper) => (
          <div key={commandWrapper.index} className={style.item}>
            <label className={style.checkboxLabel}>
              <input
                type="checkbox"
                className={style.checkbox}
                checked={selectedCommands.has(commandWrapper.index)}
                onChange={() =>
                  handleCheckboxChange(commandWrapper.index, "command")
                }
              />
              <div className={style.itemContent}>
                {commandWrapper.command.itemsGroup.map((itemGroup) => (
                  <div key={itemGroup.index} className={style.serviceDetails}>
                    <span className={style.serviceName}>
                      {itemGroup.item.description}
                    </span>

                    <span className={style.employeeName}>
                      {itemGroup.item.employeeName || "Sin asignar"}
                    </span>
                  </div>
                ))}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddReserveCommandsModal;
