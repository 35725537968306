export const openSaleBoxProps = {
  messages: {
    salebox_already_open: "",
    salebox_want_to_open: "Estás a punto de abrir esta caja.",
    cash_count__note: "",
    cash_count__note_already_open: "",
    cash_count__success: "Caja abierta con éxito",
    cash_count__success_instruction:
      "Aguarda un momento, estamos redirigiéndote a la caja.",
  },
  inputs: {
    cash__value: "Cantidad de efectivo:",
    submit__button: "Abrir Caja",
    submit__button_continue: "Continuar",
  },
};
