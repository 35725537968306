import React from "react";
import style from "./PittyCashCard.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { CashIcon, CircleAlertIcon } from "@Models/icons";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { useAppSelector } from "@Hooks/store";

const PittyCashCard = () => {
  const report = useAppSelector((state) => state.saleboxReports.report);
  const currentAmount = report.pettyCash.currentAmount;

  return (
    <article className={style.container}>
      <h4 className={style.label}>Caja chica</h4>
      <div className={style.card}>
        <div className={style.card__content}>
          <Icon path={CashIcon} color={"#937cf4"} size={32} />
          <p className={style.card__header}>Efectivo en caja</p>
          <div className={style.card__amount__container}>
            <span className={style.total_amount}>
              {formatToSoles(currentAmount)}
            </span>
          </div>
          <div className={style.card__numbers__container}>
            <span className={style.card__numbers__item}>
              <span className={style.card__numbers__item_label}>
                Ingresos en efectivo
              </span>
              <span className={style.card__numbers__item_amount}>
                {formatToSoles(report.pettyCash.totalIncome)}
              </span>
            </span>
            <span className={style.card__numbers__item}>
              <span className={style.card__numbers__item_label}>
                Egresos en efectivo
              </span>
              <span className={style.card__numbers__item_amount}>
                {formatToSoles(report.pettyCash.totalOutcome)}
              </span>
            </span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default PittyCashCard;
