import React from "react";

import { AppDispatch } from "@ReduxService/index";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import SelectClientModal from "@Components/ui/organisms/SelectClientModal/SelectClientModal";
import { ClientsModalStateContextProps } from "@Hooks/NewClientsModalStateContext";
import {
  updateClient,
  updateGenericClient,
} from "@ReduxService/states/currentSaleSlice";
import { GENERIC_CLIENT_ID } from "@Models/const/clients";

export const changeModalClient = (
  modalContext: AcceptCancelModalContextProps,
  clientModalContext: ClientsModalStateContextProps,
  dispatch: AppDispatch,
  callback: () => void
) => {
  const {
    buttonTwo,
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const handleSelectClient = (client: IClient) => {
    if (client.id === GENERIC_CLIENT_ID) {
      dispatch(updateGenericClient(client));
    } else {
      dispatch(updateClient(client));
      dispatch(updateGenericClient(null));
    }
    callback();
  };

  const ModalChildren = (
    <SelectClientModal
      clientModalContext={clientModalContext}
      modalContext={modalContext}
      handleSelectClient={handleSelectClient}
    />
  );

  handleTitleChange("Seleccionar cliente");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: true,
  });
  handleButtonTwoChange({
    ...buttonTwo,
    textButtonTwo: "Seleccionar",
    isDisabled: true,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
