import axios from "axios";
import { baseUrl, config } from "./constGlobals";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const postSendEmailSaleboxReport_service = async (payload) => {
  const url = `${baseUrl}/Reports/AggregateReport`;
  const envia_reporte_a_su_correo = 70;
  trackUserAction(envia_reporte_a_su_correo);
  try {
    const response: IResponse = await axios
      .post(url, payload, {
        headers: {
          ...config.headers,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
    return {
      isSuccessful: true,
      status: response.status,
      message:
        "<b>¡Reporte enviado con éxito!</b><br/>En breve recibirás el documento en tu casilla de correo electrónico.",
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data.message ||
        "Hubo un error al enviar el reporte. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const downloadSaleboxReport_service = async (payload) => {
  const url = `${baseUrl}/Reports/DonwloadSaleboxReport?idSalebox=${payload.idSalebox}&startDate=${payload.startDate}&endDate=${payload.endDate}`;
  // const descarga_reporte_caja = 71; // Nuevo código de tracking
  // trackUserAction(descarga_reporte_caja);

  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message || "Error al descargar el reporte",
      data: [],
    };
  }
};
