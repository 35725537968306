import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./SelectedCashregisterBody.module.css";
import PayoutForm from "@Components/ui/organisms/PayoutForm/PayoutForm";
import SalesOptionsList from "@Components/ui/molecules/SalesOptionsList/SalesOptionsList";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import SalesContainer from "../SalesContainer/SalesContainer";
import EmptyMessage from "@Components/ui/molecules/EmptyMessage/EmptyMessage";
import {
  CircleUserIcon,
  AddIcon,
  MinusIcon,
  CalendarIcon,
  CodeBarIcon,
  PackageIcon,
  ServiceIcon,
  GiftIcon,
} from "@Models/icons";
// import { changeModalClient } from "@Hooks/UpdateModalType/changeModalClient/changeModalClient";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import {
  ClientsModalStateContext,
  ClientsModalStateContextProps,
} from "@Hooks/NewClientsModalStateContext";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import {
  validateModuleAccess,
  UnauthorizedMessage,
} from "@Models/resources-components";
import { CREATE_SALES_UUID } from "@Models/const/securityAccess";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import {
  APPOINTMENTS_TYPE,
  PACKAGES_TYPE,
  PRODUCTS_TYPE,
  SERVICES_TYPE,
  GIFTCARDS_TYPE,
} from "@Models/const/salesOptions";
import { handleSaleOptionAction } from "@Hooks/handleSaleOptionAction/handleSaleOptionAction";

export interface SelectedCashregisterBodyProps {
  prop?: string;
}

const SelectedCashregisterBody = () => {
  const dispatch = useAppDispatch();
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const clientModalContext = useContext(
    ClientsModalStateContext
  ) as ClientsModalStateContextProps;
  const client = useAppSelector((state) => state.currentSale.client);
  const genericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const selectedClient = genericClient || client;
  const bodyContainerRef = useRef<HTMLElement>(null);
  const screenWidth = useScreenWidth();
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const floatingButtonContainerRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSaleOptionClick = (optionId: number) => {
    setIsExpanded(false);
    handleSaleOptionAction(
      optionId,
      dispatch,
      selectedClient,
      modalContext,
      clientModalContext,
      client
    );
  };
  useEffect(() => {
    setIsExpanded(false);
  }, [selectedClient]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      floatingButtonContainerRef.current &&
      !floatingButtonContainerRef.current.contains(event.target as Node)
    ) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className={styles.SelectedCashregisterBody} ref={bodyContainerRef}>
      {!validateModuleAccess(CREATE_SALES_UUID, false) ? (
        <UnauthorizedMessage
          restrictedFeatureName={"Ventas"}
          restrictedUUID={CREATE_SALES_UUID}
        />
      ) : (
        <>
          <article className={styles.SelectedCashregisterBody__left}>
            <SalesOptionsList
              screenWidth={screenWidth}
              dispatch={dispatch}
              callback={() => {}}
              ref={inputRef}
            />
            {!selectedClient ? (
              <EmptyMessage
                icon={{ name: CircleUserIcon, size: 100 }}
                text="No hay un cliente seleccionado."
                subtext="Selecciona o ingresa un nuevo cliente añadiendo una venta o haciendo click aquí."
                action={handleFocusInput}
              />
            ) : (
              <SalesContainer />
            )}
            <div
              className={styles.floatingButtonContainer}
              ref={floatingButtonContainerRef}
            >
              {isExpanded && selectedClient && (
                <div
                  className={`${styles.expandedButtons} ${
                    isExpanded ? styles.expanded : ""
                  }`}
                >
                  <button onClick={() => handleSaleOptionClick(SERVICES_TYPE)}>
                    <Icon
                      path={ServiceIcon}
                      alt="Servicios"
                      size={15}
                      color="#45348e"
                    />
                    Servicios
                  </button>
                  <button onClick={() => handleSaleOptionClick(PRODUCTS_TYPE)}>
                    <Icon
                      path={CodeBarIcon}
                      alt="Productos"
                      size={15}
                      color="#45348e"
                    />
                    Productos
                  </button>
                  <button onClick={() => handleSaleOptionClick(PACKAGES_TYPE)}>
                    <Icon
                      path={PackageIcon}
                      alt="Paquetes"
                      size={15}
                      color="#45348e"
                    />
                    Paquetes
                  </button>
                  <button
                    onClick={() => handleSaleOptionClick(APPOINTMENTS_TYPE)}
                  >
                    <Icon
                      path={CalendarIcon}
                      alt="Reservas"
                      size={15}
                      color="#45348e"
                    />
                    Reservas / Comandas
                  </button>
                  {/* <button onClick={() => handleSaleOptionClick(GIFTCARDS_TYPE)}>
                    <Icon
                      path={GiftIcon}
                      alt="Giftcards"
                      size={15}
                      color="#45348e"
                    />
                    Giftcards
                  </button> */}
                </div>
              )}
              {selectedClient && (
                <button
                  onClick={handleToggle}
                  className={styles.floatingButton}
                >
                  <Icon
                    path={isExpanded ? MinusIcon : AddIcon}
                    alt="Añadir"
                    size={15}
                    color="#fff"
                  />
                </button>
              )}
            </div>
          </article>
          <article className={styles.SelectedCashregisterBody__right}>
            <PayoutForm />
          </article>
        </>
      )}
    </section>
  );
};

export default SelectedCashregisterBody;
