import axios from "axios";
import { baseUrl, config } from "./constGlobals";
import { updateNotification } from "@ReduxService/states/notificationSlice";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getSaleBoxes_service = async (dispatch) => {
  const url = `${baseUrl}/SaleBox/SaleBox`;
  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    dispatch(
      updateNotification({
        message: "Hubo un error al obtener las cajas. Intentalo de nuevo.",
        status: "400",
        toastTimeDuration: 4000,
      })
    );
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        "Hubo un error al obtener las cajas de venta. Por favor, intente nuevamente.",
      data: null,
    };
  }
};

export const getSaleBoxReport_service = async (payload: {
  idSalebox: number;
  startDate: string;
  endDate: string;
}) => {
  const url = `${baseUrl}/Reports/SaleBoxReport`;
  const carga_reporte_de_caja = 69;
  trackUserAction(carga_reporte_de_caja);
  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: payload,
      })
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        "Hubo un error al obtener el reporte de la caja. Por favor, intente nuevamente.",
      data: null,
    };
  }
};

export const getSaleBoxReportMovements_service = async (payload: {
  idSalebox: number;
  startDate: string;
  endDate: string;
  page: number;
}) => {
  const url = `${baseUrl}/Reports/SaleBoxReportDetail`;
  const carga_reporte_de_caja = 69;
  trackUserAction(carga_reporte_de_caja);
  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: payload,
      })
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        "Hubo un error al obtener el reporte de la caja. Por favor, intente nuevamente.",
      data: null,
    };
  }
};

type CashCountType = 1 | 2;

export const openSaleBox_service = async (payload: {
  req: {
    saleBoxId: number;
    amount: number;
    cashCountType: CashCountType;
  };
}) => {
  const url = `${baseUrl}/SaleBox/CashCount`;
  const abre_caja = 55;
  trackUserAction(abre_caja);

  try {
    const response: IResponse = await axios
      .put(url, payload, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    const formattedData = {
      isSuccessful: false,
      status: error.response?.data?.status || 500,
      message:
        error.response?.data?.status === 400
          ? error.response?.data?.detail
          : "Hubo un error al abrir la caja. Por favor, intente nuevamente.",
      data: { lastAmount: error.response?.data?.detail?.split("/")[1] },
    };
    return formattedData;
  }
};

export const closeSaleBox_service = async (payload: {
  req: {
    saleBoxId: number;
    amount: number;
    cashCountType: CashCountType;
  };
}) => {
  const url = `${baseUrl}/SaleBox/CashCount`;

  try {
    const cierra_caja = 56;
    trackUserAction(cierra_caja);
    const response: IResponse = await axios
      .put(url, payload, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    const formattedData = {
      isSuccessful: false,
      status: error.response?.status || 500,
      message:
        error.response?.status === 400
          ? "El monto en efectivo no coincide con el último cierre"
          : "Hubo un error al cerrar la caja. Por favor, intente nuevamente.",
      data: { lastAmount: error.response?.data?.detail?.split("/")[1] },
    };

    return formattedData;
  }
};
