import { STATUS_OK } from "@Models/httpsStatus";
import { v4 as uuid } from "uuid";
import { AppDispatch } from "@ReduxService/index";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import {
  updateReport,
  updateReportMovements,
} from "@ReduxService/states/saleBoxReportSlice";
import {
  getSaleBoxReport_service,
  getSaleBoxReportMovements_service,
} from "@Services/saleBox";

export const getSaleBoxReport = async (
  dispatch: AppDispatch,
  values: {
    salebox: ISaleBox | "";
    fromDate: string;
    toDate: string;
  }
) => {
  const payload = {
    idSalebox: values.salebox !== "" ? values.salebox.id : 0,
    startDate: values.fromDate,
    endDate: values.toDate,
  };

  // Obtener el reporte principal
  const response: IResponse = await getSaleBoxReport_service(payload);

  if (response.status !== STATUS_OK) {
    dispatch(
      updateReport({
        isSuccessful: false,
        status: response.status,
        message: "Error al obtener el reporte de caja",
        data: null,
      })
    );
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status as any,
        toastTimeDuration: 4000,
      })
    );
    return response;
  }

  // Obtener los movimientos del reporte
  const movementsPayload = {
    ...payload,
    page: 1,
  };

  const movementsResponse: IResponse = await getSaleBoxReportMovements_service(
    movementsPayload
  );

  if (movementsResponse.status !== STATUS_OK) {
    dispatch(
      updateNotification({
        message: "Error al obtener los movimientos del reporte",
        status: movementsResponse.status as any,
        toastTimeDuration: 4000,
      })
    );
  }

  const data = response.data;
  const formattedResponse: ISaleBoxReport = {
    pettyCash: {
      currentAmount: data.saleBoxCash,
      totalIncome: data.entryCash,
      totalOutcome: data.outputCash,
    },
    movements: {
      sales: {
        amount: data.totalQuantitySales,
        totalCash: data.totalCashSales,
      },
      inputs: {
        amount: data.entryQuantity,
        totalCash: data.totalEntryCash,
      },
      outputs: {
        amount: data.outputQuantity,
        totalCash: data.totalOutputCash,
      },
    },
    payments:
      data.paymentMethods.length > 0
        ? data.paymentMethods.map((method) => ({
            category: method.category,
            subCategory: method.subcategory,
            amount: method.amount,
          }))
        : [],
  };

  dispatch(
    updateReport({
      isSuccessful: true,
      status: STATUS_OK,
      message: "Reporte de caja obtenido correctamente",
      data: formattedResponse,
    })
  );

  if (movementsResponse.status === STATUS_OK) {
    const formattedMovements: ISaleBoxReportMovementsResponse = {
      data: movementsResponse.data.detail.map((item: any) => {
        // Separar la fecha y hora del string
        const [datePart, timePart, meridiem] = item.date.split(" ");

        return {
          id: uuid(),
          date: datePart,
          time: `${timePart} ${meridiem}`,
          isCashRegisterCloseMovement: item.closeMovement,
          type: `${item.typeMovement.charAt(0).toUpperCase()}${item.typeMovement
            .slice(1)
            .toLowerCase()}`,
          income: item.income,
          outcome: item.revenue,
          total: item.cumulative,
          concept: `${item.description
            .charAt(0)
            .toUpperCase()}${item.description.slice(1).toLowerCase()}`,
          paymentMethod: `${item.paymentType
            .charAt(0)
            .toUpperCase()}${item.paymentType.slice(1).toLowerCase()}`,
          active: item.activo,
        };
      }),
      totalPages: movementsResponse.data.maxPage,
      currentPage: movementsResponse.data.actualPage,
    };

    dispatch(updateReportMovements(formattedMovements));
  }

  return {
    status: STATUS_OK,
    message: "Reporte de caja obtenido correctamente",
    data: response.data,
  };
};

export const getSaleBoxReportMovements = async (values: {
  salebox: ISaleBox | "";
  fromDate: string;
  toDate: string;
  page: number;
}) => {
  const payload = {
    idSalebox: values.salebox !== "" ? values.salebox.id : 0,
    startDate: values.fromDate,
    endDate: values.toDate,
    page: values.page,
  };

  const movementsResponse: IResponse = await getSaleBoxReportMovements_service(
    payload
  );

  if (movementsResponse.status !== STATUS_OK) {
    return {
      status: movementsResponse.status,
      message: "Error al obtener los movimientos del reporte",
      data: null,
    };
  }

  const formattedMovements: ISaleBoxReportMovementsResponse = {
    data: movementsResponse.data.detail.map((item: any) => {
      const [datePart, timePart, meridiem] = item.date.split(" ");

      return {
        id: uuid(),
        date: datePart,
        time: `${timePart} ${meridiem}`,
        isCashRegisterCloseMovement: item.closeMovement,
        type: `${item.typeMovement.charAt(0).toUpperCase()}${item.typeMovement
          .slice(1)
          .toLowerCase()}`,
        income: item.income,
        outcome: item.revenue,
        total: item.cumulative,
        concept: `${item.description.charAt(0).toUpperCase()}${item.description
          .slice(1)
          .toLowerCase()}`,
        paymentMethod: `${item.paymentType
          .charAt(0)
          .toUpperCase()}${item.paymentType.slice(1).toLowerCase()}`,
        active: item.activo,
      };
    }),
    totalPages: movementsResponse.data.maxPage,
    currentPage: movementsResponse.data.actualPage,
  };

  return {
    isSuccessful: true,
    status: STATUS_OK,
    message: "Movimientos obtenidos correctamente",
    data: formattedMovements,
  };
};
