import { TYPE_DNI } from "@Models/const/documentTypes";
import React, { createContext, useMemo, useState } from "react";

export interface ClientsModalStateContextProps {
  title: string;
  subTitle: string;
  backClick: () => void;
  nextClick: () => void;
  width: number | string;
  client: IClient;
  handleClientChange: (client: IClient) => void;
  status: {
    error: boolean;
    validated: boolean;
    loading: boolean;
    message: string;
  };
  textButton: string;
  edit: boolean;
  isLoading: boolean;
  forceDisabled: boolean;
  visibility: {
    isVisible: boolean;
    setIsVisible: Function | null;
  };
  handleVisibilityChange: (visibility: {
    isVisible: boolean;
    setIsVisible: Function | null;
  }) => void;
}

const ClientsModalStateContext = createContext<
  ClientsModalStateContextProps | {}
>({});

const ClientsModalStateProvider = ({ children }) => {
  const [title, setTitle] = useState<string | Element>(
    "Agregar datos de cliente nuevo"
  );
  const [subTitle, setSubTitle] = useState<string | Element>("");
  const [visibility, setVisibility] = useState<{
    isVisible: boolean;
    setIsVisible: Function | null;
  }>({
    isVisible: false,
    setIsVisible: () => {},
  });
  const [backClick, setBackClick] = useState<() => void>(() =>
    setVisibility({ isVisible: false, setIsVisible: null })
  );
  const [nextClick, setNextClick] = useState<() => void>(() => {});
  const [width, setWidth] = useState<number | string>("610px");
  const [client, setClient] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneCode: 51,
    phoneNumber: "",
    documentType: TYPE_DNI,
    documentNumber: "",
    sex: "",
    birthdate: "",
    address: "",
  });
  const [status, setStatus] = useState({
    error: false,
    validated: false,
    loading: false,
    message: "",
  });
  const [textButton, setTextButton] = useState<string>("Siguiente");
  const [edit, setEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forceDisabled, setForceDisabled] = useState<boolean>(false);

  const handleTitleChange = (title: string | Element) => {
    setTitle(title);
  };

  const handleSubTitleChange = (subTitle: string | Element) => {
    setSubTitle(subTitle);
  };

  const handleBackClickChange = (backClick: () => void) => {
    setBackClick(backClick);
  };

  const handleNextClickChange = (nextClick: () => void) => {
    setNextClick(nextClick);
  };

  const handleWidthChange = (width: number | string) => {
    setWidth(width);
  };

  const handleClientChange = (event: any) => {
    setClient(event);
  };

  const handleStatusChange = (status: any) => {
    setStatus(status);
  };

  const handleTextButtonChange = (textButton: string) => {
    setTextButton(textButton);
  };

  const handleEditChange = (edit: boolean) => {
    setEdit(edit);
  };

  const handleIsLoadingChange = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  const handleForceDisabledChange = (forceDisabled: boolean) => {
    setForceDisabled(forceDisabled);
  };

  const handleVisibilityChange = (visibility: {
    isVisible: boolean;
    setIsVisible: Function | null;
  }) => {
    setVisibility(visibility);
  };

  const contextValue = useMemo(
    () => ({
      title,
      subTitle,
      backClick,
      nextClick,
      width,
      client,
      status,
      textButton,
      edit,
      isLoading,
      forceDisabled,
      visibility,
      handleTitleChange,
      handleSubTitleChange,
      handleBackClickChange,
      handleNextClickChange,
      handleWidthChange,
      handleClientChange,
      handleStatusChange,
      handleTextButtonChange,
      handleEditChange,
      handleIsLoadingChange,
      handleForceDisabledChange,
      handleVisibilityChange,
    }),
    [
      title,
      subTitle,
      backClick,
      nextClick,
      width,
      client,
      status,
      textButton,
      edit,
      isLoading,
      forceDisabled,
      visibility,
    ]
  );

  return (
    <ClientsModalStateContext.Provider value={contextValue}>
      {children}
    </ClientsModalStateContext.Provider>
  );
};

export { ClientsModalStateContext, ClientsModalStateProvider };
