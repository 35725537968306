import React, { useCallback } from "react";
import styles from "./CashregisterMovementsTable.module.css";
import { formatToSoles } from "@Utilities/FormatsHandler";
import CustomTable, {
  Column,
} from "@Components/ui/molecules/CustomTable/CustomTable";
import { useAppSelector, useAppDispatch } from "@Hooks/store";
import { getSaleBoxReportMovements } from "@Adapters/getSaleBoxReport.adapter";
import { updateReportMovements } from "@ReduxService/states/saleBoxReportSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";

const CashregisterMovementsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { movements } = useAppSelector((state) => state.saleboxReports);
  const filters = useAppSelector((state) => state.saleboxReports);

  const handlePageChange = useCallback(
    async (page: number) => {
      const response = await getSaleBoxReportMovements({
        salebox: filters.salebox,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        page: page,
      });

      if (response.isSuccessful) {
        dispatch(updateReportMovements(response.data));
      } else {
        dispatch(
          updateNotification({
            message: response.message,
            status: response.status,
            toastTimeDuration: 4000,
          })
        );
      }
    },
    [filters, dispatch]
  );

  const columns: Column<ISaleBoxReportMovement>[] = [
    {
      header: "Fecha",
      accessor: (movement) => movement.date,
    },
    {
      header: "Hora",
      accessor: (movement) => movement.time,
    },
    {
      header: "Tipo",
      accessor: (movement) => movement.type,
    },
    {
      header: "Concepto",
      accessor: (movement) => movement.concept,
    },
    {
      header: "Método de pago",
      accessor: (movement) => movement.paymentMethod,
    },
    {
      header: "Ingreso",
      accessor: (movement) => {
        if (movement.isCashRegisterCloseMovement) {
          return formatToSoles(movement.income);
        }
        return movement.income > 0 ? formatToSoles(movement.income) : "-";
      },
      cellClassName: (movement) =>
        movement.isCashRegisterCloseMovement ? styles.mergedCell : "",
    },
    {
      header: "Egreso",
      accessor: (movement) => {
        if (movement.isCashRegisterCloseMovement) {
          return null;
        }
        return movement.outcome > 0 ? formatToSoles(movement.outcome) : "-";
      },
    },
    {
      header: "Total acumulado",
      accessor: (movement) => {
        if (movement.isCashRegisterCloseMovement) {
          return null;
        }
        return formatToSoles(movement.total);
      },
    },
  ];

  const processedData = movements.data?.map((movement) => ({
    ...movement,
    rowClassName: `${
      movement.isCashRegisterCloseMovement ? styles.closeMovementRow : ""
    } ${!movement.active ? styles.inactiveRow : ""}`,
  }));

  return (
    <CustomTable
      data={processedData || []}
      columns={columns as Column<{ id: string | number }>[]}
      currentPage={movements.currentPage}
      setCurrentPage={handlePageChange}
      totalPages={movements.totalPages}
      emptyMessage="No se han encontrado movimientos para este período."
    />
  );
};

export default CashregisterMovementsTable;
