import { AppDispatch } from "@ReduxService/index";
import { updateWarehouses } from "@ReduxService/states/saleConfigSlice";
import { getWarehouses_service } from "@Services/warehouses";

export const getWarehouses = async (dispatch: AppDispatch) => {
  const response: IResponse = await getWarehouses_service();

  if (!response.isSuccessful) {
    return response;
  }

  const formattedResponse: IWarehouse[] = response.data.map(
    (warehouse: any) => {
      return {
        id: warehouse.warehouseId,
        name: warehouse.warehouseName,
        isForSale: warehouse.sales === 1,
        products: null,
        hasFetchedProducts: false,
      };
    }
  );

  dispatch(updateWarehouses(formattedResponse));

  return response;
};
