import axios from "axios";
import { baseUrl, config } from "./constGlobals";

export const getPaymentMethods_service = async () => {
  const url = `${baseUrl}/PaymentsMethods/PaymentMethods`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los métodos de pago.",
      data: [],
    };
  }
};
