import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getGiftcards_service } from "@Services/giftcards";

export const getGiftcards_adapter = async (dispatch: AppDispatch) => {
  const response: IResponse = await getGiftcards_service();

  if (response.status !== STATUS_OK) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status.toString(),
        toastTimeDuration: 4000,
      })
    );
    return [];
  }

  return response.data.map((giftcard: any) => ({
    id: giftcard.giftcardId,
    value: giftcard.value,
    code: giftcard.code,
    modality: giftcard.modality,
  }));
};
