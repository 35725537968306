export const isSaleValid = (
  paymentMethods: ISalePaymentMethod[],
  giftcardPayments: IGiftcardPayment[],
  summary: ISaleSummary
): boolean => {
  // Si el total es 0, la venta es válida
  if (summary.total === 0) return true;

  // Suma todos los pagos con métodos de pago
  const methodsCovered = paymentMethods.reduce((acc, value) => {
    return acc + (parseFloat(String(value.value)) || 0);
  }, 0);

  // Suma todos los pagos con giftcards
  const giftcardsCovered = giftcardPayments.reduce((acc, value) => {
    return acc + (parseFloat(String(value.amount)) || 0);
  }, 0);

  // Suma total de todos los pagos
  const totalCovered = methodsCovered + giftcardsCovered;

  // Calcula la diferencia con el total de la venta
  const difference = Number((summary.total - totalCovered).toFixed(2));

  // Si la diferencia es 0, no necesitamos validar los métodos de pago
  if (difference === 0) return true;

  // Verifica que todos los métodos de pago tengan valores válidos
  const allPaymentMethodsValid = paymentMethods.every(
    (method) =>
      method.paymentMethod &&
      method.paymentCategory &&
      parseFloat(String(method.value)) > 0
  );

  // Verifica que todas las giftcards tengan valores válidos
  const allGiftcardsValid = giftcardPayments.every(
    (giftcard) =>
      giftcard.giftcardCode && parseFloat(String(giftcard.amount)) > 0
  );

  // La venta es válida si:
  // 1. La diferencia es 0 o negativa (se ha cubierto o excedido el monto)
  // 2. Todos los métodos de pago son válidos
  // 3. Todas las giftcards son válidas (si hay alguna)
  return (
    difference <= 0 &&
    allPaymentMethodsValid &&
    (giftcardPayments.length === 0 || allGiftcardsValid)
  );
};
