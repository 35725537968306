export const typeDocuments = [
  {
    value: "Todos",
    name: "Todos",
  },
  {
    value: "1",
    name: "Boleta de venta",
  },
  {
    value: "2",
    name: "Factura",
  },
  {
    value: "3",
    name: "Nota de crédito",
  },
  {
    value: "5",
    name: "Nota de venta",
  },
];
export const state = [
  {
    value: "Todos",
    name: "Todos",
  },
  {
    value: "1",
    name: "Emitido",
  },
  {
    value: "2",
    name: "Anulado",
  },
];

export const stateFE = [
  {
    value: "Todos",
    name: "Todos",
  },
  {
    value: "1",
    name: "Pendiente",
  },
  {
    value: "2",
    name: "No transmitido",
  },
  {
    value: "3",
    name: "Rechazado",
  },
  {
    value: "4",
    name: "Aceptado",
  },
  {
    value: "5",
    name: "Anulado",
  },
];
// una funcion para elegir la clase de las celdas de la tabla
export const getStatusClassName = (status: string, style: any): string => {
  switch (status) {
    case "Aceptado":
      return style.acceptedStatus;
    case "Emitido":
      return "";
    case "No transmitido":
      return "";
    default:
      return style.rejectedStatus;
  }
};

export const DOCUMENTOS_DE_VENTAS = "documentos-de-ventas";
