export const eBillingAmountModalSuccessMessages = {
  title: "Tienes [1] comprobantes disponibles.",
  message: `¡Genial! Aún tienes comprobantes disponibles para generar ventas mediante facturación 
  electrónica. Si necesitas más comprobantes, puedes actualizar tu plan o comprar más comprobantes 
  en cualquier momento.`,
  button: "Aumentar comprobantes",
};

export const eBillingAmountModalWarningMessages = {
  title: "¡Atención! Te quedan [1] comprobantes disponibles.",
  message: `Estás próximo a quedarte sin comprobantes electrónicos. Para evitar interrupciones 
  en tus ventas, te recomendamos aumentar tu límite comprando más comprobantes ahora.`,
  button: "Aumentar comprobantes",
};

export const eBillingAmountModalErrorMessages = {
  title: "Tienes [1] comprobantes disponibles.",
  message: `Para continuar realizando ventas con facturación electrónica, necesitas aumentar 
  tu límite de comprobantes. Esto se puede hacer con la compra de más comprobantes.`,
  button: "Aumentar comprobantes",
};
