/* eslint-disable */
// @ts-ignore
import React, { useContext, useEffect, useRef, useState } from "react";
// @ts-ignore
import styles from "./SelectedCashregisterHeader.module.css";
import { BillIcon, PieGraphIcon, TimesIcon } from "@Models/icons";
import { validateModuleAccess, Icon } from "@Models/resources-components";
import { TwoWaysArrowsIcon } from "../../../models/icons";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";
import { changeModalSalebox } from "@Hooks/UpdateModalType/changeModalSalebox/changeModalSalebox";
import { changeModalCashCount } from "@Hooks/UpdateModalType/changeModalCashCount/changeModalCashCount";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { changeModalChangeSaleBox } from "@Hooks/UpdateModalType/changeModalChangeSaleBox/changeModalChangeSaleBox";
import {
  CREATE_SALES_UUID,
  MOVEMENTS_UUID,
  SALEBOX_REPORT_UUID,
  SALE_DOCUMENTS_UUID,
} from "@Models/const/securityAccess";
// @ts-ignore
import { IconWithOptions, IconWrapper } from "@viuti/recursos";
import {
  HiMiniListBullet,
  HiMiniArrowPath,
  HiLockClosed,
} from "react-icons/hi2";
import { changeModalDowngrade } from "@Hooks/UpdateModalType/changeModalDowngrade/changeModalDowngrade";

const SelectedCashregisterHeader = () => {
  const dispatch = useAppDispatch();
  const saleBoxesList = useAppSelector((state) => state.saleBoxesList);
  const selectedSaleBox = useAppSelector((state) => state.selectedSaleBox);
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  let iconFilter = "";
  const currentUrl = window.location.href;

  const listRef = useRef(null);
  const changeSaleboxButtonRef = useRef(null);
  const screenWidth = useScreenWidth();
  const [isChangeSaleboxActive, setIsChangeSaleboxActive] = useState(false);

  const handleOnMouseOver = (e: any) => {
    const icon = e.target.firstChild;
    if (e.target === null) return;

    if (e.target === e.currentTarget) {
      if (icon.style) {
        if (!iconFilter) iconFilter = icon.style.filter;
        icon.style.filter = "brightness(10.0)";
      }
    } else if (e.target.nodeName === "IMG")
      e.target.style.filter = "brightness(10.0)";
  };

  const handleOnMouseOut = (e: any) => {
    if (e.target === e.currentTarget) {
      const icon = e.target.firstChild;
      if (icon.style) {
        icon.style.filter = iconFilter;
      }
    }
  };

  useEffect(() => {
    const handleClick = (e: any) => {
      if (
        listRef.current &&
        !listRef.current.contains(e.target) &&
        changeSaleboxButtonRef.current &&
        !changeSaleboxButtonRef.current.contains(e.target)
      ) {
        setIsChangeSaleboxActive(false);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [listRef, changeSaleboxButtonRef]);

  const handleSaleBoxClick = (saleBox: ISaleBox) => {
    if (saleBox.isDowngraded) {
      changeModalDowngrade(modalContext);
      return;
    }

    if (saleBox.needsCashCount) {
      changeModalCashCount(modalContext, saleBox, false);
    } else {
      changeModalSalebox(saleBox, modalContext, dispatch, true);
    }
  };

  if (screenWidth < 900)
    return (
      <header className={styles.headerContainer} id="headerContainer">
        <div className={styles.headerRow}>
          <span className={styles.statusBubble} />
          <h2 className={styles.cashregisterName}>{selectedSaleBox?.name}</h2>
          <button
            className={styles.changeCashregisterButton}
            onClick={() =>
              changeModalChangeSaleBox(
                modalContext,
                dispatch,
                selectedSaleBox,
                saleBoxesList
              )
            }
            ref={changeSaleboxButtonRef}
          >
            <IconWrapper icon={HiMiniArrowPath} size={14} />
          </button>
        </div>
        <div>
          <IconWithOptions
            buttonsStyles={{
              padding: "15px",
            }}
            containerStyles={{
              borderRadius: "6px",
            }}
            icon={
              <IconWrapper
                icon={HiMiniListBullet}
                size={40}
                color={"#473693"}
              />
            }
            actions={[
              {
                label: "Movimientos",
                handler: () => {
                  const uuidToValidate = MOVEMENTS_UUID;
                  const isAccessOk = validateModuleAccess(uuidToValidate);
                  if (isAccessOk)
                    window.location.href = currentUrl + "/movimientos";
                },
                icon: (
                  <Icon path={TwoWaysArrowsIcon} color="#473693" size={16} />
                ),
              },
              {
                label: "Documentos de venta",
                handler: () => {
                  const uuidToValidate = SALE_DOCUMENTS_UUID;
                  const isAccessOk = validateModuleAccess(uuidToValidate);
                  if (isAccessOk)
                    window.location.href = currentUrl + "/documentos-de-ventas";
                },
                icon: <Icon path={BillIcon} color="#473693" size={16} />,
              },
              {
                label: "Mi caja",
                handler: () => {
                  const uuidToValidate = SALEBOX_REPORT_UUID;
                  const isAccessOk = validateModuleAccess(uuidToValidate);
                  if (isAccessOk)
                    window.location.href = currentUrl + "/reporte";
                },
                icon: <Icon path={PieGraphIcon} color="#473693" size={16} />,
              },
              {
                label: "Cerrar caja",
                handler: () => {
                  validateModuleAccess(CREATE_SALES_UUID) &&
                    changeModalSalebox(
                      selectedSaleBox,
                      modalContext,
                      dispatch,
                      false
                    );
                },
                icon: (
                  <Icon path={TimesIcon} color="#d82121" size={14} alt="X" />
                ),
              },
            ]}
          />
        </div>
      </header>
    );
  return (
    <header className={styles.headerContainer} id="headerContainer">
      <div className={styles.headerRow}>
        <span className={styles.statusBubble} />
        <h2 className={styles.cashregisterName}>{selectedSaleBox?.name}</h2>
        <button
          className={styles.changeCashregisterButton}
          onClick={() => setIsChangeSaleboxActive(!isChangeSaleboxActive)}
          ref={changeSaleboxButtonRef}
        >
          Cambiar de caja
          <ul
            className={`${styles.changeCashregisterButton__PopUp} ${
              isChangeSaleboxActive
                ? styles.changeCashregisterButton__PopUp__active
                : ""
            }`}
            ref={listRef}
          >
            {saleBoxesList?.map((saleBox: ISaleBox) => {
              let statusText = "";
              if (saleBox.id === selectedSaleBox.id) {
                statusText = "Seleccionada";
              } else if (saleBox.isOpen) {
                statusText = "Abierta";
              } else {
                statusText = "Cerrada";
              }
              return (
                <button
                  key={saleBox.id}
                  className={`${
                    styles.changeCashregisterButton__PopUp__Element
                  } ${
                    saleBox.isDowngraded
                      ? styles.changeCashregisterButton__PopUp__Element__Disabled
                      : ""
                  }`}
                  onClick={() => handleSaleBoxClick(saleBox)}
                  disabled={saleBox.id === selectedSaleBox.id}
                >
                  <div
                    className={
                      styles.changeCashregisterButton__PopUp__Element__Name
                    }
                  >
                    {saleBox.name}
                  </div>

                  <div
                    className={
                      styles.changeCashregisterButton__PopUp__Element__Status
                    }
                  >
                    <span
                      className={`${
                        saleBox.isOpen
                          ? styles.changeCashregisterButton__PopUp__Element__Status__Open
                          : styles.changeCashregisterButton__PopUp__Element__Status__Closed
                      } ${
                        saleBox.id === selectedSaleBox.id
                          ? styles.changeCashregisterButton__PopUp__Element__Status__Selected
                          : ""
                      }`}
                    />
                    {statusText}
                    {saleBox.isDowngraded && (
                      <span
                        className={
                          styles.changeCashregisterButton__PopUp__Element__Status__DowngradeIcon
                        }
                      >
                        <IconWrapper
                          icon={HiLockClosed}
                          size={12}
                          color="#937cf4"
                        />
                      </span>
                    )}
                  </div>
                </button>
              );
            })}
          </ul>
        </button>
        <button
          className={styles.closeCashregisterButton}
          onClick={() =>
            validateModuleAccess(CREATE_SALES_UUID) &&
            changeModalSalebox(selectedSaleBox, modalContext, dispatch, false)
          }
        >
          Cerrar caja
          <span className={styles.closeCashregisterButton__Icon}>
            <Icon path={TimesIcon} color="#d82121" size={8} alt="X" />
          </span>
        </button>
      </div>
      <div className={styles.headerRow}>
        <button
          className={styles.actionButtons}
          onMouseOver={handleOnMouseOver}
          onMouseOut={handleOnMouseOut}
          onFocus={() => {}}
          onBlur={() => {}}
          onClick={() => {
            const uuidToValidate = MOVEMENTS_UUID;
            const isAccessOk = validateModuleAccess(uuidToValidate);
            if (isAccessOk) window.location.href = currentUrl + "/movimientos";
          }}
        >
          <Icon path={TwoWaysArrowsIcon} color="#473693" size={14} />
          Movimientos
        </button>
        <button
          className={styles.actionButtons}
          onMouseOver={handleOnMouseOver}
          onMouseOut={handleOnMouseOut}
          onFocus={() => {}}
          onBlur={() => {}}
          onClick={() => {
            const uuidToValidate = SALE_DOCUMENTS_UUID;
            const isAccessOk = validateModuleAccess(uuidToValidate);
            if (isAccessOk)
              window.location.href = currentUrl + "/documentos-de-ventas";
          }}
        >
          <Icon path={BillIcon} color="#473693" size={14} />
          Documentos de venta
        </button>
        <button
          className={styles.actionButtons}
          onMouseOver={handleOnMouseOver}
          onMouseOut={handleOnMouseOut}
          onFocus={() => {}}
          onBlur={() => {}}
          onClick={() => {
            const uuidToValidate = SALEBOX_REPORT_UUID;
            const isAccessOk = validateModuleAccess(uuidToValidate);
            if (isAccessOk) window.location.href = currentUrl + "/reporte";
          }}
        >
          <Icon path={PieGraphIcon} color="#473693" size={14} />
          Mi caja
        </button>
      </div>
    </header>
  );
};

export default SelectedCashregisterHeader;
