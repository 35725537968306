import { DISCOUNT_TYPE, PERCENTAGE_TYPE } from "@Models/const/priceModifier";
import { formatCurrency } from "./handleQuantityBlur";

export const handleIsPercentageChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  modifier: {
    type: string;
    setModifierType: React.Dispatch<React.SetStateAction<string>>;
  },
  summary: {
    value: IItemSummary;
    setSummary: React.Dispatch<React.SetStateAction<IItemSummary>>;
  },
  totals: {
    totalWithPercentage: number;
    totalWithSoles: number;
  }
) => {
  const { type, setModifierType } = modifier;
  const { value, setSummary } = summary;
  const { totalWithPercentage, totalWithSoles } = totals;

  const isPercentage = e.target.value === PERCENTAGE_TYPE;
  const modifierType = type === DISCOUNT_TYPE ? "discount" : "addition";

  setSummary({
    ...value,
    [modifierType]: formatCurrency(value[modifierType]),
    modifierIsPercentage: isPercentage,
    total: value.modifierIsPercentage ? totalWithPercentage : totalWithSoles,
  } as IItemSummary);
};
