import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateWarehouseProducts } from "@ReduxService/states/saleConfigSlice";
import { getWarehouseProducts_service } from "@Services/warehouses";

export const getProductsByWarehouseId = async (
  dispatch: AppDispatch,
  warehouseId: number
) => {
  const response: IResponse = await getWarehouseProducts_service(warehouseId);

  if (response.status !== STATUS_OK) {
    return response;
  }

  const formattedResponse: IProduct[] = response.data.map((product: any) => {
    return {
      warehouseId: warehouseId,
      id: product.productId,
      localId: product.productLocalId,
      name: product.productName,
      code: product.code,
      price: Number(product.price) - product.price * 0.18,
      igv: product.price * 0.18,
      stock: product.stock,
      brand: {
        id: product.brand.brandId,
        name: product.brand.brandName,
      },
      subBrand: {
        id: product.line?.lineId || null,
        name: product.line?.lineName || null,
      },
      categories: product.category.map((category: any) => {
        return {
          id: category.categoryId,
          name: category.categoryName,
        };
      }),
    };
  });

  const dispatchPayload = {
    products: formattedResponse,
    warehouseId,
  };

  dispatch(updateWarehouseProducts(dispatchPayload));

  return {
    status: STATUS_OK,
    message: "Productos obtenidos correctamente",
    data: response.data,
  };
};
