import { STATUS_OK } from "@Models/httpsStatus";
import { addConcepts } from "@ReduxService/states/conceptsSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getConceptMovements_service } from "@Services/conceptsMovements";

export const getConceptsMovements = async (dispatch) => {
  const response: IResponse = await getConceptMovements_service();

  if (response.status !== STATUS_OK) {
    updateNotification({
      message: "Hubo un error al obtener los conceptos de movimiento",
      status: "400",
      toastTimeDuration: 2000,
    });
    return response;
  }

  const formattedResponse = response.data.map((concept: any) => {
    return {
      value: concept.idSaleBoxMovementConcept,
      name: concept.description,
      movementType: concept.movementType,
    };
  });

  dispatch(addConcepts(formattedResponse));
};
