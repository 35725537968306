import { STATUS_OK } from "@Models/httpsStatus";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import {
  updatePages,
  updateSaleDocuments,
} from "@ReduxService/states/saleDocumentsSlice";
import { getSalesDocuments_service } from "@Services/salesDocuments";
import { capitalizeWords } from "@Utilities/CapitalizeWords";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { convertDateFormat } from "@Utilities/convertDateFormat";
import { formatTypeDocument } from "@Utilities/formatTypeDocument";

interface Form {
  searParameter: string;
  idBox: string;
  typeDocumetnId: string;
  status: string;
  statusFE: string;
  startDate: string;
  endDate: string;
}

export const getSalesDocuments = async (form: Form, page, dispatch) => {
  const {
    searParameter,
    idBox,
    typeDocumetnId,
    status,
    statusFE,
    startDate,
    endDate,
  } = form;

  const payload = {
    searchParameter: searParameter,
    idBox: idBox === "Todas" ? "" : idBox,
    typeDocumentId: typeDocumetnId === "Todos" ? "" : typeDocumetnId,
    status: status === "Todos" ? "" : status,
    statusFE: statusFE === "Todos" ? "" : statusFE,
    startDate: startDate,
    endDate: endDate,
    page: page,
  };

  const response: IResponse = await getSalesDocuments_service(payload);

  if (!response.isSuccessful) {
    dispatch(
      updateNotification({
        status: response.status,
        message: response.message,
        toastTimeDuration: 4000,
      })
    );
    return response;
  }

  const salesDocuments = response.data.documents.map((saleDocument) => {
    return {
      saleBoxId: saleDocument.saleBoxId,
      box: saleDocument.saleBoxName,
      type: formatTypeDocument(saleDocument.typeDocumentName),
      number: saleDocument.number,
      date: convertDateFormat(saleDocument.dateEmision),
      justTheDate: convertDateFormatTimeOrDate(
        saleDocument.dateEmision,
        "date"
      ),
      time: convertDateFormatTimeOrDate(saleDocument.dateEmision, "time"),
      employee: capitalizeWords(saleDocument.employeeName),
      client: capitalizeWords(saleDocument.clientName),
      clientDocumentName: saleDocument.clientDocumentName,
      clientDocumentNumber: saleDocument.clientDocumentNumber,
      clientId: saleDocument.clientId,
      total: formatToSoles(saleDocument.total),
      status: capitalizeWords(saleDocument.statusName),
      statusFE:
        capitalizeWords(saleDocument.statusFEName) === "Anulado (baja)"
          ? "Anulado"
          : capitalizeWords(saleDocument.statusFEName),
      id: saleDocument.saleDocumentId,
      series: saleDocument.serie,
      icbper: formatToSoles(saleDocument.icbper),
      subTotal: formatToSoles(saleDocument.subTotal),
      igv: formatToSoles(saleDocument.igv),
      discount: formatToSoles(saleDocument.discount),
      typeDocument: saleDocument.typeDocument,
    };
  });

  dispatch(updateSaleDocuments(salesDocuments));
  dispatch(updatePages(response.data.maxPages));

  return {
    status: STATUS_OK,
    message: "Documentos de venta obtenidos correctamente",
    data: {
      salesDocuments: salesDocuments,
      pages: response.data.maxPages,
    },
  };
};

const convertDateFormatTimeOrDate = (date: string, type: string) => {
  const dateArray = convertDateFormat(date).split(" ");
  if (type === "date") {
    return dateArray[0];
  }
  return dateArray[1] + " " + dateArray[2];
};
