import React from "react";
import { AppDispatch } from "@ReduxService/index";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { ChangeSaleBoxModal } from "@Components/ui/organisms/ChangeSaleBoxModal/ChangeSaleBoxModal";

export const changeModalChangeSaleBox = (
  modalContext: AcceptCancelModalContextProps,
  dispatch: AppDispatch,
  selectedSaleBox: any,
  saleBoxesList: any
) => {
  const {
    buttonTwo,
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleClose,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = (
    <ChangeSaleBoxModal
      modalContext={modalContext}
      dispatch={dispatch}
      selectedSaleBox={selectedSaleBox}
      saleBoxesList={saleBoxesList}
    />
  );

  handleTitleChange("Cambiar de caja");
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonTwo: false,
  });
  handleButtonTwoChange({
    ...buttonTwo,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() => handleClose());
  handleNoSizeLimitChange(true);
};
