import React, { useEffect, useState } from "react";
import style from "./AddProductsModal.module.css";
// @ts-ignore
import { SelectInput } from "@viuti/recursos";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import { addSaleProducts } from "@ReduxService/states/currentSaleSlice";
import AddSaleProductRow from "@Components/ui/atoms/AddSaleProductRow/AddSaleProductRow";
import { getProductsByWarehouseId } from "@Adapters/getProductsByWarehouseId.adapter";
import { STATUS_OK } from "@Models/httpsStatus";
import { LoadingPulseIcon } from "@Models/icons";

interface IAddProductsModalProps {
  modalContext: AcceptCancelModalContextProps;
}

const AddProductsModal = ({ modalContext }: IAddProductsModalProps) => {
  const dispatch = useAppDispatch();
  const warehouses = useAppSelector((state) => state.saleConfig.warehouses);
  const warehousesList = warehouses.map((warehouse) => {
    return {
      value: warehouse.id,
      name: warehouse.name,
    };
  });

  const loungeEmployees = useAppSelector(
    (state) => state.saleConfig.loungeEmployees
  );

  const [values, setValues] = useState<
    ISaleProduct[]
    // @ts-ignore
  >([{ item: {} as IProduct, employee: "" }]);
  const [fetchResponses, setFetchResponses] = useState({
    products: { loading: false, error: false },
  });
  const setFetchProducts = (loading: boolean, error: boolean) => {
    setFetchResponses((prev) => {
      return {
        ...prev,
        products: {
          loading: loading,
          error: error,
        },
      };
    });
  };
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<{}>("");

  useEffect(() => {
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      textButtonTwo: "Agregar",
      actionButtonTwo: () => {
        dispatch(addSaleProducts(values));
        // @ts-ignore
        setValues([{ item: {} as IProduct, employee: "" }]);
        modalContext.handleClose();
      },
      isDisabled: values.some((value) => {
        return !value.employeeId || !value.item?.id;
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    const defaultWarehouse =
      warehouses.find((warehouse) => warehouse.isForSale) || warehouses[0];
    if (defaultWarehouse && !defaultWarehouse.hasFetchedProducts) {
      setFetchProducts(true, false);

      (async () => {
        const response = await getProductsByWarehouseId(
          dispatch,
          defaultWarehouse.id
        );
        setFetchProducts(false, response.status !== STATUS_OK);
      })();
    }
    setSelectedWarehouseId(defaultWarehouse.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWarehouseChange = async (e: any) => {
    setFetchProducts(true, false);
    setSelectedWarehouseId(e.target.value);
    const response = await getProductsByWarehouseId(dispatch, e.target.value);
    setFetchProducts(false, response.status !== STATUS_OK);
  };

  if (warehousesList.length === 0 && !fetchResponses.products.loading) {
    return (
      <div className={style.body}>
        <p className={style.empty__message}>
          No hemos encontrado ningún almacén disponible.
        </p>
        <p className={style.empty__submessage}>
          Para poder agregar productos a la venta, es necesario que haya al
          menos un almacén disponible.
        </p>
      </div>
    );
  }
  return (
    <>
      <div className={style.body}>
        <span className={style.form__row__input_container}>
          <SelectInput
            label="Almacén de origen"
            value={selectedWarehouseId}
            handleChange={(e) => handleWarehouseChange(e)}
            items={warehousesList}
            required
          />
        </span>
        {values.map((value, index) => {
          const uniqueKey = `${index}-${value.item?.id}`;
          return (
            <AddSaleProductRow
              key={uniqueKey}
              saleIndex={index}
              values={values}
              setValues={setValues}
              loungeEmployees={loungeEmployees}
              selectedWarehouseId={selectedWarehouseId}
            />
          );
        })}
      </div>
      {fetchResponses?.products.loading && (
        <div className={style.loading}>
          <img src={LoadingPulseIcon} alt="" />
        </div>
      )}
    </>
  );
};

export default AddProductsModal;
