import React, { useEffect, useRef, useState } from "react";
import style from "./PayoutModal.module.css";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import SaleIndicators from "./components/SaleIndicators/SaleIndicators";
import AddPaymentMethodRow from "@Components/ui/atoms/AddPaymentMethodRow/AddPaymentMethodRow";
import PayoutResume from "./components/PayoutResume/PayoutResume";
import { isSaleValid } from "./functions/isSaleValid";
import {
  resetSale,
  addGiftcardPayment,
  updateSalePaymentMethods,
  updateIsFreeSale,
} from "@ReduxService/states/currentSaleSlice";
import { useReactToPrint } from "react-to-print";
import PayoutTicket from "./components/PayoutTicket/PayoutTicket";
import { postNewSale } from "@Adapters/postNewSale.adapter";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { GiftIcon } from "@Models/icons";
import AddGiftcardPaymentRow from "@Components/ui/atoms/AddGiftcardPaymentRow/AddGiftcardPaymentRow";
import { STATUS_OK } from "@Models/httpsStatus";

interface IPayoutModalProps {
  modalContext: AcceptCancelModalContextProps;
}

const PayoutModal = ({ modalContext }: IPayoutModalProps) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  const sales = useAppSelector((state) => state.currentSale.sales);
  const hasGiftcardsInSale = sales.giftcards.length > 0;
  const isFreeSale = useAppSelector((state) => state.currentSale.isFreeSale);
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const summary = useAppSelector((state) => state.currentSale.summary);

  const [paymentMethods, setPaymentMethods] = useState<ISalePaymentMethod[]>([
    // @ts-ignore
    { paymentMethod: "", paymentCategory: "", value: "" },
  ]);

  const giftcardPayments = useAppSelector(
    (state) => state.currentSale.giftcardPayments
  );

  const [isModalDisabled, setIsModalDisabled] = useState(true);

  useEffect(() => {
    setIsModalDisabled(
      !isFreeSale && !isSaleValid(paymentMethods, giftcardPayments, summary)
    );
  }, [paymentMethods, giftcardPayments, summary, isFreeSale]);

  useEffect(() => {
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      textButtonTwo: "Finalizar venta",
      actionButtonTwo: async () => {
        setIsSubmitPending(true);
        dispatch(
          updateNotification({
            message: "Realizando la venta...",
            status: "pending",
            toastTimeDuration: "INFINITE",
          })
        );

        const giftcardPaymentsFormatted: IGiftcardPayment[] =
          giftcardPayments.map((payment) => ({
            giftcardId: payment.giftcardId,
            amount: payment.amount,
            giftcardCode: payment.giftcardCode,
          }));

        dispatch(updateSalePaymentMethods(paymentMethods));

        let response = await postNewSale(
          dispatch,
          state,
          {
            paymentMethods,
            giftcardPayments: giftcardPaymentsFormatted,
          },
          isFreeSale
        );

        if (response.status === 200) {
          setTimeout(() => {
            handlePrintSaleTicket();
            dispatch(resetSale());
          }, 1000);
          modalContext.handleVisibilityChange({
            isVisible: false,
            setIsVisible: () => {},
          });
        }
        setIsSubmitPending(false);
      },
      isDisabled: isModalDisabled || isSubmitPending,
      isLoading: isSubmitPending,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalDisabled, isSubmitPending]);

  const printableRef = useRef(null);

  const handlePrintSaleTicket = useReactToPrint({
    content: () => printableRef.current,
  });

  const [resume, setResume] = useState({
    saleCovered: 0,
    difference: 0,
    isSaleValid: false,
    giftcardsDiscount: 0,
  });

  useEffect(() => {
    const methodsCovered = paymentMethods.reduce((acc, value) => {
      return acc + parseFloat(String(value.value)) || 0;
    }, 0);

    const giftcardsDiscount = giftcardPayments.reduce((acc, value) => {
      return acc + parseFloat(String(value.amount)) || 0;
    }, 0);

    const saleCovered = isFreeSale ? summary.total : methodsCovered;
    const difference = isFreeSale
      ? 0
      : Number((summary.total - saleCovered - giftcardsDiscount).toFixed(2));
    const isSaleValid = isFreeSale || difference <= 0;

    setResume({
      saleCovered,
      difference,
      isSaleValid,
      giftcardsDiscount,
    });
  }, [paymentMethods, giftcardPayments, summary, isFreeSale]);

  const handleAddGiftcard = () => {
    dispatch(addGiftcardPayment());
  };

  const hasIncompleteGiftcardPayments = giftcardPayments.some(
    (payment) =>
      !payment.giftcardCode || !payment.amount || payment.amount === "0"
  );

  const isGiftcardButtonDisabled =
    hasGiftcardsInSale || hasIncompleteGiftcardPayments;

  const handleFreeSaleToggle = () => {
    if (isFreeSale) {
      dispatch(updateIsFreeSale(false));
      setPaymentMethods([
        // @ts-ignore
        { paymentMethod: "" as IPaymentMethod, paymentCategory: "", value: "" },
      ]);
    } else {
      dispatch(updateIsFreeSale(true));
      setPaymentMethods([]);
    }
  };

  return (
    <div className={style.body}>
      <section className={style.payout__container}>
        <article className={style.payment_methods__container}>
          <header className={style.sales__container}>
            <h5 className={style.sales__container_title}>Ventas añadidas</h5>
            <SaleIndicators sales={sales} />
          </header>
          {!isFreeSale ? (
            <>
              {paymentMethods.map((value, index) => (
                <AddPaymentMethodRow
                  key={`payment-${index}`}
                  saleIndex={index}
                  values={paymentMethods}
                  setValues={setPaymentMethods}
                  resume={resume}
                />
              ))}
              {giftcardPayments.map((payment, index) => (
                <AddGiftcardPaymentRow
                  key={`giftcard-${index}`}
                  saleIndex={index}
                  resume={resume}
                />
              ))}
              {/* <button
								className={`${style.addGiftcard} ${
									isGiftcardButtonDisabled ? style.addGiftcard__disabled : ""
								}`}
								onClick={handleAddGiftcard}
								disabled={isGiftcardButtonDisabled}
							>
								<Icon
									path={GiftIcon}
									size={20}
									color={isGiftcardButtonDisabled ? "#7b7b7b" : "#937CF4"}
								/>
								<span>Añadir Gift Card</span>
							</button> */}
            </>
          ) : (
            <div className={style.free_sale__message}>
              <p>Esta venta ha sido marcada como gratuita.</p>
              <p>
                No se requerirán medios de pago y se registrará como tal en el
                sistema.
              </p>
            </div>
          )}
        </article>
        <PayoutResume
          summary={summary}
          resume={resume}
          isFreeSale={isFreeSale}
          onFreeSaleToggle={handleFreeSaleToggle}
        />
        {state.currentSale.finishedSaleResponse?.status === STATUS_OK && (
          <PayoutTicket printableRef={printableRef} summary={summary} />
        )}
      </section>
    </div>
  );
};

export default PayoutModal;
