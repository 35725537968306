import React, { useEffect } from "react";
import style from "./PackageModificationModal.module.css";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
import PackageDetailsModal from "../PackageDetailsModal/PackageDetailsModal";
import EditPriceModal from "../EditPriceModal/EditPriceModal";
// @ts-ignore
import { TabNavigation, IconWrapper } from "@viuti/recursos";
import { HiExclamationTriangle } from "react-icons/hi2";
import { SaleTypeId } from "@Models/const/salesOptions";

interface IPackageModificationModalProps {
  modalContext: AcceptCancelModalContextProps;
  packageItem: IPackage;
  sale: {
    item: ISaleItem;
    summary?: {
      quantity: string;
      subtotal: number;
      discount: number;
      addition: number;
      modifierIsPercentage: boolean;
      total: number;
    };
  };
  type: SaleTypeId;
}

const PackageModificationModal = ({
  modalContext,
  packageItem,
  sale,
  type,
}: IPackageModificationModalProps) => {
  const isStockAvailable = packageItem.stock === null || packageItem.stock > 0;

  let navigationViews = [
    {
      name: "Detalles",
      nameIcon:
        !packageItem.isAvailable ||
        !packageItem.isValid ||
        (!isStockAvailable && (
          <IconWrapper icon={HiExclamationTriangle} size={15} color="#ff9800" />
        )),
      component: (
        <PackageDetailsModal
          modalContext={modalContext}
          packageItem={packageItem}
        />
      ),
    },
    {
      name: "Modificar precios",
      component: (
        <EditPriceModal modalContext={modalContext} sale={sale} type={type} />
      ),
    },
  ];

  return (
    <div className={style.body}>
      <TabNavigation views={navigationViews} key={packageItem.id} />
    </div>
  );
};

export default PackageModificationModal;
