// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cMPKb7s0KFfhUXsfHCVr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
}

.t0eVm4aj13qLHXSQq_7U,
.tLO6zu2wCnF0BStZJOlX,
.lGG71i3rv_Y0HTXs5gWq {
  color: #1f1f1f;
  text-align: center;
  font-family: "Mulish", sans-serif;
}

.iSlRtYhx3oKHUKxuoC9Q {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  &:hover > .tLO6zu2wCnF0BStZJOlX {
    transform: rotateY(180deg);
    background-color: #f9fee3;
    border: 2px solid #d0d57c;
  }
}

.t0eVm4aj13qLHXSQq_7U {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.lGG71i3rv_Y0HTXs5gWq {
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.tLO6zu2wCnF0BStZJOlX {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #e8e3ff;
  border: 2px solid #d1c6ff;
  border-radius: 50%;
  transition: all 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/DowngradeModal/DowngradeModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;;;EAGE,cAAc;EACd,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;;EAEnB;IACE,0BAA0B;IAC1B,yBAAyB;IACzB,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".downgradeModalContent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  max-width: 450px;\n}\n\n.modal__access__denied__title,\n.modal__access__denied__icon,\n.modal__access__denied__description {\n  color: #1f1f1f;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n}\n\n.modal__access__denied__icon__container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 1rem;\n\n  &:hover > .modal__access__denied__icon {\n    transform: rotateY(180deg);\n    background-color: #f9fee3;\n    border: 2px solid #d0d57c;\n  }\n}\n\n.modal__access__denied__title {\n  font-size: 1rem;\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n\n.modal__access__denied__description {\n  font-size: 0.9rem;\n  margin-bottom: 2rem;\n}\n\n.modal__access__denied__icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n  background-color: #e8e3ff;\n  border: 2px solid #d1c6ff;\n  border-radius: 50%;\n  transition: all 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downgradeModalContent": `cMPKb7s0KFfhUXsfHCVr`,
	"modal__access__denied__title": `t0eVm4aj13qLHXSQq_7U`,
	"modal__access__denied__icon": `tLO6zu2wCnF0BStZJOlX`,
	"modal__access__denied__description": `lGG71i3rv_Y0HTXs5gWq`,
	"modal__access__denied__icon__container": `iSlRtYhx3oKHUKxuoC9Q`
};
export default ___CSS_LOADER_EXPORT___;
