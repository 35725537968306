import { openCloseSaleBox_service } from "@Services/openCloseSaleBox";

export const openCloseSaleBox_adapter = async (saleBoxId: number) => {
  const response = await openCloseSaleBox_service(saleBoxId);
  return {
    isSuccessful: response.isSuccessful,
    status: response.status,
    message: response.message,
    data: response.data,
  };
};
