// @ts-ignore
import React, { useEffect, useState } from "react";
import style from "./SaleboxReport.module.css";
// @ts-ignore
import { HeaderNavigation, validateModuleAccess } from "@viuti/recursos";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { redirectToMainPage } from "@Utilities/Navigation";
import useValidateSalebox from "@Hooks/useValidateSalebox";
import SaleboxReportFilter from "@Components/ui/molecules/SaleboxReportFilter/SaleboxReportFilter";
import { getSaleBoxReport } from "@Adapters/getSaleBoxReport.adapter";
import ReportContent from "@Components/ui/organisms/ReportContent/ReportContent";
import { LoadingPulseIcon } from "@Models/icons";
import { updateReportFilters } from "@ReduxService/states/saleBoxReportSlice";
import { useParams } from "react-router-dom";
import { STATUS_OK } from "@Models/httpsStatus";
import { EXPORT_SALEBOX_REPORT_UUID } from "@Models/const/securityAccess";
import { postShareReport } from "@Adapters/postShareReport.adapter";

interface ReportValues {
  salebox: ISaleBox | "";
  fromDate: string;
  toDate: string;
}

const SaleboxReport = () => {
  const dispatch = useAppDispatch();
  const { saleBoxId } = useParams<{ saleBoxId: string }>();
  const saleBoxes: ISaleBox[] = useAppSelector((state) => state.saleBoxesList);
  const reportResponse = useAppSelector(
    (state) => state.saleboxReports.fetchResponse
  );
  const currentDate = new Date();
  const [isDownloading, setIsDownloading] = useState(false);
  const filterValues = useAppSelector((state) => state.saleboxReports);

  const sitesHeaderProps = {
    textBttn: isDownloading ? "Descargando..." : "Exportar reporte",
    handleClick: async () => {
      const uuidToValidate = EXPORT_SALEBOX_REPORT_UUID;
      const isAccessOk = validateModuleAccess(uuidToValidate);
      if (isAccessOk && !isDownloading) {
        setIsDownloading(true);
        try {
          await postShareReport(
            "download",
            {
              idSalebox: saleBoxId,
              startDate: filterValues.fromDate,
              endDate: filterValues.toDate,
            },
            dispatch
          );
        } finally {
          setIsDownloading(false);
        }
      }
    },
    isDisabled: isDownloading,
    isHidden: false,
    isPrimary: true,
    isLoading: isDownloading,
  };

  const headerProps = {
    title: "Reporte de caja",
    previousAction: () => redirectToMainPage("reporte"),
    previousActionMovement: "back",
    buttonProps: sitesHeaderProps,
  };

  const [fetchResponses, setFetchResponses] = useState<{
    saleBoxes: {
      loading: boolean;
      error: boolean;
    };
  }>({
    saleBoxes: {
      loading: true,
      error: false,
    },
  });

  useValidateSalebox(setFetchResponses);

  useEffect(() => {
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    if (
      fetchResponses.saleBoxes.loading === false &&
      fetchResponses.saleBoxes.error === false
    ) {
      const saleBox = saleBoxes.find(
        (saleBox) => saleBox.id === Number(saleBoxId)
      );
      const newValues: ReportValues = {
        salebox: saleBox || "",
        fromDate: formattedDate,
        toDate: formattedDate,
      };

      dispatch(
        updateReportFilters({
          target: { name: "salebox", value: newValues.salebox },
        } as React.ChangeEvent<HTMLInputElement>)
      );
      dispatch(
        updateReportFilters({
          target: { name: "fromDate", value: newValues.fromDate },
        } as React.ChangeEvent<HTMLInputElement>)
      );
      dispatch(
        updateReportFilters({
          target: { name: "toDate", value: newValues.toDate },
        } as React.ChangeEvent<HTMLInputElement>)
      );
      getSaleBoxReport(dispatch, newValues);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResponses.saleBoxes.loading]);

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation {...headerProps} />
      <main className={style.body}>
        {fetchResponses.saleBoxes.loading || reportResponse.status === null ? (
          <div className={style.loading}>
            <img src={LoadingPulseIcon} alt="Cargando..." />
          </div>
        ) : (
          <>
            <SaleboxReportFilter />
            {reportResponse.status !== STATUS_OK ? (
              <div className={style.error}>
                <p className={style.error_message}>
                  No hemos podido obtener el reporte de la caja.
                </p>
                <p className={style.error_submessage}>
                  {reportResponse.message}
                </p>
              </div>
            ) : (
              <ReportContent />
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default SaleboxReport;
