import React from "react";
import style from "./PayoutResume.module.css";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { useAppSelector } from "@Hooks/store";
import { HiCheckCircle } from "react-icons/hi2";
// @ts-ignore
import { IconWrapper } from "@viuti/recursos";

interface IPayoutResumeProps {
  summary: {
    subtotal: number;
    prepayment: number;
    discount: number;
    igv: number;
    icbp: number;
    total: number;
  };
  resume: {
    saleCovered: number;
    difference: number;
    isSaleValid: boolean;
    giftcardsDiscount: number;
  };
  isFreeSale: boolean;
  onFreeSaleToggle: () => void;
}

const PayoutResume = ({
  summary,
  resume,
  isFreeSale,
  onFreeSaleToggle,
}: IPayoutResumeProps) => {
  const billingDocument = useAppSelector(
    (state) => state.currentSale.billingDocument
  );
  const client = useAppSelector((state) => state.currentSale.client);
  const genericClient = useAppSelector(
    (state) => state.currentSale.genericClient
  );
  const selectedClient = genericClient || client;

  return (
    <article className={style.resume__container}>
      <div className={style.resume__header}>
        <h3 className={style.resume__title}>Resumen de venta</h3>
        <h5>{billingDocument?.name}</h5>
        <h5 className={style.resume__client}>
          {selectedClient?.name} {selectedClient?.lastName}
          {!!selectedClient?.documentNumber && (
            <span className={style.resume__client_document}>
              {selectedClient?.documentNumber}
            </span>
          )}
        </h5>
        <div className={style.resume__free_sale_switch}>
          <span className={style.switch_label}>Venta gratuita</span>
          <label className={style.switch}>
            <input
              type="checkbox"
              checked={isFreeSale}
              onChange={onFreeSaleToggle}
            />
            <span className={style.slider}></span>
          </label>
        </div>
      </div>
      <div className={style.resume__body}>
        {isFreeSale ? (
          <div className={`${style.resume__row} ${style.resume__row_free}`}>
            <span>Estado</span>
            <div className={style.resume__free_status}>
              <span>Gratuito</span>
              <IconWrapper icon={HiCheckCircle} size={14} color="#0bad4e" />
            </div>
          </div>
        ) : (
          <div
            className={`${style.resume__row} ${
              resume.difference > 0
                ? style.resume__row_pending
                : style.resume__row_complete
            }`}
          >
            <span>Diferencia</span>
            <span className={style.resume__amount}>
              {formatToSoles(resume.difference)}
            </span>
          </div>
        )}
        <span
          className={`${style.resume__item} ${style.resume__item_discount}`}
        >
          <p>Descuento</p>
          <p>{formatToSoles(summary.discount)}</p>
        </span>
        {/* <span className={`${style.resume__item} ${style.resume__item_discount_detail}`}>
					<p>Giftcards</p>
					<p>{formatToSoles(resume.giftcardsDiscount)}</p>
				</span> */}
        <span className={style.resume__item}>
          <p>Total a pagar</p>
          <p>{formatToSoles(summary.total - resume.giftcardsDiscount)}</p>
        </span>
        <span className={style.resume__item}>
          <p>
            <b>Total cubierto</b>
          </p>
          <p>
            <b>{formatToSoles(resume.saleCovered)}</b>
          </p>
        </span>
      </div>
    </article>
  );
};

export default PayoutResume;
