import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
export interface IConcept {
  name: string;
  value: number;
  movementType: string | number;
}

export interface IConceptsSlice {
  concepts: IConcept[];
  entryConcepts: IConcept[];
  outputConcepts: IConcept[];
}

const DEFAULT_STATE = {
  concepts: [],
  entryConcepts: [],
  outputConcepts: [],
};

const initialState: IConceptsSlice = (() => {
  return DEFAULT_STATE;
})();

export const conceptsSlice = createSlice({
  name: "concepts",
  initialState,
  reducers: {
    addConcepts: (state, action: PayloadAction<IConcept[]>) => {
      state.concepts = action.payload;
      const entryConcepts = action.payload.filter(
        (concept) => concept.movementType === 1
      );
      const outputConcepts = action.payload.filter(
        (concept) => concept.movementType === 2
      );
      state.entryConcepts = entryConcepts;
      state.outputConcepts = outputConcepts;
    },
    addConcept: (state, action: PayloadAction<IConcept>) => {
      const tempConcepts = state.concepts;
      tempConcepts.push(action.payload);
      state.concepts = tempConcepts;
      if (action.payload.movementType === 1) {
        const tempEntryConcepts = state.entryConcepts;
        tempEntryConcepts.push(action.payload);
        state.entryConcepts = tempEntryConcepts;
      } else {
        const tempOutputConcepts = state.outputConcepts;
        tempOutputConcepts.push(action.payload);
        state.outputConcepts = tempOutputConcepts;
      }
    },
  },
});

export const { addConcept, addConcepts } = conceptsSlice.actions;

export default conceptsSlice.reducer;
