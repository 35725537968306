// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OGLs28LcmHjr7OlVs1Je {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) 50px;
  align-items: flex-end;
  gap: 1rem;

  background-color: #f5f3fe;
  padding: 20px;
  border-radius: 10px;

  @media screen and (width < 660px) {
    grid-template-columns: 1fr;
  }
}

.gJoJn52Rsfkl3G3CQ37K {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 50px;
  overflow: hidden;
}

.gJoJn52Rsfkl3G3CQ37K > button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  transition: all 150ms ease-in-out;

  &:hover {
    background-color: #f9f9f9;
  }
}

.HnKDwW83bS8v7Jwj0G3S > button {
  border-color: #ba1a1a;
  background-color: #fde6e6;

  &:hover {
    background-color: #ba1a1a1a;
  }
}

.qYS3DIfBnHPBwbpK1Iv9 > button {
  filter: grayscale(100%);
  cursor: not-allowed;
  background-color: #fafafa;

  &:hover {
    background-color: #fafafa;
  }

  & > img {
    opacity: 0.5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/AddSaleServiceRow/AddSaleServiceRow.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gEAAgE;EAChE,qBAAqB;EACrB,SAAS;;EAET,yBAAyB;EACzB,aAAa;EACb,mBAAmB;;EAEnB;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,WAAW;EACX,YAAY;;EAEZ,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,iCAAiC;;EAEjC;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;;EAEzB;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;;EAEzB;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".form__row {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) 50px;\n  align-items: flex-end;\n  gap: 1rem;\n\n  background-color: #f5f3fe;\n  padding: 20px;\n  border-radius: 10px;\n\n  @media screen and (width < 660px) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.form__row__input__icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 40px;\n  min-width: 50px;\n  overflow: hidden;\n}\n\n.form__row__input__icon > button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  height: 100%;\n\n  border: 1px solid #e0e0e0;\n  border-radius: 5px;\n  cursor: pointer;\n  background-color: #fff;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    background-color: #f9f9f9;\n  }\n}\n\n.form__row__input__icon_delete > button {\n  border-color: #ba1a1a;\n  background-color: #fde6e6;\n\n  &:hover {\n    background-color: #ba1a1a1a;\n  }\n}\n\n.form__row__input__icon_disabled > button {\n  filter: grayscale(100%);\n  cursor: not-allowed;\n  background-color: #fafafa;\n\n  &:hover {\n    background-color: #fafafa;\n  }\n\n  & > img {\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form__row": `OGLs28LcmHjr7OlVs1Je`,
	"form__row__input__icon": `gJoJn52Rsfkl3G3CQ37K`,
	"form__row__input__icon_delete": `HnKDwW83bS8v7Jwj0G3S`,
	"form__row__input__icon_disabled": `qYS3DIfBnHPBwbpK1Iv9`
};
export default ___CSS_LOADER_EXPORT___;
