import React from "react";
import style from "./TertiaryButton.module.css";

// @ts-ignore
import { Icon } from "@viuti/recursos";

interface TertiaryButtonProps {
  buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>;
  icon: string;
  value: string;
}

const TertiaryButton = ({ buttonProps, icon, value }: TertiaryButtonProps) => {
  return (
    <button className={style.button} {...buttonProps}>
      <Icon path={icon} color="#937CF4" size={12} />
      {value}
    </button>
  );
};

export default TertiaryButton;
