import axios from "axios";
import { baseUrl, config } from "./constGlobals";

export interface INewSalePayload {
  idPaymentClient: number;
  typeDocumentoPaymentId: number;
  saleBoxId: number;
  payments: Payment[];
  idGiftCard: any[];
  idPrommotion: any[];
  items: Item[];
  isFreeSale?: boolean;
}

interface Payment {
  idPaymentMethod: number;
  paymentAmount: number;
}

interface Item {
  priceServiceId: number;
  localProductId: number;
  idReserva?: number;
  commandId: number;
  employeeId: number;
  promoId: number;
  quantity: number;
  discount: number; // Descuento por unidad
  surcharge: number; // Recargo por unidad
}

export const postNewSale_service = async (payload: INewSalePayload) => {
  const url = `${baseUrl}/Payment/CreatePayment`;

  try {
    const response: IResponse = await axios
      .post(url, payload, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al realizar la venta. Intentalo de nuevo.",
      data: [],
    };
  }
};

interface ISummarySale {
  priceServiceId: number;
  localProductId: number;
  amount: number;
  discount: number;
  increase: number;
}

interface IItemParams {
  PriceServiceId?: number;
  LocalProductId?: number;
  Amount: number;
  Discount: number;
  Increase: number;
}
export const getItemNetPrice_service = async (payload: IItemParams) => {
  const url = `${baseUrl}/Calculations/CalculateAmountItem`;

  try {
    const response: IResponse = await axios
      .get(url, {
        params: payload,
        ...config,
      })
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status,
      message:
        error?.response?.data.message ||
        "Hubo un error al obtener el precio neto del producto.",
      data: [],
    };
  }
};

export const getSalesSummary_service = async (payload: ISummarySale[]) => {
  const url = `${baseUrl}/Calculations/CalculateAmountTotal`;

  try {
    const response: IResponse = await axios
      .post(url, payload, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data.message ||
        "Hubo un error al obtener el resumen de la venta.",
      data: [],
    };
  }
};
