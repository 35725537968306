import { getItemNetPrice } from "@Adapters/getItemNetPrice.adapter";
import { DISCOUNT_TYPE } from "@Models/const/priceModifier";
import { AppDispatch } from "@ReduxService/index";

export const handleQuantityBlur = async (
  e: React.ChangeEvent<HTMLInputElement>,
  dispatch: AppDispatch,
  sale,
  summary: IItemSummary,
  modifierType: string,
  setSummary: React.Dispatch<React.SetStateAction<IItemSummary>>
) => {
  let input = e.target.value;

  // Si el input está vacío o es 0, permitir el valor 0
  if (!input || input === "0") {
    input = "0.00";
  }

  // Convertir a número y formatear a 2 decimales
  const value = parseFloat(input);
  const formattedValue = isNaN(value) ? "0.00" : value.toFixed(2);

  setSummary({
    ...summary,
    discount: 0,
    addition: 0,
    [modifierType === DISCOUNT_TYPE ? "discount" : "addition"]:
      summary.modifierIsPercentage
        ? formatPercentage(formattedValue)
        : formatCurrency(formattedValue),
  });
};

export const formatCurrency = (number: number | string) => {
  const num = Number(number);
  if (!isNaN(num)) {
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  return "0.00";
};

export const formatPercentage = (number: number | string) => {
  const num = Number(number);
  if (!isNaN(num)) {
    return num.toFixed(2);
  }
  return "0.00";
};
