export const convertTime = (timeString: string): string => {
  // Parse the time string
  const [hour, minute] = timeString.split(":").map((part) => parseInt(part));
  // Determine if it's AM or PM
  const period = hour >= 12 ? "PM" : "AM";
  // Convert 24-hour format to 12-hour format
  const hour12 = hour % 12 || 12;
  // Construct the new time format
  const newTimeFormat = `${hour12}:${minute
    .toString()
    .padStart(2, "0")} ${period}`;
  return newTimeFormat;
};
