import axios from "axios";
import { oldBaseUrl, config } from "./constGlobals";

export const getAccountInfo_service = async () => {
  const url = `${oldBaseUrl}/Empleado/DatosGeneralesEmpleado`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los datos del empleado. Por favor, intente de nuevo.",
      data: [],
    };
  }
};
