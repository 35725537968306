import React, { useState } from "react";
//@ts-ignore
import { SaleDocumentReportView } from "@viuti/recursos";
import useBoxes from "@Hooks/useBoxes/useBoxes";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { getAccountInfo } from "@Adapters/getAccountInfo.adapter";
import { getSalesDocuments } from "@Adapters/getSalesDocuments.adapter";
import { updateShouldReloadTable } from "@ReduxService/states/movementsSlice";
import { updateCurrentPagination } from "@ReduxService/states/saleDocumentsSlice";
import { EXPORT_SALE_DOCUMENTS_UUID } from "@Models/const/securityAccess";
import { redirectToMainPage } from "@Utilities/Navigation";
import {
  DOCUMENTOS_DE_VENTAS,
  state,
  stateFE,
  typeDocuments,
} from "@Models/const/constSalesDocuments";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { useNavigate } from "react-router-dom";

const SalesDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { boxes, saleBoxId } = useBoxes(dispatch);
  const { saleDocuments, pages, currentPagination, shouldReloadTable } =
    useAppSelector((state) => state.saleDocuments);
  const [_, setIsLoadingSalesDocuments] = useState(true);

  const [isSaleDetailsModalOpen, setIsSaleDetailsModalOpen] = useState(false);
  const [isCancelTicketModalOpen, setIsCancelTicketModalOpen] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isCreditNoteModalVisible, setIsCreditNoteModalVisible] =
    useState(false);
  return (
    <SaleDocumentReportView
      boxes={boxes}
      saleBoxId={saleBoxId}
      saleDocuments={saleDocuments}
      pages={pages}
      currentPagination={currentPagination}
      shouldReloadTable={shouldReloadTable}
      getAccountInfo={() => getAccountInfo(dispatch)}
      getSalesDocuments={async (form, page) =>
        getSalesDocuments(form, page, dispatch)
      }
      updateShouldReloadTable={(bool) => updateShouldReloadTable(bool)}
      updateCurrentPagination={updateCurrentPagination}
      onNotification={(notification) =>
        dispatch(updateNotification(notification))
      }
      saleDetailsModalVisibility={{
        isVisible: isSaleDetailsModalOpen,
        setIsVisible: setIsSaleDetailsModalOpen,
      }}
      cancelTicketModalVisibility={{
        isVisible: isCancelTicketModalOpen,
        setIsVisible: setIsCancelTicketModalOpen,
      }}
      exportModalVisibility={{
        isVisible: isExportModalVisible,
        setIsVisible: setIsExportModalVisible,
      }}
      creditNoteModalVisibility={{
        isVisible: isCreditNoteModalVisible,
        setIsVisible: setIsCreditNoteModalVisible,
      }}
      EXPORT_SALE_DOCUMENTS_UUID={EXPORT_SALE_DOCUMENTS_UUID}
      onHeaderBackClick={() => navigate(`/${saleBoxId}`)}
      typeDocuments={typeDocuments}
      state={state}
      stateFE={stateFE}
      setCurrentPagination={(e) => {
        setIsLoadingSalesDocuments(true);
        dispatch(updateCurrentPagination(e));
      }}
    />
  );
};

export default SalesDocuments;
