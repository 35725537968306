import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import { updateSummmary } from "@ReduxService/states/currentSaleSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getSalesSummary_service } from "@Services/sales";

export const getSalesSummary = async (
  dispatch: AppDispatch,
  sales: {
    products: ISaleProduct[];
    services: ISaleService[];
    appointments: ISaleAppointment[];
    packages: ISalePackage[];
    commands: ISaleCommand[];
    giftcards: ISaleGiftcard[];
  }
) => {
  const payload = [];

  // Agregar la suma de todos los items de las comandas al payload
  sales?.commands.forEach((command) => {
    command.command.itemsGroup.forEach((itemGroup) => {
      payload.push({
        priceServiceId: itemGroup.item.priceServiceId,
        localProductId: itemGroup.item.localProductId,
        amount: itemGroup.item.quantity,
        discount: 0,
        increase: 0,
        reserveId: command.command.reserveId || null,
        comandaId: command.command.comandaId || null,
      });
    });
  });

  sales?.products.forEach((product) => {
    payload.push({
      priceServiceId: null,
      localProductId: product.item.localId,
      amount: Number(product.summary.quantity) || 1,
      discount: product.summary.discount,
      increase: product.summary.addition,
    });
  });

  sales?.services.forEach((service) => {
    payload.push({
      priceServiceId: service.item.priceId,
      localProductId: null,
      amount: Number(service.summary.quantity) || 1,
      discount: service.summary.discount,
      increase: service.summary.addition,
    });
  });

  sales?.appointments.forEach((appointment) => {
    appointment.appointment.services.forEach((service) => {
      payload.push({
        priceServiceId: service.item.priceId,
        localProductId: null,
        amount: service.summary.quantity || 1,
        discount: service.summary.discount,
        increase: service.summary.addition,
      });
    });
  });

  sales?.packages.forEach((packageSale) => {
    payload.push({
      priceServiceId: null,
      localProductId: null,
      packageId: packageSale.item.id,
      giftcardId: null,
      amount: Number(packageSale.summary.quantity) || 1,
      discount: packageSale.summary.discount,
      increase: packageSale.summary.addition,
    });
  });

  sales?.giftcards.forEach((giftcard) => {
    payload.push({
      priceServiceId: null,
      localProductId: null,
      packageId: null,
      giftcardId: giftcard.item.id,
      amount: Number(giftcard.summary.quantity) || 1,
      discount: giftcard.summary.discount,
      increase: giftcard.summary.addition,
    });
  });

  const response: IResponse = await getSalesSummary_service(payload);

  if (response.status !== STATUS_OK) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status as any,
        toastTimeDuration: 4000,
      })
    );
    return response;
  }

  const newSummary = {
    subtotal: response.data.value,
    prepayment: 0,
    discount: response.data.discount,
    discountGiftcard: 0,
    igv: response.data.tax,
    icbp: response.data.icbper,
    total: response.data.price,
  };

  dispatch(updateSummmary(newSummary));
};
