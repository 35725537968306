export const CREATE_SALES_UUID = "f363f59b-20e8-481d-83d1-72231191fbf3";

export const SALEBOX_REPORT_UUID = "";
export const SALE_DOCUMENTS_UUID = "";
export const MOVEMENTS_UUID = "";

export const REGISTER_MOVEMENTS_UUID = "";
export const EXPORT_MOVEMENTS_UUID = "";

export const EXPORT_SALE_DOCUMENTS_UUID = "";

export const EXPORT_SALEBOX_REPORT_UUID = "";
