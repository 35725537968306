import React, { useContext } from "react";
import {
  FormCreateNewClient as ClientModal,
  ModalLayoutWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import {
  ClientsModalStateContext,
  ClientsModalStateContextProps,
} from "@Hooks/NewClientsModalStateContext";

import {
  TYPE_CARNET_DE_EXTRANJERIA,
  TYPE_DNI,
  TYPE_PASAPORTE,
  TYPE_RUC,
} from "@Models/const/documentTypes";

interface IAddClientModalProps {
  handleCreateClient: (client: IClient) => void;
  setIsVisible: (isVisible: boolean) => void;
}

const AddClientModal = ({
  handleCreateClient,
  setIsVisible,
}: IAddClientModalProps) => {
  const clientsModalContext = useContext(
    ClientsModalStateContext
  ) as ClientsModalStateContextProps;

  const postNewClient = async (client) => {
    // la funcion de arriba pero en reversa
    const getDocumentType = (typeD) => {
      if (typeD === 1) return TYPE_RUC;
      if (typeD === 2) return TYPE_DNI;
      if (typeD === 3) return TYPE_CARNET_DE_EXTRANJERIA;
      if (typeD === 4) return TYPE_PASAPORTE;
    };

    const form = client;

    clientsModalContext.handleClientChange({
      id: form.userId,
      name: form.name,
      lastName: form.lastName,
      email: form.email,
      gender: form.sex,
      phoneNumber: `${form.phoneCode}${form.phoneNumber}`,
      birthdate: form.birthdate,
      documentType: {
        id: form.documentTypeId,
        name: form.documentType,
      },
      category: form.category && {
        id: form.categoryId,
        name: form.category,
        discount: {
          services: form.discountService,
          products: form.discountProduct,
        },
      },
      documentNumber: form.documentNumber,
    });
    handleCreateClient({
      id: form.userId,
      name: form.name,
      lastName: form.lastName,
      email: form.email,
      gender: form.sex,
      phoneNumber: `${form.phoneCode}${form.phoneNumber}`,
      birthdate: form.birthdate,
      documentType: {
        id: form.documentTypeId,
        name: getDocumentType(form.documentTypeId),
      },
      documentNumber: form.documentNumber,
      category: {
        id: form.categoryId,
        name: form.category,
        discount: {
          services: form.discountService,
          products: form.discountProduct,
        },
      },
    });
    setIsVisible(false);
  };

  return (
    <ModalLayoutWrapper isVisible={clientsModalContext.visibility.isVisible}>
      <ClientModal
        setIsDisabled={setIsVisible}
        isDisabled={false}
        nextClick={(client) => postNewClient(client)}
      />
    </ModalLayoutWrapper>
  );
};

export default AddClientModal;
