import React from "react";
import style from "./DowngradeModal.module.css";
import { HiLockClosed } from "react-icons/hi2";
// @ts-ignore
import { ButtonWithoutIcon, IconWrapper } from "@viuti/recursos";

const DowngradeModal = () => {
  return (
    <div className={style.downgradeModalContent}>
      <h2 className={style.modal__access__denied__title}>
        Lo sentimos, no tienes permisos para acceder a esta caja.
      </h2>
      <div className={style.modal__access__denied__icon__container}>
        <span className={style.modal__access__denied__icon}>
          <IconWrapper icon={HiLockClosed} size={48} color="#937cf4" />
        </span>
      </div>
      <p className={style.modal__access__denied__description}>
        Esta caja está deshabilitada automáticamente debido a la cantidad de
        cajas disponibles según tu plan actual. Para habilitarla nuevamente,
        actualiza tu plan a uno superior o ajusta la cantidad de cajas
        disponibles.
      </p>
      <ButtonWithoutIcon
        isPrimary
        textBttn="Actualizar plan"
        handleClick={() => window.location.replace("/mi-cuenta/planes")}
      />
    </div>
  );
};

export default DowngradeModal;
