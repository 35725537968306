import { useEffect } from "react";
import style from "./SavedSaleModal.module.css";
import { AcceptCancelModalContextProps } from "@Hooks/AcceptCancelModalContext";
// @ts-ignore
import { ButtonWithoutIcon, IconWrapper } from "@viuti/recursos";
import { useAppDispatch } from "@Hooks/store";
import { updateInitialState } from "@ReduxService/states/currentSaleSlice";
import { HiClock } from "react-icons/hi2";

interface IEBillingAmountModalProps {
  modalContext: AcceptCancelModalContextProps;
}

const SavedSaleModal = ({ modalContext }: IEBillingAmountModalProps) => {
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    const parsedState = JSON.parse(localStorage.getItem("lastSavedSale"));
    dispatch(updateInitialState(parsedState));
    localStorage.removeItem("lastSavedSale");
    localStorage.removeItem("lastSaleboxSale");

    modalContext.handleVisibilityChange({
      isVisible: false,
      setIsVisible: () => {},
    });
  };

  const handelDiscard = () => {
    localStorage.removeItem("lastSavedSale");
    localStorage.removeItem("lastSaleboxSale");
    modalContext.handleVisibilityChange({
      isVisible: false,
      setIsVisible: () => {},
    });
  };

  useEffect(() => {
    modalContext.handleActionButtonCloseChange(() => {
      handelDiscard();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.body}>
      <IconWrapper icon={HiClock} size={48} color={"#45348e"} />
      <p className={style.description}>
        Parece que tienes una venta no completada. ¿Quieres continuar donde lo
        dejaste o descartar y empezar una nueva?
      </p>
      <span className={style.buttonsContainer}>
        <ButtonWithoutIcon
          isPrimary={false}
          textBttn="Descartar y empezar nueva"
          handleClick={handelDiscard}
          width="100%"
        />
        <ButtonWithoutIcon
          isPrimary
          textBttn="Continuar venta"
          handleClick={handleContinue}
          width="100%"
        />
      </span>
    </div>
  );
};

export default SavedSaleModal;
