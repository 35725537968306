// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hb_rq2CWZ0rssYHtxIOa {
  display: flex;
  flex-direction: column;

  gap: 20px;
  min-width: 580px;
  min-height: 400px;
  max-height: 485px;
  overflow-y: auto;
}

@media screen and (width < 746px) {
  .Hb_rq2CWZ0rssYHtxIOa {
    min-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/AddServiceModal/AddServiceModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n\n  gap: 20px;\n  min-width: 580px;\n  min-height: 400px;\n  max-height: 485px;\n  overflow-y: auto;\n}\n\n@media screen and (width < 746px) {\n  .body {\n    min-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `Hb_rq2CWZ0rssYHtxIOa`
};
export default ___CSS_LOADER_EXPORT___;
