import React, { useContext } from "react";
import styles from "./BillingDocumentsBullet.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { BillIcon } from "@Models/icons";
import { useAppSelector } from "@Hooks/store";
import {
  ERROR_CONST,
  ERROR_TYPE,
  SUCCESS_CONST,
  SUCCESS_TYPE,
  WARNING_CONST,
  WARNING_TYPE,
} from "@Models/const/common";
import { changeModalEBillingAmount } from "@Hooks/UpdateModalType/changeModalEBillingAmount/changeModalEBillingAmount";
import {
  AcceptCancelModalContext,
  AcceptCancelModalContextProps,
} from "@Hooks/AcceptCancelModalContext";

const BillingDocumentsBullet = () => {
  const modalContext = useContext(
    AcceptCancelModalContext
  ) as AcceptCancelModalContextProps;
  const electronicBillingAmmount = useAppSelector(
    (state) => state.saleConfig.electronicBillingAmmount
  );

  const AMOUT_OK = 50;
  const AMOUNT_DANGER = 19;

  let color = "#45348e";
  let containerStatusStyle = styles.container__ok;
  let StatusIcon = require("react-icons/hi").HiCheckCircle;
  let modalType: SUCCESS_TYPE | WARNING_TYPE | ERROR_TYPE = SUCCESS_CONST;

  if (electronicBillingAmmount <= AMOUNT_DANGER) {
    color = "#d82121";
    containerStatusStyle = styles.container__danger;
    StatusIcon = require("react-icons/hi2").HiExclamationCircle;
    modalType = ERROR_CONST;
  } else if (
    electronicBillingAmmount > AMOUNT_DANGER &&
    electronicBillingAmmount < AMOUT_OK
  ) {
    color = "#ffc107";
    containerStatusStyle = styles.container__warning;
    StatusIcon = require("react-icons/hi2").HiExclamationTriangle;
    modalType = WARNING_CONST;
  } else if (electronicBillingAmmount >= AMOUT_OK) {
    color = "#00de66";
    containerStatusStyle = styles.container__ok;
    StatusIcon = require("react-icons/hi2").HiCheckCircle;
    modalType = SUCCESS_CONST;
  }

  return (
    <button
      className={`${styles.container} ${containerStatusStyle}`}
      onClick={() => changeModalEBillingAmount(modalContext, modalType)}
    >
      <span className={styles.content}>
        <StatusIcon size={16} color={color} />
        {electronicBillingAmmount} documentos disponibles
      </span>
      <Icon path={BillIcon} size={14} color={color} />
    </button>
  );
};

export default BillingDocumentsBullet;
